import Client from "../../../base/Client";
import Utility from "../../../base/Utility";
import Preview from "./Preview";

class SmashupPreview extends Preview {
  constructor(el, product, pers) {
    super(el, product, pers);
    this.hasVideo = true;
    this.type = 'smashup';
  }

  afterInit() {
    this.el.classList.add('--waits-visible');
    this.loadProduct();
    Utility.elementRendered('video', this.el).then((el) => {
      Client.blockScrollCallable();
      this.videoEl = el;
      this.videoRenderedHandler();
    })
  }

  videoRenderedHandler() {
    setTimeout(() => {
      this.exitFullscreen();
    }, 100)
  }

  exitFullscreen() {
    Utility.elementRendered('#expression-close-button', this.el).then(el => {
      setTimeout(() => {
        el.click();
      }, 0)
      setTimeout(() => {
        this.isReady = true;
        Utility.waitBackgroundLoad(this.container.querySelector('#jwPlayerContainer .jw-preview'), this.ready.bind(this));
      }, 50);
    })
  }

  afterReady() {
    Client.allowScrollCallable();
    let playerEl = this.el.querySelector('#jwPlayerWrapper');
    if (window.jwplayer && playerEl) {
      this.player = jwplayer(playerEl);
    }
    this.player?.on("fullscreen", (e) => {
      if (this.dialog) {
        setTimeout(() => {
          this.dialog.isBlocking = e.fullscreen;
        }, 100)
      }
      if (!e.fullscreen) {
        this.player.setFullscreen(false)
      }
    })
  }

  pauseMedia() {
    if (this.player?.getState() == "playing") {
      this.player?.pause();
    }
  }

  resetMedia() {
    this.player?.stop();
  }

}

export default SmashupPreview;