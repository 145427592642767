import Client from "../base/Client";
import Helpers from "../base/Helpers";
import Render from "../base/Render";
import Utility from "../base/Utility";

class Reminders {
  constructor() {
    this.itemTemplate = document.querySelector(".reminder-list-item-template") || null;
    this.collection = new Map();
    this.emptyFilteredReminders = {
      anniversaries: [],
      birthdays: [],
      hasAnniversaries: false,
      hasBirthdays: false
    }
    this.init();
  }

  init() {
    this.hydrateDate();
    this.initReminders();
    if (["/", "/ecards/birthday", "/ecards/anniversary", "/creatacard/birthday",
       "/creatacard/anniversary"].includes(window.location.pathname)) {
      this.initDYReminders();
    }

  }

  hydrateDate() {
    const reminderCurrentDate = document.querySelector("#reminders__current-date");
    if (!reminderCurrentDate) return;

    const currentDate = new Date();
    const currentMonth = currentDate.toLocaleString('en-US', { month: 'long' });

    reminderCurrentDate.innerHTML = currentMonth + " " + currentDate.getDate() + Utility.getOrdinal(currentDate.getDate());
  }

  initReminders() {
    document.querySelectorAll(".reminders")?.forEach(el => {
      if (el.id == '') {
        el.id = `reminders-instance`;
      }
      Utility.fixDuplicates(el);
      this.collection.set(el.id, new RemindersInstance(el, this));
    })
  }

  async initDYReminders() {
    try {
      if (!app_env.customer) throw new Error("Customer not signed in.")
      let response = await fetch(`${app_env.shost}/myaccount/reminders`);

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      let remidersData = await response.json();

      let today = new Date();
      let in14Days = new Date();
      in14Days.setDate(today.getDate() + 14);

      let closestAnniversary = null;
      let closestBirthday = null;

      remidersData.forEach(event => {
        let eventDate = new Date(event.year_start, event.month_start - 1, event.day_start);

        if (eventDate >= today && eventDate <= in14Days) {
          event.formatted_date = this.formatDate(event);

          if (event.occasion_id === 1) {
            if (!closestBirthday || eventDate < new Date(closestBirthday.year_start, closestBirthday.month_start - 1, closestBirthday.day_start)) {
              closestBirthday = event;
            }
          } else if (event.occasion_id === 2) {
            if (!closestAnniversary || eventDate < new Date(closestAnniversary.year_start, closestAnniversary.month_start - 1, closestAnniversary.day_start)) {
              closestAnniversary = event;
            }
          }
        }
      });

      let hasAnniversaries = closestAnniversary !== null;
      let hasBirthdays = closestBirthday !== null;

      let result = {
        anniversaries: closestAnniversary ? [closestAnniversary] : [],
        birthdays: closestBirthday ? [closestBirthday] : [],
        hasAnniversaries: hasAnniversaries,
        hasBirthdays: hasBirthdays
      };

      window.filteredReminders = result;
    } catch (error) {
      window.filteredReminders = this.emptyFilteredReminders;
    }
  };


  // Helper function to add suffix to day
  getDayWithSuffix(day) {
    if (day > 3 && day < 21) return day + 'th';
    switch (day % 10) {
      case 1: return day + "st";
      case 2: return day + "nd";
      case 3: return day + "rd";
      default: return day + "th";
    }
  }

  // Helper function to format date
  formatDate(event) {
    let date = new Date(event.year_start, event.month_start - 1, event.day_start);
    let options = { month: 'long' };
    let month = date.toLocaleDateString('en-US', options);
    let dayWithSuffix = this.getDayWithSuffix(date.getDate());
    return `${month} ${dayWithSuffix}`;
  }
}

class RemindersInstance {
  constructor(el, parent) {
    this.el = el;
    this.parent = parent;
    this.hydrated = false;
    this.dialog = null;
    this.data = null;
    this.storageKey = `ag-${window.app_env?.box_env || "general"}-reminders`;
    this.storageType = "local";
    this.totalEmpty = 0;
    this.totalReminders = 0;
    this.today = new Date().toJSON().split('T')[0];
    this.customerNumber = window.app_env?.customer?.number || 0;
    this.init();
  }

  init() {
    document.addEventListener('app-contact-updated', this.handleContactUpdate.bind(this));
    document.addEventListener('app-contact-removed', this.handleContactUpdate.bind(this));
    document.addEventListener('app-contact-added', this.handleContactUpdate.bind(this));
    !window.app_env.is_signed_in && window.app_storage.remove(this.storageKey, this.storageType);
    const recovered = window.app_storage.get(this.storageKey, this.storageType);
    if (recovered) {
      if (this.today != recovered.date || this.customerNumber != recovered.customer)
      window.app_storage.remove(this.storageKey, this.storageType)
    }
    const dialogEl = this.el.closest(".dialog");
    if (dialogEl) {
      this.dialog = window.app_dialogs?.collection.get(dialogEl.id);
    }

    if (this.dialog) {
      this.dialog.el.addEventListener("dialog-opened", this.hydrateReminders.bind(this))
      if (window.app_storage.get(this.storageKey, this.storageType)) {
        this.hydrateReminders();
      }
    } else {
      this.hydrateReminders();
    }

    // Handle resets
    let reminderForm = window.app_forms?.collection.get("confirm-reminders");
    if (reminderForm) {
      reminderForm.onSuccess = () => {
        let entry = JSON.parse(reminderForm.lastRequest?.package?.body || "{}");
        if (entry.events) {
          let changes = Helpers.getEventsChanges(entry.events, null);
          changes && window.app_services?.track("contact-updated", changes);
        }
        return true;
      }
    }
  }

  handleContactUpdate(e) {
    let changes = e?.detail || [];
    let invalidate = false;
    changes?.forEach?.(change => {
      if (!change.includes("reminder") && (change.includes("anniversary") || change.includes("birthday"))) {
        invalidate = true;
      } else if (change.includes("fname") || change.includes("lname")) {
        invalidate = true;
      }
    })
    if (invalidate || e.type == "app-contact-removed") {
      this.hydrated = false;
      window.app_storage.remove(this.storageKey, this.storageType);
      let exemptedCaches = [];
      if (app_env.page_type == "contacts-and-reminders") {
        exemptedCaches.push("/addressbook/contacts?events=1")
      }
      window.app_tables?.invalidateCache(exemptedCaches);
    }
  }

  async hydrateReminders() {
    if (this.hydrated || !window.app_env.is_signed_in) return
    this.totalEmpty = 0;
    this.totalReminders = 0;
    this.setLoading();
    this.setEmpty("birthday", false);
    this.setEmpty("anniversary", false);
    this.sessionData = window.app_storage.get(this.storageKey, this.storageType);
    if (this.sessionData) {
      this.data = this.sessionData;
    } else {
      this.data = await this.fetchReminders();
      this.data["date"] = this.today;
      this.data["customer"] = this.customerNumber;
      window.app_storage.set(this.storageKey, this.data, this.storageType);
    }
    this.hydrateReminderSection("birthday");
    this.hydrateReminderSection("anniversary");
    this.setLoading(false);
    this.manageHolidays();
    this.hydrated = true;
  }

  manageHolidays() {
    let holidayItems = this.el.querySelectorAll(`[data-content-key="reminders-holiday-list"] > li`);
    let originalSize = holidayItems.length;
    let visibleCount = originalSize > this.totalReminders ? originalSize - this.totalReminders : 2;
    holidayItems.forEach((item, counter) => {
      if (counter >= visibleCount) {
        item.classList.add("hidden");
      } else {
        item.classList.remove("hidden");
      }
    })
  }

  async fetchReminders() {
    try {
      let data = '';
      let endpoint = "/myaccount/reminders";
      let options = {
        method: "POST",
        headers: {'X-Csrftoken': Client.getCSRFToken()}
      };
      let response = await fetch(endpoint, options);
      if (response.status == 200) {
        data = await response.json();
        return data;
      } else {
        return {};
      }
    } catch (e) {
      return {};
    }
  }

  hydrateReminderSection(key) {
    const hasEntries = this.data && this.data.hasOwnProperty(`${key}_list`) && this.data[`${key}_list`].length != 0;
    if (hasEntries) {
      this.totalReminders = this.totalReminders + this.data[`${key}_list`].length;
    }
    let entries = hasEntries ? this.data[`${key}_list`] : [
      {month:"",day:"",link:{href:"",title:""}},
      {month:"",day:"",link:{href:"",title:""}}
    ];
    let inner = "";
    entries.forEach(entry => {
      inner += Render.interpolateTemplate(this.parent.itemTemplate, {
        href: entry.link.href ? `href="${entry.link.href}"` : "",
        date: `${entry.month} ${entry.day}`,
        title: entry.link.title
      }, true)
    })

    let update = {};
    update[`reminders-${key}-list`] = { inner };
    Utility.updateContent(update, this.el);
    !hasEntries && this.setEmpty(key);
  }

  setEmpty(type = "birthday", isEmpty = true) {
    let update = {};
    update[`reminders-${type}-block`] = {
      addClass: isEmpty ? "hidden" : "",
      removeClass: isEmpty ? "" : "hidden",
    }
    if (!isEmpty) {
      update[`reminders-empty-block`] = {
        addClass: "hidden"
      }
    } else {
      this.totalEmpty++;
      if (this.totalEmpty == 2) {
        update[`reminders-empty-block`] = {
          removeClass: "hidden"
        }
      }
    }
    Utility.updateContent(update, this.el)
  }

  setLoading(isLoading = true) {
    let update = {};
    ["birthday", "anniversary"].forEach(key => {
      update[`reminders-${key}-list`] = {
        addClass: isLoading ? "--loading" : "",
        removeClass: isLoading ? "" : "--loading",
      }
    });
    Utility.updateContent(update, this.el);
  }

}

export default Reminders;


