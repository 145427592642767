import Client from "../../../base/Client";
import Utility from "../../../base/Utility";

class BooleanField {

  constructor(el, form) {
    this.el = el;
    this.id = el.id;
    this.type = 'boolean';
    this.form = form;
    this.field = el.closest('.field') || null;
    this.fieldOutput = null;
    this.enabled = true;
    this.ready = false;
    this.abortController = new AbortController();
    this.name = el.getAttribute('data-name') || el.name;
    this.truename = el.name;
    this.key = el.name;
    this.nameLowercase = this.name.toLowerCase();
    this.isRadio = el.type == 'radio';
    this.getGroup = () => (this.form.el.querySelectorAll(`input[type="${this.el.type}"][name="${this.el.name}"]`));
    this.getIsGroup = () => (this.getGroup() && this.getGroup().length - 1 !== 0);
    this.fieldset = this.el.closest(`fieldset[name="${this.el.name}-fieldset"]`) || null;
    this.rules = new Map();
    this.errors = new Map();
    this.formErrors = new Map();
    this.removeErrorOnResolve = true;
    this.valid = true;
    this.outputList = null;
    this.isAcceptance = el.hasAttribute('data-acceptance');
    this.isMultiple = this.isRadio ? false : !el.hasAttribute('data-single');
    // lifecycle hooks
    this.onChange = (el, instance) => { }
    this.onCheck = (el, instance) => { }
    this.onUncheck = (el, instance) => { }
    this.init();
  }

  enable() {
    this.enabled = true;
  }

  isEnabled() {
    return this.enabled && !(this.el.closest('[aria-hidden="true"]') || Client.isHidden(this.el))
  }

  disable() {
    this.enabled = false;
    this.validate();
    let removed = this.form.errors.delete(this.truename);
    let removedHidden = this.form.hiddenErrors.delete(this.truename);
    (removed || removedHidden) && this.form.renderErrors();
  }

  init() {
    this.field?.classList.add('--group-lead');
    if (this.el.hasAttribute('required') || this.fieldset?.hasAttribute("required")) {
      if (this.fieldset) {
        this.fieldset.setAttribute('aria-required', 'true');
      } else {
        this.el.setAttribute('aria-required', 'true');
      }
      if (this.isRadio) {
        this.rules.set('required', {
          error: this.el.getAttribute('data-required-error') || 'Choose an option',
          formError: this.el.getAttribute('data-required-form-error') || `Choose an ${this.nameLowercase} to continue`,
          test: el => (el.checked == true),
        });
      } else {
        this.rules.set('required', {
          error: this.el.getAttribute('data-required-error') || (
            this.isAcceptance ? `Accept ${this.nameLowercase} to continue` :
              this.getIsGroup() ? `Please select one that apply` : `Checkbox is required`
          ),
          formError: this.el.getAttribute('data-required-form-error') || (
            this.isAcceptance ? `Accept ${this.nameLowercase} to continue` :
              this.getIsGroup() ? `Select at least one ${this.nameLowercase} that apply` : `Check ${this.nameLowercase} checkbox to continue`
          ),
          test: el => (el.checked == true),
        });
      }
    }

    // Listeners
    if (this.getIsGroup()) {
      this.getGroup().forEach(el => {
        this.handleCheck(el);
        el.closest('.field')?.classList.add('--init-as-group');
      })
    } else {
      this.handleCheck(this.el);
    }

    if (!this.field.classList.contains('--no-output')) {
      this.renderErrorOutput();
    }

    this.el.addEventListener("group-change", () => {
      this.removeError("server");
    })
    this.setReady();
  }

  getDecriptionSet(el) {
    let string = el.getAttribute("aria-describedby") || "";
    return string ? new Set(string.split(" ")) : new Set();
  }

  clearDescription(el = null) {
    let target = el || this.el;
    target.removeAttribute("aria-describedby");
  }

  addDescription(key, el = null) {
    if (el && typeof el == "string") {
      el = Array.from(this.getGroup()).find(item => item.value == el);
    }
    let target = el || this.el;
    let describedBy = this.getDecriptionSet(target);
    if (!key || describedBy.has(key)) return
    describedBy.add(key);
    let description = Array.from(describedBy).join(" ");
    target?.setAttribute("aria-describedby", description);
  }

  removeDescription(key, el = null) {
    if (el && typeof el == "string") {
      el = Array.from(this.getGroup()).find(item => item.value == el);
    }
    let target = el || this.el;
    let describedBy = this.getDecriptionSet(target);
    if (!key || !describedBy.has(key)) return
    if (describedBy.size == 1) return this.clearDescription(target)
    describedBy.delete(key);
    let description = Array.from(describedBy).join(" ");
    target.setAttribute("aria-describedby", description);
  }

  addServerError(message, temporary = false) {
    this.addError("server", message);
  }

  forwardChange(e) {
    this.handleCheck(e.target);
    this.getGroup()?.forEach(innerEl => {
      if (this.isRadio && !this.isMultiple) {
        if (innerEl.checked == true && innerEl != e.target) {
          innerEl.checked = false;
          this.handleCheck(innerEl);
        }
      }
    })
    this.validate();
  }

  destroy() {
    this.abortController.abort();
  }

  handleCheck(el, trigger = true) {
    if (el.checked) {
      el.setAttribute('checked', '');
    } else {
      if (el.hasAttribute('checked')) el.removeAttribute('checked');
    }
    if (trigger) {
      this.el.dispatchEvent(new Event("group-change"))
      this.onChange(el, this);
      if (el.checked) {
        this.onCheck(el, this);
      } else {
        this.onUncheck(el, this);
      }
    }
  }

  setReady() {
    this.form.fieldReady(this.truename);
    this.ready = true;
  }

  unsetReady() {
    this.form.fieldUnready(this.truename);
    this.ready = false;
  }

  setValue(value = false, trigger = false) {
    if (!this.getIsGroup()) {
      let check = ["checked", "1", "true", true].includes(value);
      let changed = this.el.checked != check; 
      this.el.checked = check;
      changed && trigger && this.el.dispatchEvent(new Event("change", {bubbles: true}));
      this.handleCheck(this.el, trigger);
    }
  }

  renderErrorOutput() {
    this.fieldOutput = this.field.querySelector('.field__output');
    if (!this.fieldOutput) {
      let inner = `
      <span class="field__output"><div class="error-wrapper"></div></span>
      `;
      this.field.insertBefore(Utility.fragmentFromString(inner), this.field.firstElementChild);
    }
    this.fieldOutput = this.field.querySelector('.field__output');
    this.outputList = this.fieldOutput.querySelector('.error-wrapper');
    Utility.addResizeObserver(this.fieldOutput, this.outputChange.bind(this));
  }

  outputChange() {
    if (this.outputList.children.length != 0) {
      this.fieldOutput.classList.remove('--empty');
      this.fieldOutput.setAttribute('aria-hidden', 'false');
    } else {
      this.fieldOutput.classList.add('--empty');
      this.fieldOutput.setAttribute('aria-hidden', 'true');
    }
  }

  renderErrors() {
    if (!this.fieldOutput) return
    this.errors.forEach((value, key) => {
      if (!this.fieldOutput.querySelector(`#${this.id}-${key}`)) {
        let inner = `
        <span id="${this.id}-${key}" class="field__error --new">${value}</span>
        `;
        this.fieldOutput?.firstElementChild?.appendChild(Utility.fragmentFromString(inner));
      } else {
        this.fieldOutput.querySelector(`#${this.id}-${key}`).classList.add('--new');
      }
      this.el.setAttribute('aria-describedby', `${this.id}-${key}`);
      this.el.setAttribute('aria-invalid', 'true');
    })
    this.clearErrors();
  }

  clearErrors() {
    const errors = this.fieldOutput?.querySelectorAll('.field__error');
    if (!errors || errors.length == 0) {
      this.el.removeAttribute('aria-describedby');
      this.el.removeAttribute('aria-invalid');
    } else {
      errors.forEach(error => {
        if (error.classList.contains('--new')) {
          error.classList.remove('--new')
        } else {
          error.remove();
          this.el.removeAttribute('aria-describedby');
          this.el.removeAttribute('aria-invalid');
        }
      })
    }
  }

  addError(key, error, formError) {
    this.errors.set(key, error);
    this.formErrors.set(key, formError);
    this.form.errors.set(this.truename, formError);
    this.renderErrors();
  }

  removeError(key) {
    this.errors.delete(key);
    this.formErrors.delete(key);
    this.form.errors.delete(this.truename);
    this.renderErrors();
  }

  validate() {
    if (this.form.validationDisabled) return
    this.errors = new Map();
    this.formErrors = new Map();
    this.form.errors.delete(this.truename);
    this.valid = true;
    let pass = false;
    this.rules.forEach((value, key) => {
      if (!this.isEnabled()) {
        pass = true;
      } else {
        if (this.getIsGroup()) {
          this.getGroup().forEach((el => {
            if (value.test(el)) {
              pass = true;
            }
          }))
        } else {
          if (value.test(this.el)) {
            pass = true;
          }
        }
      }
      if (!pass) {
        this.addError(key, value.error, value.formError);
        this.valid = false;
      } else {
        this.removeError(key);
      }
    })

    this.revalidate();
    if (this.valid) {
      this.field.classList.remove('--error');
    } else {
      if (!this.field.classList.contains('--no-output')) {
        this.field.classList.add('--error');
      }
    }
    return this.valid;
  }

  revalidate() {
    if (this.form.notSubmitted == true) return
    let pass = false;
    const errorEl = this.form.el.querySelector(`#${this.form.id}-${this.el.name}-error`);
    if (!errorEl) return

    this.errors.forEach((error, key) => {
      if (key == 'required') {
        errorEl.firstElementChild.innerText = this.rules.get('required').formError;

        if (this.getIsGroup()) {
          this.getGroup().forEach((el => {
            if (this.rules.get('required').test(el)) {
              pass = true;
            }
          }))
        } else {
          if (this.rules.get('required').test(this.el)) {
            pass = true;
          }
        }

        if (!pass) {
          errorEl.classList.remove('--resolved');
        } else {
          errorEl.classList.add('--resolved');
          if (this.removeErrorOnResolve) { errorEl.remove() }
        }
      }
    })
    return;
  }
}

export default BooleanField;