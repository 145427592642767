class DynamicYield {
  constructor(services) {
    this.config = window.app_env?.dy_config;
    this.services = services;
    this.buffer = new Set();
    this.enabled = false;
    this.init();
  }

  init() {
    // Any DY setup required for page
    if (!this.config) return;
    if (["payment", "pof"].includes(window.app_env?.page_type)) return
    if (window.app_env?.page_type.includes("webview")) return

    this.enabled = true;
    window.DY = window.DY || {};
    window.DY.recommendationContext = this.getContext();
    this.loaded().then(() => {
      this.identifyCustomer();
      this.setMemberStatus();
      this.buffer?.forEach(this.sendEvent.bind(this));
      this.buffer = new Set();
    }).catch(() => {})
  }

  loaded() {
    return new Promise((resolve, reject) => {
      if (window.DY?.API) {
        resolve()
      } else {
        let limit = 30;
        let ticker = setInterval(() => {
          if (window.DY?.API) {
            clearInterval(ticker);
            resolve()
          } else if (limit == 0) {
            clearInterval(ticker);
            reject()
          }
          limit--
        }, 1000);
      }
    })
  }

  setMemberStatus() {
    let member = Boolean(window.app_env?.customer?.is_paid)
    let value = window.app_storage.get("ag_dy_member_status", "session")
    if (value && value == String(member)) return
    window.app_storage.set("ag_dy_member_status", String(member), "session");
    DY.API("event", {
      name: "member_status",
      properties: { member }
    });
  }

  identifyCustomer() {
    let email = window.app_env?.customer?.email;
    if (!email || window.app_storage.getCookie("ag_dy_remember_me")) return
    window.app_storage.setCookie("ag_dy_remember_me", "used");
    DY.API("identify", {
      uid: DYO.dyhash.sha256(email),
      type: "he"
    });
  }

  track(content) {
    if (!content || !this.enabled) return
    !this.sendEvent(content) && this.buffer.add(content);
  }

  sendEvent(content) {
    if (!window.DY?.API) return false
    if (typeof content == "string") {
      window.DY.API("event", {name: content});
    } else {
      if (content.properties?.hashedEmail) {
        content.properties.hashedEmail = window.DYO?.dyhash?.sha256(content.properties.hashedEmail);
        window.DYO?.dyhash && window.DY.API("event", content);
      } else {
        window.DY.API("event", content);
      }
    }
    return true;
  }

  getContext() {
    let context = {}
    if (["archive"].includes(app_env.page_type)) {
      context["type"] = "CATEGORY";
      context["data"] = this.getFilters();
    } else if (["pdp", "pickup"].includes(app_env.page_type)) {
      context["type"] = "PRODUCT";
      context["data"] = [`${String(app_env.product?.product_number || "")}`];
    } else if (window.location.pathname == '/') {
      context["type"] = "HOMEPAGE";
    } else {
      context["type"] = "OTHER";
      context["data"] = [app_env.page_type];
    }
    return context
  }

  getFilters() {
    let filters = [];
    if (app_env.navigation_request?.filters) {
      for (let facet in app_env.navigation_request.filters) {
        app_env.navigation_request?.filters[facet].forEach(option => {
          filters.push(option);
        });
      }
    }
    return filters
  }
}

export default DynamicYield