import Utility from "../../../base/Utility";
import BaseProduct from "./BaseProduct";

class TalkingSmashupProduct extends BaseProduct {
  constructor(el, parent, type, env=null) {
    super(el, parent, type, env);
    this.previewIsLoading = false;
    this.initSmashupTTS();
  }

  initSmashupTTS() {
    if (this.form) {
      this.form.isInactive = true;
    }
    Utility.updateContent({
      "send-submit": {
        removeClass: "form-interact",
      }
    }, this.el)
    this.el.classList.add('--waits-visible');
    this.loadProduct();
    Utility.elementRendered('canvas', this.container).then((el) => {
      if (this.form) {
        this.form?.fields.forEach(field => {
          field.initProxy();
        })
        this.manageSelfieInteractions();
      }
      this.manageExpressionButtons();
      setTimeout(() => {
        this.ready();
      }, 100)
    })
  };

  manageExpressionButtons() {
    Utility.elementRendered("#agi-expression-closed-caption-button", this.container).then(el => {
      el.addEventListener("keyup", (e) => {
        if (!["Space", "Enter"].includes(e.code)) return
        e.target.dispatchEvent(new Event("mouseup"));
      })
      this.container.querySelector("#agi-expression-mute-button")?.addEventListener("keyup", (e) => {
        if (!["Space", "Enter"].includes(e.code)) return
        e.target.dispatchEvent(new Event("mouseup"));
      })
      let button = document.createElement("button");
      button.setAttribute("aria-label", "pause animations");
      button.setAttribute("tabindex", "0");
      button.classList.add("product-detail__animation-toggle", "--playing");
      button.addEventListener("click", (e) => {
        let expression = window.agi?.expressions?.ExpressionManager?.expression?.stage;
        if (!expression) return
        this.toggleAnimation(!expression.tickEnabled, e.target);
      })
      el.parentNode.insertBefore(button, el);
    })
  }

  toggleAnimation(on, button = null) {
    button = button || this.container.querySelector(".product-detail__animation-toggle");
    let expression = window.agi?.expressions?.ExpressionManager?.expression?.stage;
    if (!expression) return
    expression.tickEnabled = on;
    button.setAttribute("aria-label", `${!on ? "pause" : "play"} animations`);
    button.classList.toggle("--playing", on);
    button.classList.toggle("--paused", !on);
  }

  afterReady() {
    window.agi?.expressions?.Express?.dispatcher?.addEventListener("playAudio", this.previewStarted.bind(this));
    this.form?.saver?.load();
    setTimeout(() => {
      this.setFormInactive(false);
    }, 100);
    if (this.iframe) {
      window.addEventListener("message", e => {
        if (!e.data?.name) return
        if (e.data.name == "product-preview-loading") {
          this.previewLoading();
        } else if (e.data.name == "product-preview-started") {
          this.previewStarted();
        } else if (e.data.name == "product-preview-ended") {
          this.previewEnded();
        }
      }, {signal: this.listeners.signal})
    }
  }

  initListeners() {
    this.productInterface?.addEventListener(agi.ProductEvent.PLAYBACK_COMPLETE, (e) => {
      this.previewEnded();
    });
  }

  triggerStop() {
    this.iframe?.contentWindow?.document?.querySelector("#agi-expression-stop")?.click();
    if (this.previewIsLoading) return
    if (window.agi?.expressions?.Express) {
      let ccButton = this.container.querySelector("#agi-expression-closed-caption-button.toggled");
      ccButton?.dispatchEvent(new Event("mouseup"));
      let self = agi.expressions.ExpressionManager.expression;
      let Express = agi.expressions.Express;
      self.stop();
      Express.playbackComplete();
      Express.stopAudio("music2");
      Express.musicIsPlaying = false;

      if (Express.mode == "init") {
        Express.addTouchEvents();
        self.gotoAndStop("idle");
      } else {
        Express.showReplayButton();
      }
    }
  }

  previewLoading() {
    this.previewIsLoading = true;
    this.toggleAnimation(true);
    Utility.updateContent({
      'preview-btn': {
        addClass: '--loading'
      },
    })
  }

  previewStarted(e) {
    this.previewIsLoading = false;
    this.toggleAnimation(true);
    this.container.classList.add("--previewing");
    Utility.updateContent({
      'preview-btn': {
        "data-product-action": "stop",
        removeClass: '--loading',
        "aria-label": "stop",
      },
      'preview-btn-icon': {
        inner: '<path fill="currentColor" fill-rule="evenodd" d="M3 4.5A1.5 1.5 0 0 1 4.5 3h9A1.5 1.5 0 0 1 15 4.5v9a1.5 1.5 0 0 1-1.5 1.5h-9A1.5 1.5 0 0 1 3 13.5v-9Z" clip-rule="evenodd"/>'
      },
      'preview-btn-text': {
        inner: 'Stop'
      }
    })
  }

  previewEnded() {
    this.container.classList.remove("--previewing");
    Utility.updateContent({
      'preview-btn': {
        "data-product-action": "preview",
        removeClass: '--loading',
        "aria-label": "preview",
      },
      'preview-btn-icon': {
        inner: '<path fill="currentColor" fill-rule="evenodd" d="M4.252 3.57a1.102 1.102 0 0 1 1.53-.883c.797.34 2.582 1.15 4.847 2.457 2.265 1.308 3.86 2.45 4.551 2.969a1.102 1.102 0 0 1 .001 1.767c-.685.515-2.26 1.642-4.552 2.967-2.295 1.324-4.06 2.124-4.848 2.46a1.1 1.1 0 0 1-1.529-.883c-.103-.856-.297-2.801-.297-5.428 0-2.625.193-4.569.297-5.426Z" clip-rule="evenodd"/>'
      },
      'preview-btn-text': {
        inner: 'Preview'
      }
    })
  }

  triggerPreview(e) {
    if (this.isSending) return
    if (this.previewIsLoading) return
    if (this.iframe) {
      this.iframe.contentWindow.document.querySelector("#agi-expression-preview")?.click();
      return
    }
    const frontendIsValid = this.form?.validate();
    e?.preventDefault();
    if (frontendIsValid) {
      // Frontend Valid
      this.form?.syncErrors();
      this.form?.renderErrors();
      this.initPreview();
    } else {
      this.form?.complete("error");
    }
  }

  triggerSend(e) {
    if (this.isSending) return
    if (this.previewIsLoading) return
    let isLink = e.target.tagName == "A";
    this.triggerStop();
    e.target?.classList.add("--loading");
    this.form?.el.classList.add("--loading");
    if (!isLink) {
      const frontendIsValid = this.form?.validate();
      e?.preventDefault();
      if (frontendIsValid) {
        // Frontend Valid
        this.form?.syncErrors();
        this.form?.renderErrors();
        this.isSending = true;
        this.initSend(e.target);
      } else {
        e.target?.classList.remove("--loading");
        this.form?.complete("error");
      }
    }
  }


  initPreview() {
    this.previewLoading();
    Utility.scrollTo(this.placer, 100, true);
    let voice = this.form.fields.get("voice")?.getValue();
    let message = this.form.fields.get("message")?.getValue();
    this.productInterface.previewProduct({ message, voice });
  };

  async initSend(trigger) {
    let voice = this.form.fields.get("voice")?.getValue();
    let message = this.form.fields.get("message")?.getValue();
    this.productInterface.updatePersonalizations({ message, voice });
    const personalizations = this.getPersonalizations();
    this.setFormPersonalizations(personalizations);
    this.form.el.submit();
  };

  manageSelfieInteractions() { };

}

export default TalkingSmashupProduct;
