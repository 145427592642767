class Helpers {
  static initExpressionResizeWatcher(container, type) {
    let expressionContainer = container.querySelector("#agi-expression-container");
    if (!expressionContainer) return
    let observer = new ResizeObserver(() => {
      let rect = expressionContainer.getBoundingClientRect();
      // Dispatch a window resize event to trigger products resize method
      if (["selfie-smashup", "talking-smashup", "interactive"].includes(type) && container.getBoundingClientRect().width != rect.width) {
        setTimeout(() => {
          expressionContainer.style.setProperty("width", container.getBoundingClientRect().width + "px");
          window.dispatchEvent(new Event("resize"));
        }, 0)
      } else if (type == "interactive" && expressionContainer.querySelector("#expression-video-container")) {
        setTimeout(() => {
          window.dispatchEvent(new Event("resize"));
        }, 0)
      }
      if (container.getBoundingClientRect().width < rect.width) {
        expressionContainer.classList.add("--fullscreen");
      } else {
        expressionContainer.classList.remove("--fullscreen");
      }
    })
    observer.observe(expressionContainer);
  }

  static getStringChanges(current, last) {
    if (current == last) return false;
    if (last) {
      return current ? "changed" : "removed"
    } else {
      return current ? "added" : false
    }
  }

  static getEventChanges(current, last=null, slug) {
    let changes = []
    if (!current && !last) return []
    if (last) {
      !current && changes.push(`removed-${slug}`);
    } else {
      current && changes.push(`added-${slug}`);
    }

    last = last || {reminders: []};
    current = current || {reminders: []};
    ["day", "month", "year"].forEach(key => {
      let change = Helpers.getStringChanges(String(current[key] || ""), String(last[key] || ""));
      change && changes.push(`${change}-${slug}-${key}`);
    });

    ["-86400", "-604800"].forEach(value => {
      if (!String(last.reminders).includes(value) && !String(current.reminders).includes(value)) return
      if (String(last.reminders).includes(value) && String(current.reminders).includes(value)) return
      if (String(last.reminders).includes(value)) {
        if (!String(current.reminders).includes(value) && !changes.includes(`removed-${slug}-reminder`)) {
          changes.push(`removed-${slug}-reminder`);
        }
      } else {
        if (String(current.reminders).includes(value) && !changes.includes(`added-${slug}-reminder`)) {
          changes.push(`added-${slug}-reminder`);
        }
      }
    });

    return changes
  }

  static getEventsChanges(current, last) {
    let changes = [];
    if (!current && !last) return []
    let currentAnniversary = null;
    let lastAnniversary = null;
    let currentBirthday = null;
    let lastBirthday = null;
    current?.forEach(event => {
      if (event.occasion_id == 1) {
        currentBirthday = event
      } else {
        currentAnniversary = event
      }
    })
    last?.forEach(event => {
      if (event.occasion_id == 1) {
        lastBirthday = event
      } else {
        lastAnniversary = event
      }
    })
    let anniversaryChanges = Helpers.getEventChanges(currentAnniversary, lastAnniversary, "anniversary");
    let birthdayChanges = Helpers.getEventChanges(currentBirthday, lastBirthday, "birthday");

    anniversaryChanges && changes.push(...anniversaryChanges);
    birthdayChanges && changes.push(...birthdayChanges);

    return changes;
  }

  static getContactChanges(newContact, oldContact={}) {
    let changes = [];
    oldContact = oldContact || {};
    ["email", "fname", "lname"].forEach(key => {
      let change = Helpers.getStringChanges(newContact[key], oldContact[key]);
      change && changes.push(`${change}-${key}`);
    });

    let eventChanges = Helpers.getEventsChanges(newContact.events, oldContact?.events);
    eventChanges && changes.push(...eventChanges);

    return changes
  }

  static convertDateTimeFromServer(datetime) {
    if (typeof datetime == 'string') {
      datetime = new Date(datetime + "Z")
    }
    let offsetMinutes = window.app_env?.minutes_offset ?? -240;
    return new Date(datetime.getTime() - offsetMinutes  * 60 * 1000);
  }

  static convertDateTimeForServer(datetime) {
    let offsetMinutes = window.app_env?.minutes_offset ?? -240;
    return new Date(datetime.getTime() + offsetMinutes  * 60 * 1000);
  }

  static formatDate(date) {
    let day = Number(date.getDate()).pad(2);
    let month = (Number(date.getMonth()) + 1).pad(2);
    let year = date.getFullYear();
    return `${year}-${month}-${day}`
  }

  static getTimeDelta(startDate = new Date(), endDate = new Date(), units = null) {
    const miliseconds = endDate.getTime() - startDate.getTime();
    const seconds = Math.round(miliseconds / 1000);
    const minutes = Math.round(seconds / 60);
    const hours = Math.round(minutes / 60);
    const days = Math.round(hours / 24);
    const deltas = {miliseconds, seconds, minutes, hours, days};
    if (units in deltas) {
      return deltas[units]
    }
    return deltas
  }

  static getDateDelta(startDate = new Date(), endDate = new Date(), units = null) {
    startDate = new Date(this.formatDate(startDate));
    endDate = new Date(this.formatDate(endDate));
    return this.getTimeDelta(startDate, endDate, units);
  }

  static strftime(sFormat, date) {
    if (!(date instanceof Date)) date = new Date();
    var nDay = date.getDay(),
      nDate = date.getDate(),
      nMonth = date.getMonth(),
      nYear = date.getFullYear(),
      nHour = date.getHours(),
      aDays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
      aMonths = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
      aDayCount = [0, 31, 59, 90, 120, 151, 181, 212, 243, 273, 304, 334],
      isLeapYear = function() {
        if ((nYear&3)!==0) return false;
        return nYear%100!==0 || nYear%400===0;
      },
      getThursday = function() {
        var target = new Date(date);
        target.setDate(nDate - ((nDay+6)%7) + 3);
        return target;
      },
      zeroPad = function(nNum, nPad) {
        return ('' + (Math.pow(10, nPad) + nNum)).slice(1);
      };
      return sFormat.replace(/%[a-z]/gi, function(sMatch) {
        return {
          '%a': aDays[nDay].slice(0,3),
          '%A': aDays[nDay],
          '%b': aMonths[nMonth].slice(0,3),
          '%B': aMonths[nMonth],
          '%c': date.toUTCString(),
          '%C': Math.floor(nYear/100),
          '%d': zeroPad(nDate, 2),
          '%e': nDate,
          '%F': date.toISOString().slice(0,10),
          '%G': getThursday().getFullYear(),
          '%g': ('' + getThursday().getFullYear()).slice(2),
          '%H': zeroPad(nHour, 2),
          '%I': zeroPad((nHour+11)%12 + 1, 2),
          '%j': zeroPad(aDayCount[nMonth] + nDate + ((nMonth>1 && isLeapYear()) ? 1 : 0), 3),
          '%k': '' + nHour,
          '%l': (nHour+11)%12 + 1,
          '%m': zeroPad(nMonth + 1, 2),
          '%M': zeroPad(date.getMinutes(), 2),
          '%p': (nHour<12) ? 'AM' : 'PM',
          '%P': (nHour<12) ? 'am' : 'pm',
          '%s': Math.round(date.getTime()/1000),
          '%S': zeroPad(date.getSeconds(), 2),
          '%u': nDay || 7,
          '%V': (function() {
                  var target = getThursday(),
                    n1stThu = target.valueOf();
                  target.setMonth(0, 1);
                  var nJan1 = target.getDay();
                  if (nJan1!==4) target.setMonth(0, 1 + ((4-nJan1)+7)%7);
                  return zeroPad(1 + Math.ceil((n1stThu-target)/604800000), 2);
                })(),
          '%w': '' + nDay,
          '%x': date.toLocaleDateString(),
          '%X': date.toLocaleTimeString(),
          '%y': ('' + nYear).slice(2),
          '%Y': nYear,
          '%z': date.toTimeString().replace(/.+GMT([+-]\d+).+/, '$1'),
          '%Z': date.toTimeString().replace(/.+\((.+?)\)$/, '$1')
        }[sMatch] || sMatch;
      });
  }

  static convertDateForHumans(date, full = false, prefixed = false) {
    let targetDate = date instanceof Date ? date : new Date(date);
    let today = new Date();
    let format = "%B %d";
    let daysLeft = this.getDateDelta(today, targetDate, "days");
    let formatted = `on ${this.strftime(`${format}, %Y`, targetDate)}`;
    if (today.getFullYear() === targetDate.getFullYear()) {
      formatted = `on ${this.strftime(format, targetDate)}`;
    }
    if (daysLeft >= 0) {
      if (daysLeft == 0) {
        if (full) {
          formatted = `today (${this.strftime(format, targetDate)})`;
        } else {
          formatted = prefixed ? "for Today" : "Today";
        }
      } else if (daysLeft == 1) {
        if (full) {
          formatted = `tomorrow (${this.strftime(format, targetDate)})`;
        } else {
          formatted = prefixed ? "for Tomorrow" : "Tomorrow";
        }
      } else if (daysLeft <= 31) {
        formatted = full ? `in ${daysLeft} days (${this.strftime(format, targetDate)})` : `in ${daysLeft} days`;
      }
    }

    return formatted
  }

}

export default Helpers;