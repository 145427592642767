import SelectField from "./SelectField";

class PredictiveSearchField extends SelectField {
  constructor(el, form) {
    super(el, form);
    this.fetchController = null;
    this.sourceKey = this.el.getAttribute('data-source');
    this.el.setAttribute('type', 'search');
    this.el.removeAttribute('disabled');
    this.prepopulateOptions();
  }

  get isConstructor() {
    return this.isAutocomplete && this.el.getAttribute('data-source') == "constructor"
  }

  prepopulateOptions() {
    if (!this.sourceKey || this.queriedTerm == "") return
    this.optionsData = null;
    this.initExternalRequest();
  }

  buildOptionsFromResponse(response) {
    this.el.setAttribute("aria-busy", false);
    !this.form.isSubmitting && this.field.classList.remove('--loading');
    const options = {};
    if (this.isConstructor) {
      if (this.queriedTerm) {
        options[`<strong>${this.queriedTerm}</strong>`] = this.queriedTerm;
      }
      if (response.hasOwnProperty('sections')) {
        response.sections['Search Suggestions']?.forEach(suggestion => {
          suggestion.value = this.filterRegEx(suggestion.value);
          if (suggestion.value === this.queriedTerm) {
            this.hasFullQueryMatch = true;
            return
          }
          if (Object.keys(options).length == 6) return
          let title = suggestion.value;
          suggestion.matched_terms?.forEach(match => {
            let tokens = match.split('').map(token => `(${token})`);
            tokens = tokens.join("('|\"|`|’)?");
            title = title.replace(new RegExp(`${tokens}(?![^<>]*>)`, 'i'), (term) => `<strong>${term}</strong>`);
          })
          options[title] = suggestion.value;
        })
      }
    }
    return options
  }

  buildRequestObject() {
    let url = null;
    let options = {};
    if (this.isConstructor) {
      let cio = window.app_services?.constructor?.buildAutocomplete(this.queriedTerm);
      url = cio?.url || null;
      options = cio?.options || {};
    }
    return {url, options}
  }

  initExternalRequest(open = true) {
    this.fetchController?.abort();
    let {url, options} = this.buildRequestObject();
    if (!url) return
    this.el.setAttribute("aria-busy", true);
    this.field.classList.add('--loading');
    this.fetchController = new AbortController();
    fetch(url, { ...options, signal: this.fetchController.signal })
      .then(response => response.json())
      .then(data => {
        this.loadOptions(this.buildOptionsFromResponse(data));
        if (open) {
          this.openListbox(false, "input");
          this.focusOption();
        }
      })
      .catch(e => {})
  }

  parseOptionAttributes(atts, option) {
    if (this.isConstructor && !(this.hasFullQueryMatch && option.counter == 0)) {
      atts["data-cnstrc-item-section"] = "Search Suggestions";
      atts["data-cnstrc-item-name"] = option.value;
    }
    let attributes = [];
    for (const key in atts) {
      attributes.push(`${key}="${atts[key]}"`);
    }
    return attributes.join(" ")
  }

  parseListboxAttributes(atts = []) {
    this.isConstructor && atts.push("data-cnstrc-autosuggest");
    return atts.join(" ")
  }

  buildOptions(focusOption = true) {
    if (this.sourceKey) {
      this.optionsData = null;
      if (this.queriedTerm != '') {
        return this.initExternalRequest();
      }
      this.closeListbox(true, "input");
      if (this.fetchController) {
        this.fetchController?.abort();
        this.field.classList.remove('--loading');
        this.el.setAttribute("aria-busy", false);
        this.optionsData = null;
      }
      return
    }
    if (!this.optionsData) return
    let options = this.searchOptions();
    this.renderOptions(options);
    this.openListbox(false, "input");
    this.focusOption();
  }
}

export default PredictiveSearchField;