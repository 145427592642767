// This extends the Number class methods to be able to pad numbers by specified size
// example for number 2 with pad size 3 it will output 002, used for date configuration
Number.prototype.pad = function (size) {
  let s = String(this);
  while (s.length < (size || 2)) { s = "0" + s; }
  return s;
}

// This helps to capitalize a string
String.prototype.capitalize = function () {
  if(this == "") return ""
  return this.replace(/\b\w/g, function (letter) {
    return letter.toUpperCase();
  });
}

// This helps external .submit() method on forms to dispatch an event
// instead of submitting directly to server and avoiding frontend validation
// also saved the original submit under _submit just in case.
HTMLFormElement.prototype._submit = HTMLFormElement.prototype.submit;
function requestedSubmit(e) {
  // Here should be used SubmitEvent but Safari supports SubmitEvent only after 15
  this.dispatchEvent(new Event('submit', { cancelable: true }));
}

HTMLFormElement.prototype.submit = requestedSubmit;


Array.prototype.removeItem = function(item) {
  let array = this;
  const index = array.indexOf(item);
  index > -1 && array.splice(index, 1);
  return array;
}

HTMLElement.prototype.getInstance = function(type) {
  if (!type || !this.id) return null
  return window[`app_${type}`]?.collection?.get(this.id);
}

window.alert = (message) => {
  console.warn("[Alert Voided]", message);
}