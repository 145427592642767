import Client from "../base/Client";
import Utility from "../base/Utility";

class Sticky {
  constructor() {
    this.collection = new Map();
    this.init();
  }

  init() {
    const els = document.querySelectorAll('.bidirectional-sticky');
    if (!els || els.length == 0) return
    els.forEach(this.initSticky.bind(this));
  }

  initSticky(el) {
    if (el.id == '') {
      el.id = `sticky-instance`;
    }
    Utility.fixDuplicates(el);
    this.collection.set(el.id, new StickyInstance(el))
  }
}

class StickyInstance {
  constructor(el) {
    this.el = el;
    this.name = el.id;
    this.elHeight;
    this.viewportHeight;
    this.topOffset;
    this.botOffset;
    this.containerHeight;
    this.pageSpacing;
    this.spacing;
    this.isStickable = false;
    this.lastScroll = 0;
    this.topOffset = null;
    this.botOffset = null;
    this.observer = null;
    this.init();
  }

  init() {
    window.app_listeners?.add('resize', this.name, this.update.bind(this));
    Utility.addResizeObserver(this.el, this.update.bind(this), false);
    Utility.addResizeObserver(document.querySelector('.content-wrapper'), this.update.bind(this), false);
    this.initObserver();
  }

  clear() {
    this.el.classList.remove('--sticky-bottom');
    this.el.parentElement.style.setProperty('padding-top', '');
    this.el.parentElement.style.setProperty('padding-bottom', '');
  }

  handleSticky() {
    // Check scrolling direction
    if (this.lastScroll > window.scrollY) {
      // Is scrolling to top
      if (this.botOffset == null) {
        const botOffset = (this.viewportHeight - this.el.getBoundingClientRect().bottom) - (this.viewportHeight - this.el.parentElement.getBoundingClientRect().bottom);
        this.el.parentElement.style.setProperty('padding-bottom', botOffset != 0 ? `${botOffset}px` : '');
        this.botOffset = botOffset;
      }
      this.el.classList.remove('--sticky-bottom');
      this.topOffset = null;
      this.el.parentElement.style.setProperty('padding-top', '');
    } else if (this.lastScroll < window.scrollY) {
      // Is scrolling to bottom
      if (this.topOffset == null) {
        const topOffset = this.el.getBoundingClientRect().top - this.el.parentElement.getBoundingClientRect().top;
        this.el.parentElement.style.setProperty('padding-top', topOffset != 0 ? `${topOffset}px` : '');
        this.topOffset = topOffset;
      }
      this.el.classList.add('--sticky-bottom');
      this.botOffset = null;
      this.el.parentElement.style.setProperty('padding-bottom', '');
    }
    // Saving last scroll value
    this.lastScroll = window.scrollY;
  }

  update() {
    this.elHeight = this.el.getBoundingClientRect().height;
    this.el.style.setProperty('--el-height', this.elHeight + 'px');
    this.viewportHeight = Client.height;
    this.pageSpacing = Utility.getRootCssVar('--spacing', 'number');
    this.containerHeight = this.el.parentElement.getBoundingClientRect().height;
    this.topOffset = Utility.getRootCssVar('--main-bar-height', 'number') + this.pageSpacing * 1;
    this.botOffset = this.pageSpacing * 1;
    this.handleSticky();
    if (this.observer) {
      this.observer.unobserve(this.el.parentElement);
      this.observer.observe(this.el.parentElement);
    }
    if (this.containerHeight > this.elHeight && (this.elHeight + this.topOffset + this.botOffset >= this.viewportHeight)) {
      this.isStickable = true;
    } else {
      this.isStickable = false;
      if (window.app_listeners?.events.scroll.has(this.name)) {
        window.app_listeners?.remove('scroll', this.name);
      }
      this.clear();
    }
  }

  intersectionHandler(entries) {
    if (!entries[0].isIntersecting) {
      if (window.app_listeners?.events.scroll.has(this.name)) {
        window.app_listeners?.remove('scroll', this.name);
      }
    } else if (this.isStickable) {
      window.app_listeners?.add('scroll', this.name, this.handleSticky.bind(this));
    }
  }

  initObserver() {
    this.observer = new IntersectionObserver(this.intersectionHandler.bind(this));
    this.observer.observe(this.el.parentElement);
  }
}

export default Sticky;