import Preview from "./Preview";
import Utility from "../../../base/Utility";

class TalkingSmashupPreview extends Preview {

  constructor(el, product, pers) {
    super(el, product, pers);
    this.hasVideo = true;
    this.type = 'talking-smashup';
    this.productHasReplay = false;
    this.productReplayHandler = () => { };
  }

  afterInit() {
    this.isReady = true;
    this.loadProduct();
    this.watchLoader();
  }

  watchLoader() {
    this.el.classList.remove('--ready', '--after-ready');
    this.el.classList.add('--waits-visible');
    Utility.elementRendered('#agi-expression-container', this.container).then((el) => {
      Utility.elementRendered('img, canvas', this.container).then((el) => {
        setTimeout(() => {
          this.ready();
        }, 100)
      })
    })
  }

  afterReady() {
    window.agi?.expressions?.Express?.dispatcher?.addEventListener("playAudio", this.playAudioHandler.bind(this));
  }

  playAudioHandler() {
    if (this.dialog && !this.dialog.isActive) {
      this.resetMedia();
    }
  }

  resetMedia() {
    if (window.agi?.expressions?.Express) {
      let ccButton = this.container.querySelector("#agi-expression-closed-caption-button.toggled");
      ccButton?.dispatchEvent(new Event("mouseup"));
      let self = agi.expressions.ExpressionManager.expression;
      let Express = agi.expressions.Express;
      if (!self || !Express) return
      self.stop();
      Express.playbackComplete();
      Express.stopAudio("music2");
      Express.musicIsPlaying = false;

      self.gotoAndStop("idle");
      self.pickupBackground.visible = true;
      self.playButton.visible = true;
      Express.hideMuteButton();
      Express.hideClosedCaptionButton();
      Express.hideReplayButton();
    }
  }

}

export default TalkingSmashupPreview;