import Tracker from "./Tracker";
import DynamicYield from "./DynamicYield";
import ConstructorIO from "./ConstructorIO";
import LobsterStorage from "./LobsterStorage";
import Tealium from "./Tealium";
import GoogleAnalytics from "./GoogleAnalytics";

class Services {
  constructor() {
    this.dy = new DynamicYield(this);
    this.ga = new GoogleAnalytics(this);
    this.constructor = new ConstructorIO(this);
    this.tracker = new Tracker(this);
    this.tealium = new Tealium(this);
    this.lobster = new LobsterStorage(this);
    this.storage = window.app_storage;
  }

  track(event, content = null) {
    this.tracker?.track(event, content);
  }

  getCountries() {
    return new Promise((resolve, reject) => {
      if (this.storage.get("countries")) {
        resolve(this.storage.get("countries"));
      } else {
        fetch("/get-countries").then(response => {
          if (!response) throw new Error("no response");
          return response.json()
        }).then(json => {
          if (!json || !json.countries) throw new Error("no json");
          this.storage.set("countries", json.countries)
          resolve(json.countries)
        }).catch(e => {
          reject(null)
        })
      }
    })
  }
}

export default Services;