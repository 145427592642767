import Utility from "../../../base/Utility";
import BaseProduct from "./BaseProduct";

class CreatacardProduct extends BaseProduct {
  constructor(el, parent, type, env=null) {
    super(el, parent, type, env);
    this.manifest = this.config?.manifest || null;
    this.placer = this.el.querySelector(".product-builder-container");
    this.options = {};
    this.musicSelection = {};
    this.assetsPath = `${this.env?.imghost}/product/unifiedbuilder/${this.product.product_number}/`;
    this.loadCreatacard();
  }

  loadCreatacard() {
    if (!this.manifest) return console.warn("No manifest found");
    this.handleEnvelope();
    if (this.env.page_type == "quickview" && !window.agi?.CardLoader) {
      Utility.loadResources("card-loader-lib", [this.config.card_loader_lib], () => {
        this.renderCreatacard();
      }, true)
    } else {
      this.renderCreatacard();
    }
    this.initMusicPlay();
  };

  renderCreatacard() {
    if (!this.placer) return console.warn("No creatacard container on page");
    this.loader = new agi.CardLoader(this.placer);
    this.loader.addEventListener("cardReady", (e) => {
      setTimeout(() => {
        this.el.querySelector(".creatacard__container")?.classList.add("--loaded");
        Utility.updateContent({
          "creatacard-music-control": {
            removeClass: "--inactive",
            tabindex: null
          }
        })
        this.el.querySelectorAll(`input[name="creatacard-music"]`)?.forEach((musicInput, index, collection) => {
          musicInput.closest(".field")?.classList.remove("--inactive");
          musicInput.addEventListener("change", () => {
            if (musicInput.checked) {
              this.triggerUnmute();
              collection.forEach(input => {
                input.checked = true;
                input.setAttribute("checked", "");
              })
            } else {
              this.triggerMute();
              collection.forEach(input => {
                input.checked = false;
                input.removeAttribute("checked");
              })
            }
          }, {signal: this.listeners.signal})
        })
      }, 150);
    })
    this.loader.load(this.getOptions());
  }

  handleEnvelope() {
    if (!this.config.flags.includes("envelope")) return

    const imageEl = this.el.querySelector(".creatacard-envelope");
    if (!imageEl || (imageEl.src && !imageEl.src.includes("data:"))) return

    let previewImage = this.manifest?.envelope?.previewImages[0];
    if (!previewImage) {
      previewImage = `${app_env.imghost}/product/siteassets/general/3550777/image.png`;
    } else {
      previewImage = `${this.assetsPath}${previewImage}`;
    }
    imageEl.addEventListener("load", (e) => {
      imageEl.closest(".creatacard__envelope")?.classList.add("--loaded");
    });
    imageEl.src = previewImage;
  }

  getOptions() {
    const manifest = {...this.manifest};
    const previewImages = manifest.previewImages ?? manifest.card?.previewImages;
    const images = previewImages?.map((image) => `${this.assetsPath}${image}`);
 
    this.options = {
      template: this.config.template,
      images,
      apiUrl: this.env?.apihost || "",
      clientID: this.env?.client_id || "",
      contentID: this.product.product_number,
      isDetailsPage: true,
      music: {
        enabled: this.config.flags.includes("music"),
      },
    };

    const features = manifest.features ? {...manifest.features} : {}
    if (features) {
      if (features.animation && !features.animation?.url?.includes(this.assetsPath)) {
        features.animation.url =
          this.assetsPath + features.animation.url;
      }
      features.music?.tracks?.forEach((track, index) => {
        features.music.tracks[index].filename = track.url;
      })
      features.embellishment?.assets?.forEach((asset, index) => {
        if (asset.name) {
          features.embellishment.assets[index].url = `${this.assetsPath}${asset.name}`;
        }
      })
      this.options["features"] = features;
    }

    return this.options
  }

  triggerMute() {
    let btn = this.el.querySelector("#play-pause-button.sound-on");
    if (!btn) return
    btn.click();
    Utility.updateContent({
      "creatacard-music-control": {
        removeClass: "--on",
        "aria-label": "Unmute Card",
        "data-product-action": "unmute"
      },
      "music-control-label": "Sound Off",
      'sound-control-btn': {
        "data-product-action": "unmute",
      }
    })
  };

  triggerUnmute() {
    let btn = this.el.querySelector("#play-pause-button.sound-off");
    if (!btn) return
    btn.click();
    Utility.updateContent({
      "creatacard-music-control": {
        addClass: "--on",
        "aria-label": "Mute Card",
        "data-product-action": "mute"
      },
      "music-control-label": "Sound On",
      'sound-control-btn': {
        "data-product-action": "mute",
      }
    })
  };

  destroy() {
    super.destroy();
    this.el.querySelector("#play-pause-button.sound-on")?.click();
    this.el.querySelector(".creatacard__container")?.classList.remove("--loaded");
    this.pauseMusicSelection();
    window.app_listeners.remove("click", "cac-music-click")
    setTimeout(() => {
      this.placer.innerHTML = "";
    }, 100);
  }

  playPauseMusicSelection(src, holder) {
    let player = this.musicSelection.player;
    let controlIcon = holder.querySelector(".css-icon.--play-pause");
    let statusEl = holder.querySelector(".option__status");
    let isCurrent = Boolean(this.musicSelection.src && this.musicSelection.src === src);
    this.musicSelection.holder?.classList.remove("--selected");
    holder.classList.add("--selected");
    if (isCurrent) {
      if (this.musicSelection.player.paused) {
        this.musicSelection.player.play();
        controlIcon.classList.add("--playing");
        this.musicSelection.statusEl.textContent = "playing";

      } else {
        this.musicSelection.player.pause();
        controlIcon.classList.remove("--playing");
        this.musicSelection.holder.classList.remove("--selected");
        this.musicSelection.statusEl.textContent = "paused";
      }
    } else {
      this.pauseMusicSelection();
      player = new Audio(src);
      player.play();
      controlIcon.classList.add("--playing");
      statusEl.textContent = "playing";
    }
    player.addEventListener("ended", this.pauseMusicSelection.bind(this));
    this.musicSelection = {holder, controlIcon, player, src, statusEl};
  }

  pauseMusicSelection() {
    if (!this.musicSelection.player) return
    this.musicSelection.player.currentTime = 0;
    this.musicSelection.player.pause();
    this.musicSelection.controlIcon.classList.remove("--playing");
    this.musicSelection.holder.classList.remove("--selected");
    this.musicSelection.statusEl.textContent = "paused";
  }



  initMusicPlay() {
    const musicItems = document.querySelectorAll(".music-selection-item");
    if (!musicItems) return;
    window.app_listeners.add("click", "cac-music-click", (e) => {
      let target = e.real_target || e.target;
      if (!target.classList.contains("music-selection-item")) return
      let holder = target.closest(".option");
      target.checked = false;
      !app_page.wasKeyboardEvent && this.playPauseMusicSelection(target.getAttribute("data-music-link"), holder);
    })
    musicItems.forEach(musicItem => {
      let holder = musicItem.closest(".option");
      musicItem.addEventListener("keydown", (e) => {
        if (["Enter", "Space"].includes(e.code)) {
          this.playPauseMusicSelection(musicItem.getAttribute("data-music-link"), holder);
        }
      }, {signal: this.listeners.signal})
      musicItem.addEventListener("focus", (e) => {
        const grid = holder.closest("ul");
        const offset = getComputedStyle(grid)?.getPropertyValue('padding-left');
        holder.closest("ul")?.scrollTo({
          left: e.target.closest("li").offsetLeft - Number(offset.replace("px", "")),
          behavior: "smooth"
        })
      }, {signal: this.listeners.signal})
    });
  }
};

export default CreatacardProduct;