import Utility from "../../../base/Utility";
import Preview from "./Preview";

class EcardPreview extends Preview {
  constructor(el, product, pers) {
    super(el, product, pers);
    this.hasVideo = true;
    this.type = 'ecard';
  }

  afterInit() {
    this.loadProduct();
    this.el.classList.add('--waits-visible');
    this.isReady = true;
  }

  afterLoad() {
    Utility.getVideoDimensions(
      this.container,
      this.productInterface?.baseResolution,
      this.setProductDimensions.bind(this)
      ).then(dimensions => {
      this.setProductDimensions(dimensions)
      this.ready();
    })
  }

  afterReady() {
    Utility.elementRendered('.vjs-big-play-button', this.el).then(el => {
      this.videoEl = this.container.querySelector("video");
      if (window.videojs && this.videoEl) {
        this.player = videojs(this.videoEl);
      }
      this.player?.on('play', () => {
        this.player.addClass('vjs-has-started');
      })
      this.player?.on("fullscreenchange", () => {
        if (this.dialog) {
          setTimeout(() => {
            this.dialog.isBlocking = this.player.isFullscreen();
          }, 100)
        }
      })
    })
  }

  pauseMedia() {
    this.videoEl = this.videoEl || this.container.querySelector("video");
    if (!this.player && window.videojs && this.videoEl) {
      this.player = videojs(this.videoEl);
    }
    if (!this.player?.paused()) {
      this.player?.pause();
    }
  }

  resetMedia() {
    this.videoEl = this.videoEl || this.container.querySelector("video");
    if (!this.player && window.videojs && this.videoEl) {
      this.player = videojs(this.videoEl);
    }
    this.player?.currentTime(0);
    this.player?.removeClass('vjs-has-started');
  }

}

export default EcardPreview;