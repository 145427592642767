class AppleProvider {
  constructor(config, manager) {
    this.manager = manager;
    this.config = config;
    this.slug = "apple";
    this.label = "Apple";
    this.enabled = false;
    this.provider = null;
    this.init();
  }

  init() {
    if (this.enabled) return
    let check = document.querySelector(`.sso-auth__trigger[value="${this.slug}"]`)
    if (!check || !window.AppleID || !this.config) return
    this.provider = AppleID.auth.init({
      clientId : this.config.web_service_id,
      scope : 'name email',
      redirectURI : window.app_env?.shost || window.location.href,
      usePopup : true
    });
    document.addEventListener('AppleIDSignInOnSuccess', this.handleAuth.bind(this));
    document.addEventListener('AppleIDSignInOnFailure', (event) => {
      let eventError = event.detail.error;
      if(eventError === "popup_closed_by_user" || eventError ==="user_trigger_new_signin_flow") return
      this.manager.handleAuthFail();
    });
    this.enabled = true;
  }

  handleAuth(response) {
    let token = response?.detail?.authorization?.id_token;
    let userInfo = JSON.stringify(response?.detail?.user) || "";
    if (!token) {
      this.manager.handleAuthFail();
    } else {
      this.manager.handleAuthSuccess(this.label, token, userInfo);
    }
  }

  start(e) {
    !this.enabled && this.init();
    if (!this.enabled) return console.log("not initialized");
    AppleID.auth.signIn();
  }

}

export default AppleProvider;