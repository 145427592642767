class Global {
  static breakpoints = {
    xl: 1650,
    lg: 1370,
    md: 1170,
    sm: 990,
    xs: 770,
    xxs: 440
  }
  static mobileBreakpoint = this.breakpoints.sm;
  static focusableIgnoredClassesList = ["tox-tbtn", "tox-collection__item"];
  static richTextEditorCustomFontsSrc = [
    `${window.app_env?.imghost || ""}/ag/assets/fonts/index.css`,
    "https://fonts.googleapis.com/css2?family=Averia+Serif+Libre:ital,wght@0,400;0,700;1,400;1,700&family=Comic+Neue:ital,wght@0,700;1,700&family=IBM+Plex+Serif:ital,wght@0,400;0,600;1,400;1,600&family=Josefin+Sans:ital,wght@0,400;0,700;1,400;1,700&family=Merriweather:ital,wght@0,400;0,700;1,400;1,700&display=swap"
  ];
  static ignoreInertClasses = ["jw-slider-volume", "jw-slider-time"];
  static richTextEditorCustomFontsFormat = `Gordita="Gordita Content",sans-serif;Josefin="Josefin Sans", sans-serif;IBM Plex="IBM Plex Serif", serif;Merriweather="Merriweather", serif;Comic Neue="Comic Neue", cursive;Averia Libre="Averia Serif Libre", cursive;`
  static focusableQueryString =
    'a[href], area[href], input:not([type="hidden"]), select, video, textarea, button, iframe, [tabindex="0"], [contentEditable=true], .focusable-element, .card3D-container[role="application"]';
  static activeFocusableQueryString =
    'a[href]:not([tabindex="-1"]), area[href]:not([tabindex="-1"]), video:not([tabindex="-1"]), input:not([type="hidden"]):not([tabindex="-1"]), select:not([tabindex="-1"]), textarea:not([tabindex="-1"]), button:not([tabindex="-1"]), iframe:not([tabindex="-1"]), [tabindex="0"], [contentEditable=true]:not([tabindex="-1"]), .focusable-element:not([tabindex="-1"]), .card3D-container[role="application"]:not([tabindex="-1"])';
}

export default Global;