class GoogleProvider {
  constructor(config, manager) {
    this.manager = manager;
    this.config = config;
    this.slug = "google";
    this.label = "Google";
    this.enabled = false;
    this.button = null;
    this.provider = null;
    this.init();
  }

  init() {
    if (this.enabled) return
    let check = document.querySelector(`.sso-auth__trigger[value="${this.slug}"]`);
    if (!check || !window.google?.accounts?.id || !this.config) return
    this.provider = google.accounts.id.initialize({
      client_id: this.config.client_id,
      ux_mode: "popup",
      callback: this.handleAuth.bind(this),
      use_fedcm_for_prompt: true,
    });
    this.initButton();
    this.enabled = true;
  }

  initButton() {
    const wrapper = document.createElement("div");
    wrapper.style.display = "none";
    wrapper.classList.add("custom-google-button");
    document.body.appendChild(wrapper);

    window.google.accounts.id.renderButton(wrapper, {
      type: "icon",
      width: "200",
    });

    this.button = wrapper.querySelector("div[role=button]");
  }

  handleAuth(response) {
    let token = response?.credential
    if (!token) {
      this.manager.handleAuthFail();
    } else {
      this.manager.handleAuthSuccess(this.label, token);
    }
  }

  start(e) {
    !this.enabled && this.init();
    if (!this.enabled) return console.log("not initialized");
    this.button.click();
  }

}

export default GoogleProvider;