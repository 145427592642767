import Client from "../../../base/Client";
import Utility from "../../../base/Utility";
import ApplePayment from "../../../utilities/ApplePayment";

class BaseProduct {
  constructor(el, parent, type, env=null) {
    this.el = el;
    this.type = type;
    this.parent = parent;
    this.isSending = false;
    this.loader = null;
    this.productInterface = false;
    this.env = env || window.app_env;
    this.product = this.env?.product || {};
    this.config = this.env?.product_config;
    this.listeners = new AbortController();
    this.form = null;
    this.container = this.el.querySelector('.product-detail__preview') || el;
    this.iframe = this.el.querySelector('.product-detail__iframe');
    this.placer = this.el.querySelector(".product-detail__preview-watcher");
    this.actions = {
      preview: this.triggerPreview.bind(this),
      stop: this.triggerStop.bind(this),
      send: this.triggerSend.bind(this),
      mute: this.triggerMute.bind(this),
      unmute: this.triggerUnmute.bind(this),
      "subscribe-applepay": this.subscribeWithApplePay.bind(this)
    };
    this.init();
  }

  init() {
    const formKey = this.env.page_type == "quickview" ? "product-quickview-form" : "product-detail-form";
    this.form = window.app_forms?.collection.get(formKey) || null;

    this.el.querySelectorAll('.product-detail__module.--verse').forEach(this.initCardVerse.bind(this));
    if (this.type == 'fallback') {
      this.ready();
    }

    this.manageApplepay();
    this.manageSetPassword();
    this.manageQuickview();
    this.manageForm();

    window.app_listeners?.add(
      'click',
      `product-${this.product.product_number}`,
      this.clickHandler.bind(this)
    )
  };

  loadProduct() {
    if (this.iframe) return
    if (!window.agi?.ProductLoader) return console.log("no agi-product-loader library found!");
    if (!this.env?.product_config?.loader_options) return console.log("no loader config!");

    const options = this.env.product_config.loader_options;
    const product_number = this.product.product_number;
    this.loader = new agi.ProductLoader(product_number, options);
    this.loader.load().then(product => {
      this.productInterface = product;
      this.initListeners();
      this.placer?.appendChild(product.domNode);
      return product.onAddedToDom()
    }).then(product => {
      this.afterLoad();
    });
  }

  /**
   * Manages the quickview functionality for the page.
   * If the page is not a quickview or the iframe is not available, the function will return early.
   * Listens for messages from the iframe and handles different types of messages accordingly.
   */
  manageQuickview() {
    if (!this.iframe || this.env.page_type != "quickview") return
    window.addEventListener("message", e => {
      if (!e.data?.name) return
      if (e.data.name == "product-visible") {
        this.setProductDimensions(e.data.dimensions);
        if (this.product.model == "ecard") {
          setTimeout(() => {
            this.ready();
          }, 300);
        } else {
          this.ready();
        }
      } else if (e.data.name == "product-dimensions-update") {
        this.setProductDimensions(e.data.dimensions);
      } else if (e.data.name == "product-non-standard-max-width") {
        this.el.classList.add("--non-standard-width");
        this.el.style.setProperty("--product-custom-width", `${e.data.width}px`)
      } else if (e.data.name == "product-send") {
        this.setFormPersonalizations(e.data.pers);
        this.form?.el.submit();
      } else if (this.product.model == "interactive" && e.data.name == "product-send-ready") {
        this.setFormPersonalizations(e.data.pers);
        this.showSendButton();
      }
    }, {signal: this.listeners.signal})
  }

  manageApplepay() {
    if (Client.supportsApplePay()
    && !this.product.is_reply
    && !this.product.is_free
    && this.env?.is_applepay_pdp_sub) {
    Utility.updateContent({
      "applepay-container": {
        removeClass: "hidden"
      }
    }, this.el)
  }
  }

  manageForm() {
    if (this.form) {
      this.manageReplyToken();
      this.form.filterFormDataGetter = (formData) => {
        const excludedKeys = [
          "favorite_activity",
          "occasion",
          "age",
          "generated_content",
          "message_tries",
          "message_length",
          "voice",
          "zoom",
          "photo",
          "message"
        ];
        if (!formData.has('pn1595') || formData.get('pn1595') == "") {
          formData.set('pn1595', "{}");
        }
        if (formData.get("message_state")) {
          let feature = Utility.getFeatureCode(formData.get("message_state"), "message_state");
          formData.delete("message_state");
          formData.set("features_used", JSON.stringify([feature]));
        }
        excludedKeys.forEach(key => {
          if (formData.has(key)) {
            formData.delete(key);
          }
        });
        if (this.env?.sourcecode) {
          formData.set("sourcecode", this.env.sourcecode);
        }
        return formData
      }
      this.form.onSubmit = () => {

        if (this.env?.is_direct_to_personalize_enabled) {
            return true;
        }

        // GUARD 1: If user is not signed in and tries to initialize a
        // sendflow redirect to join flow or free card join flow instead
        if (!this.env.customer && !this.product.is_reply && !this.product.sendable_by_afu) {
          if (this.env.next_page) {
            window.location.href = this.env.next_page;
          }
          this.form.complete('error');
          return false
        }

        // GUARD 2: If user is signed in as RFU and tries to send
        // a non-free product redirect to join flow instead
        if (this.env?.customer && ["RFU","REG"].includes(this.env.customer.status) && !this.product.is_free && !this.env.pay_per_send) {
          if (this.env.next_page) {
            window.location.href = this.env.next_page;
          }
          this.form.complete('error');
          return false
        }

        // GUARD 3: If user in signed in and is in collections show the
        // in collections warning modal instead
        if (this.env?.customer && this.env?.customer.is_in_collections && !this.product.is_free) {
          window.app_dialogs?.open('collections-dialog', this.form.submitBtns[0]);
          this.form.complete('error');
          return false
        }

        // GUARDS PASSED
        return true
      }

      this.form.onComplete = () => {
        if (this.form.status != "success") {
          this.isSending = false;
        }
      }
    }
  }

  manageReplyToken() {
    let pickupToken = window.app_storage?.get("ag-reply-token", "session");
    if (this.product.is_reply && pickupToken && this.form) {
      this.form.extraRequestParams["reply_token"] = pickupToken;
    }
  }

  manageSetPassword() {
    let url = new URL(window.location.href);
    if (url.searchParams.has("set_password")) {
      if (!window.app_forms?.collection.get("applepay-password")) {
        url.searchParams.delete("set_password");
        window.history.replaceState(window.app_storage?.get("state") || {}, 'unused', url.href);
      }
      setTimeout(() => {
        window.app_forms?.collection.get("applepay-password")?.fields.get("new_password")?.el.focus();
      }, 1000)
    }

    let form = window.app_forms?.collection.get("applepay-password");
    if (!form) return
    form.successTimer = 2000;
    form.onAfterSuccess = () => {
      setTimeout(() => {
        this.showPasswordForm(false);
        url.searchParams.delete("set_password");
        window.history.replaceState(window.app_storage?.get("state") || {}, 'unused', url.href);
      }, 1000)
      return false;
    }
  }

  clickHandler(e) {
    let target = e.real_target || e.target;
    let key = target?.getAttribute("data-product-action") || null;
    if (key && this.actions.hasOwnProperty(key)) {
      this.actions[key](e);
    }
  }

  async subscribeWithApplePay(e) {
    let target = e.real_target || e.target;
    // apple logic
    if (!Client.supportsApplePay()) return
    this.form?.clearErrors(["server"]);
    target.classList.add("--loading");
    this.applePayment = new ApplePayment(this.form, "detail", this.env);
    this.applePayment.onError = (errors) => {
      this.form?.addError("server", errors);
      this.form?.renderErrors();
      this.form?.parent.focusFirstInvalidField(this.form);
    };
    this.applePayment.onCancel = () => {
      target.classList.remove("--loading");
    };
    this.applePayment.submitApplePayDataForm();

    let form = window.app_forms.collection.get("applepay-password");
    if (!form) return
    this.showPasswordForm();
  }

  showPasswordForm(show = true) {
    Utility.updateContent({
      'applepay-password': {
        removeClass: show ? "hidden" : "",
        addClass: show ? "" : "hidden",
      },
      "applepay-container": {
        addClass: show ? "hidden" : "",
        removeClass: show ? "" : "hidden",
      },
      "pdp-form": {
        addClass: show ? "hidden" : "",
        removeClass: show ? "" : "hidden",
      },
      "sticky-bar": {
        addClass: show ? "hidden" : "",
        removeClass: show ? "" : "hidden",
      }
    })
  }

  setFormInactive(isInactive = true) {
    if (this.form) {
      this.form.isInactive = isInactive;
      this.form.fields.forEach(field => {
        field.setInactive(isInactive);
      })
    }
    Utility.updateContent({
      'cta-holder': {
        removeClass: isInactive ? '' : '--inactive',
        addClass: isInactive ? '--inactive' : '',
      },
      'sticky-bar': {
        removeClass: isInactive ? '' : '--inactive',
        addClass: isInactive ? '--inactive' : '',
      }
    })
  }

  initCardVerse(el) {
    const trigger = el?.querySelector('button');
    if (!trigger) return;
    const showFullVerse = () => {
      const show = !el.classList.contains('--active');
      trigger.setAttribute('aria-expanded', show);
      el.querySelector('.full-verse__container')?.setAttribute('aria-hidden', !show);
      el.querySelector('.short-verse__container')?.setAttribute('aria-hidden', show);
      show && el.classList.add('--active');
      !show && el.classList.remove('--active');
      trigger.textContent = show ? "Hide Full Verse" : "View Full Verse";
    }
    trigger.addEventListener('click', showFullVerse, {signal: this.listeners.signal})
  }

  destroy() {
    this.listeners.abort();
    window.app_listeners?.remove('click', `product-${this.product.product_number}`);
  };

  getPersonalizations(e = null) {
    if (this.iframe?.contentWindow?.app_quickview) {
      return this.iframe.contentWindow.app_quickview.getPersonalizations(e);
    }
    return e?.personalizations || window.agi?.expressions?.GeneralManager?.pers || this.productInterface?.getPersonalizations();
  }

  setFormPersonalizations(pers) {
    this.form?.fill({
      'pn1595': encodeURIComponent(JSON.stringify(pers))
    }, false);
  }

  showSendButton() {
    this.form?.el.classList.remove('--disabled');
    Utility.updateContent({
      'send-submit': {
        removeClass: 'hidden',
      },
      'sticky-bar': {
        removeClass: 'hidden',
      },
      'cta-holder': {
        removeClass: 'hidden',
      }
    }, this.el)
  }

  ready() {
    this.el.classList.add('--ready');
    this.onReady();

    setTimeout(() => {
      this.el.classList.add('--after-ready');
      this.afterReady();
    }, 300);
  };

  setProductDimensions(dimensions) {
    if (!dimensions?.length || dimensions.length != 2) return
    if (typeof dimensions[0] != "number" || typeof dimensions[1] != "number") return
    this.el.style.setProperty("--product-aspect-ratio", `${dimensions[0]}/${dimensions[1]}`)
    this.el.style.setProperty("--product-reverse-ratio", `${dimensions[1] / dimensions[0]}`)
  }


  // forwarded
  onReady() { };
  triggerPreview() { };
  initListeners() { };
  afterLoad() { };
  afterReady() { };
  triggerStop() { };
  triggerSend() { };
  triggerMute() { };
  triggerUnmute() { };
}

export default BaseProduct;
