class ConstructorIO {
  constructor(services) {
    this.config = window.app_env?.constructor_config;
    this.cio_client_id = null;
    this.cio_session_id = null;
    this.services = services;
    this.init();
  }

  track(event, content) {
    if (event == "purchase") {
      window.cnstrcPurchaseData = content;
    }
  }

  buildAutocomplete(term) {
    if (!term || !this.config) return ""
    if (!this.cio_client_id) {
      this.cio_client_id = window.app_storage?.getCookie("ConstructorioID_client_id");
    }
    if (!this.cio_session_id) {
      this.cio_session_id = window.app_storage?.getCookie("ConstructorioID_session_id");
    }
    const searchParams = new URLSearchParams();
    searchParams.set("query", term);
    searchParams.set("key", this.config.key);
    searchParams.set("num_results_Search Suggestions", 6);
    searchParams.set("_dt", Date.now());
    searchParams.set("c", "cio-fe-web-american-greetings");
    if (this.config.content_learning_enabled) {
      searchParams.set("origin_referrer", window.location.origin + window.location.pathname);
      this.cio_client_id && searchParams.set("i", this.cio_client_id);
      this.cio_session_id && searchParams.set("s", this.cio_session_id);
      if (window.app_env?.customer?.number) {
        searchParams.set("ui", app_env.customer.number);
      }
    }
    let url = `${this.config.host}/autocomplete/${term}?${searchParams.toString()}`;
    let options = {}
    if (this.config?.forwarded_for) {
      options = {
        headers: {
          "X-Forwarded-For": this.config.forwarded_for
        }
      }
    }
    return {url, options}
  }

  init() {
    if (!this.config) return
    if (["payment", "pof"].includes(window.app_env?.page_type)) return

    if (window.app_env?.customer?.number) {
      window.cnstrcUserId = String(window.app_env.customer.number);
    }
  }
}

export default ConstructorIO;