import Preview from "./Preview";

/**
 * Represents a preview of a Creatacard product.
 * Extends the Preview class.
 */
class CreatacardPreview extends Preview {
  constructor(el, product, pers) {
    super(el, product, pers);
  }

  afterInit() {
    this.initCreatacard();
  }

  getGiftOptions() {
    if (!this.order.gift || !window.app_env?.reply_token) return {}
    return {
      giftcard: {
        image: this.order.gift.image_url,
        title: this.order.gift.title,
        price: this.order.gift.price,
        redeemUrl: this.order.gift.claim_link,
        redeemText: "Redeem Gift",
      }
    }
  }

  getOptions(pers = {}) {
    const options = {
      isPickup: true,
      host: this.config.card3d_options_host,
      ...this.getGiftOptions(),
      ...pers,
    }

    if (options.video && options.video.playbackUrl) {
      options.video.enabled = this.config.flags.includes("video");
    }

    if (options.envelope && this.order.recipients[0]?.name) {
      options.envelope.toBin.text = `To: ${this.order.recipients[0]?.name}`;
      options.envelope.fromBin.text = `From: ${this.order.sender?.name}`;
    }

    return options
  }

  async initCreatacard() {
    if (!this.config) return;
    let personalizations = await this.loadPersonalizations();
    if (!personalizations) return console.log("No personalizations");
    if (personalizations.envelope) {
      document.querySelector(".product-pickup")?.classList.remove("--no-envelope");
    }
    const loader = new agi.CardLoader(this.container);
    loader.load(this.getOptions(personalizations));
    loader.addEventListener('cardReady', () => {
      this.el.querySelector('#cac-card3d-loader')?.classList.add("hidden")
      this.el.querySelector('#giftcard-redeem')?.classList.add("btn", "--small", "--narrow");
    })
  }

}

export default CreatacardPreview;