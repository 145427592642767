import Utility from "../base/Utility";

/**
 * Represents a LobsterStorage class that handles storage operations.
 */
class LobsterStorage {
  constructor() {
    this.endpoint = window.app_env?.services?.storage_service_url;
    this.lib_url = window.app_env?.services?.storage_lib;
    this.enabled = false;
    this.init();
  }

  init() {
    if (this.endpoint && (this.lib_url || window.agi?.LobsterStorage)) {
      this.enabled = true
    }
  }

  /**
   * Checks if the 'lobster-storage' library has been loaded successfully.
   * @returns {Promise<boolean>} A promise that resolves to true if the library is loaded, false otherwise.
   */
  async libraryLoaded() {
    return Utility.resourcesLoaded(
      "lobster-storage",
      [this.lib_url],
      true,
      () => (window.agi?.LobsterStorage)
    )
  }

  /**
   * Generates credentials by fetching data from the server based on the provided lobster_id.
   * If lobster_id is provided, it fetches credentials from `/lobster-load/${lobster_id}` for read operation,
   * otherwise it fetches credentials from `/lobster-save` for write/read operations.
   * @param {string} [lobster_id=null] - The lobster ID to fetch credentials for.
   * @returns {object | null} A promise that resolves to an object containing
   * lobster_jwt and lobster_id, or null if an error occurs.
   */
  async generateCredentials(lobster_id = null) {
    let endpoint = lobster_id ? `/lobster-load/${lobster_id}` : "/lobster-save";
    try {
      const response = await fetch(app_env.shost + endpoint);
      const credentials = await response.json();
      if (!credentials || !credentials?.lobster_jwt || !credentials?.lobster_id) {
        throw new Error("no credentials")
      }
      return {
        lobster_jwt: credentials?.lobster_jwt,
        lobster_id: credentials?.lobster_id,
      }
    } catch (error) {
      return null
    }
  }

  /**
   * Saves customizations for a product using Lobster storage.
   * @param {string} product_number - The product number to save customizations for.
   * @param {object} customizations - The customizations to save for the product.
   * @returns {string | null} The Lobster ID if successful, null otherwise.
   */
  async save(product_number, customizations) {
    if (!this.enabled) return console.log("Lobster not implemented");
    await this.libraryLoaded();
    if (!product_number || !customizations) return null
    let credentials = await this.generateCredentials();
    if (!credentials) return null
    try {
      const lobster = new agi.LobsterStorage(
        this.endpoint,
        credentials.lobster_jwt,
        credentials.lobster_id,
        product_number
      );
      await lobster.save(customizations);
      return credentials.lobster_id;
    } catch (error) {
      console.log(error);
      return null;
    }
  }


  /**
   * Asynchronously loads a product using the provided product number and lobster ID.
   * @param {string} product_number - The product number to load.
   * @param {string} lobster_id - The lobster ID associated with the customizations.
   * @returns {object | null} A Promise that resolves to the customizations of the product, or null if an error occurs.
   */
  async load(product_number, lobster_id) {
    if (!this.enabled) return console.log("Lobster not implemented");
    await this.libraryLoaded();
    if (!product_number || !lobster_id) return null
    const credentials = await this.generateCredentials(lobster_id);
    if (!credentials) return null
    try {
      const lobster = new agi.LobsterStorage(
        this.endpoint,
        credentials.lobster_jwt,
        lobster_id,
        product_number
      );
      let response = await lobster.load();
      return response?.customizations;
    } catch (error) {
      return null;
    }
  }

}

export default LobsterStorage;