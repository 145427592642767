import SmashupPreview from "../product/preview/SmashupPreview";
import EcardPreview from "../product/preview/EcardPreview";
import InteractivePreview from "../product/preview/InteractivePreview";
import Preview from "../product/preview/Preview";
import SelfieSmashupPreview from "../product/preview/SelfieSmashupPreview";
import TalkingSmashupPreview from "../product/preview/TalkingSmashupPreview";
import PicsnwishesPreview from "./preview/PicsnwishesPreview";
import Utility from "../../base/Utility";
import Global from "../../base/Global";
import CreatacardPreview from "./preview/CreatacardPreview";

class Pickup {
  constructor(el, parent) {
    this.el = el;
    this.parent = parent;
    this.order = { ...window.app_env?.ecard_order } || null;
    this.preview = null;
    this.replyToken = window.app_env?.reply_token || null;
    this.controllerType = this.order.product.model || "fallback";
    this.previewControllerMap = {
      smashup: SmashupPreview,
      ecard: EcardPreview,
      interactive: InteractivePreview,
      'talking-smashup': TalkingSmashupPreview,
      'selfie-smashup': SelfieSmashupPreview,
      creatacard: CreatacardPreview,
      picsnwishes: PicsnwishesPreview,
      fallback: Preview,
    }
    this.init();
  }

  init() {
    Utility.loadResources("rich-text-fonts", Global.richTextEditorCustomFontsSrc, () => {}, false);
    this.initPreview();
    if (this.replyToken) {
      window.app_listeners?.add("click", "handle-pickup-reply", this.handleReplyClick.bind(this));
    }
  }

  handleReplyClick(e) {
    let target = e.real_target || e.target;

    if (target && target.hasAttribute("data-is-reply")) {
      window.app_storage?.set("ag-reply-token", this.replyToken, "session");
    }
  }

  initPreview() {
    let previewEl = document.querySelector('.product-preview');
    if (previewEl) {
      if (this.previewControllerMap.hasOwnProperty(this.controllerType)) {
        this.preview = new this.previewControllerMap[this.controllerType](previewEl, this.order.product, this.order.personalizations);
      } else {
        this.preview = new this.previewControllerMap.fallback(previewEl, this.order.product, this.order.personalizations);
      }
      this.preview.playMedia();
    }
  }
}

export default Pickup;