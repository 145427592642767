import Client from "../base/Client";
import Utility from "../base/Utility";

class DynamicHeight {
  constructor() {
    this.init();
  }

  init() {
    document.querySelectorAll('.dynamic-height')?.forEach(this.initDynamicHeight.bind(this));
  }

  initDynamicHeight(el) {
    Utility.addResizeObserver(el, null, '--dh-height');
  }

  static start(el) {
    if (Client.prefersReducedMotion()) return
    el.classList.add('dynamic-height-active');
    const elHeight = el.getBoundingClientRect().height;
    el.style.setProperty('--dh-height', elHeight + "px");
    el.classList.add('--transitioning');
  }

  static end(el) {
    if (!el.classList.contains('--transitioning')) return 0

    const target = el.firstElementChild;
    const elHeight = el.getBoundingClientRect().height;
    const targetHeight = target.getBoundingClientRect().height;
    const difference = Math.abs(elHeight - targetHeight);
    if (elHeight == targetHeight) {
      el.style.setProperty('--dh-height', '');
      el.classList.remove('--transitioning');
      return 0
    }
    const duration = Math.min(Math.max(.3, difference / 3500), 3)
    el.style.transitionDuration = duration + 's';
    setTimeout(() => {
      el.style.setProperty('--dh-height', targetHeight + "px");
      setTimeout(() => {
        el.style.setProperty('--dh-height', '');
        el.classList.remove('--transitioning');
      }, duration * 1000)
    }, 0)
    return duration;
  }
}

export default DynamicHeight;