class Toggle {

    constructor() {
        this.init();
    }

    init() {
        const els = document.querySelectorAll('.read-more');
        if (!els || els.length == 0) return;
        els.forEach(this.initToggle);
    }

    initToggle(el) {
        const trigger = el.querySelector('button');
        const container = el.querySelector('.read-more__container');
        if (!trigger || !container) return;
        trigger.addEventListener('click',() => {
            if (el.classList.contains('--active')) {
                trigger.setAttribute('aria-expanded',false);
                container.setAttribute('aria-hidden',true);
                el.classList.remove('--active');
                trigger.textContent = "Read More";
            } else {
                trigger.setAttribute('aria-expanded',true);
                container.setAttribute('aria-hidden',false);
                el.classList.add('--active');
                trigger.textContent = "Read Less";
            }
            el.parentElement.focus();
        })
    }
}

export default Toggle;