import Utility from "../../base/Utility";
import Addressbook from "./Addressbook";

class Addressbooks {
  constructor() {
    this.collection = new Map();
    this.actions = new Map();
    this.init();
  }

  init() {
    const els = document.querySelectorAll('.addressbook');
    if (!els || els.length == 0) return
    els.forEach(this.add.bind(this));
  }

  add(el, reinit = false) {
    if (el.id == '') {
      el.id = `addressbook-instance`;
    }
    Utility.fixDuplicates(el);
    if (this.collection.has(el.id)) {
      if (reinit) {
        this.collection.get(el.id).destroy()
      } else {
        return
      }
    }
    this.collection.set(el.id, new Addressbook(el, this));
  }
}

export default Addressbooks;