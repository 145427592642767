import BrowseFilter from "./BrowseFilter";
import MailboxFilter from "./MailboxFilter";
import Filter from "./Filter";
import SearchFilter from "./SearchFilter";

class Filters {

  constructor() {
    this.collection = new Map();
    this.init();
  }

  init(root = document) {
    root?.querySelectorAll('.filters')?.forEach(this.initFilter.bind(this));
  }

  initFilter(el) {
    if (el.hasAttribute('data-filter-type')){
      switch (el.getAttribute('data-filter-type')){
        case 'browse' : this.collection.set(el.id, new BrowseFilter(el)); break;
        case 'search' : this.collection.set(el.id, new SearchFilter(el)); break;
        case 'mailbox': this.collection.set(el.id, new MailboxFilter(el)); break;
        default : this.collection.set(el.id, new Filter(el)); break;
      }
    } else {
      this.collection.set(el.id, new Filter(el));
    }
  }

  destroyFilter(id) {
    if (!this.collection.has(id)) return
    this.collection.get(id).destroy();
    this.collection.delete(id)
  }

}

export default Filters;