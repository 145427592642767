import TalkingSmashupProduct from "./TalkingSmashupProduct";

class SelfieSmashupProduct extends TalkingSmashupProduct {
  constructor(el, parent, type, env=null) {
    super(el, parent, type, env);
  }

  manageSelfieInteractions() {
    const photoField = this.form?.fields.get("photo");
    const zoomField = this.form?.fields.get("zoom");
    photoField?.el.addEventListener("file-loaded", () => {
      this.productInterface.uploadImage(photoField.el.files[0]);
    }, {signal: this.listeners.signal});
    zoomField?.el.addEventListener("input", () => {
      this.productInterface.updateImageScale(zoomField.el.value);
    }, {signal: this.listeners.signal});
  }

  async initSend(trigger) {
    let voice = this.form.fields.get("voice")?.getValue();
    let message = this.form.fields.get("message")?.getValue();
    this.productInterface.updatePersonalizations({ message, voice });
    const personalizations = this.getPersonalizations();
    // TODO: Save image to aws and remove Lobster Logic
    const LobsterId = await window.app_services?.lobster.save(
      this.product.product_number,
      personalizations
    );
    if (!LobsterId) {
      trigger?.classList.remove("--loading");
      this.form.addError(
        "server",
        "Unable to save personalizations, please try again later."
      );
      this.form.complete("error-server");
      return
    }
    this.setFormPersonalizations({ LobsterId });
    this.form.el.submit();
  };
}

export default SelfieSmashupProduct;