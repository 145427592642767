import Global from "./Global";

class Client {
  static width = this.getWidth();
  static height = this.getHeight();
  static scrollbar = this.getScrollbarWidth();

  static getWidth() {
    return (this.width =
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.getElementsByTagName("body")[0].clientWidth);
  }

  static allowScrollCallable() {
    if (window._scroll) {
      window.scroll = window._scroll
    }
  }

  static blockScrollCallable() {
    window._scroll = window.scroll
    window.scroll = function() {}
  }

  static prefersReducedMotion() {
    return window.matchMedia('(prefers-reduced-motion)').matches
  }

  static supportsApplePay() {
    // TODO add proper detection here based on user agent capabilities and AG Flag for applepay
    return Boolean(window.ApplePaySession)
  }

  static supportsWebP() {
    if (window.app_page?.hasOwnProperty('webpSupport')) {
      return window.app_page.webpSupport;
    } else {
      let result = false;
      var elem = document.createElement('canvas');
      if (!!(elem.getContext && elem.getContext('2d'))) {
        result = elem.toDataURL('image/webp').indexOf('data:image/webp') == 0;
      }
      if (window.app_page) {
        app_page.webpSupport = result;
      }
      return result;
    }
  }

  static supportsDynamicImport() {
    try {
      new Function('import("")');
      return true;
    } catch (err) {
      return false;
    }
  }

  static inViewport(el, offset = 0) {
    if (!el) return false;
    const rect = el.getBoundingClientRect();
    return (
      rect.top - offset >= 0 &&
      rect.left >= 0 &&
      rect.bottom <=
      (window.innerHeight || document.documentElement.clientHeight) &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
  }

  static log(...string) {
    let logger = document.querySelector('.client-logger');
    if (!logger) {
      let loggerEl = document.createElement('div');
      loggerEl.classList.add('client-logger');
      document.body.appendChild(loggerEl);
      logger = document.querySelector('.client-logger');
    }
    let log = document.createElement('pre');
    log.innerHTML = [...string].join('<strong>,</strong> ');
    if (logger.children.length > 2) {
      logger.children[0].remove();
    }
    logger.appendChild(log);
  }

  static showSoftKeyboard(el) {
    if (!this.isAppleHandheldDevice()) return
    if (!el) return;
    // Align temp input element approximately where the input element is
    // so the cursor doesn't jump around
    const tempEl = document.createElement("input");
    const rect = el.getBoundingClientRect();
    tempEl.classList.add("proxy-trigger-keyboard");
    tempEl.style.position = "fixed";
    tempEl.style.top = rect.top + "px";
    tempEl.style.left = rect.left - 40 + "px";
    tempEl.style.height = rect.height + "px";
    tempEl.style.width = rect.width + "px";
    tempEl.style.paddingLeft = 16 + "px";
    tempEl.style.opacity = 0;
    document.body.appendChild(tempEl);
    const tempElRendered = document.querySelector(".proxy-trigger-keyboard");
    if (tempElRendered) {
      tempElRendered.click();
      tempElRendered.focus();
    }
    // The keyboard is open. Now do a delayed focus on the target element
    setTimeout(function () {
      document.body.removeChild(tempElRendered);
    }, 100);
  }

  static getHeight() {
    return (this.height =
      window.innerHeight ||
      document.documentElement.clientHeight ||
      document.getElementsByTagName("body")[0].clientHeight);
  }

  static isAppleHandheldDevice() {
    return (
      /iPad|iPhone|iPod/.test(window.navigator.userAgent) ||
      (window.navigator.platform === "MacIntel" &&
        window.navigator.maxTouchPoints > 1)
    );
  }

  static isHandheldDevice() {
    return (navigator.userAgent.match(/Android/i)
      || navigator.userAgent.match(/webOS/i)
      || navigator.userAgent.match(/BlackBerry/i)
      || navigator.userAgent.match(/Windows Phone/i)
      || this.isAppleHandheldDevice());
  }

  static queryMedia(string, callback = null, trigger = false) {
    let watcher = string ? window.matchMedia(string) : null;
    if (typeof callback == 'function' && watcher) {
      watcher.onchange = callback;
      trigger && callback(watcher)
    }
    return watcher?.matches;
  }

  static isMobile() {
    return this.width <= Global.mobileBreakpoint;
  }

  static getScrollbarWidth() {
    return window.innerWidth - document.documentElement.clientWidth;
  }

  static isKeyboardEvent(e) {
    if (!e) return false
    if (e instanceof MouseEvent && e.detail === 1) return false
    if (e instanceof KeyboardEvent || e.detail === 0) return true
    if (e instanceof PointerEvent && (e.pointerId === -1 || e.buttons === 1 || e.pointerType == "touch" || e.detail !== 0)) return false
    return true
  }

  static getConsent() {
    try {
      let consentCookie = window.app_storage?.getCookie("CONSENTMGR");
      if (!consentCookie) return false;
      let decoded = decodeURIComponent(consentCookie)
      if (decoded.includes("acceptPrompt:true")) {
        return true
      }
      return false
    } catch (e) {
      return false
    }
  }

  static scrollTo(
    el = null,
    offset = 0,
    behavior = "smooth",
    onlyIfNotInViewport = false,
    holderEl = document.documentElement
  ) {
    holderEl = holderEl || document.documentElement;
    if (onlyIfNotInViewport && el && this.inViewport(el, offset)) return;
    const top = el ? el.getBoundingClientRect().top + holderEl.scrollTop - offset : offset;
    holderEl.style.setProperty("scroll-behavior", "instant");
    holderEl.style.setProperty("scroll-padding-top", "0px");
    setTimeout(() => {
      holderEl.style.setProperty("scroll-behavior", "");
      holderEl.style.setProperty("scroll-padding-top", "");
    }, 50);
    holderEl.scrollTo({ top, behavior });
  }

  static getCSRFToken() {
    return window.app_storage.getCookie('csrftoken');
  }

  static isHidden = (el) => {
    if (!el) return true;
    let hidden = el.clientHeight == 0;
    let insideHidden = el.closest('[aria-hidden="true"]');
    return (hidden  && el.tagName != "A") || insideHidden;
  };

  static isHiddenRect = (el) => {
    if (!el) return true;
    let hidden = el.getBoundingClientRect().height == 0;
    return hidden;
  };

  static isTouch() {
    return window.matchMedia("(pointer: coarse) and (hover: none)").matches;
  }

  static dispatchEvent(key, obj = null, el = document) {
    el.dispatchEvent(
      new CustomEvent(key, {
        cancelable: false,
        detail: obj,
      })
    );
  }

  static dispatchDYevent(content) {
    if(!window.DY?.API || !content) return
    if (typeof content == "string") {
      window.DY.API("event", {name: content});
    } else {
      window.DY.API("event", content);
    }
  }
}

export default Client;
