class Saver {
  constructor(key, type, form=null, exclusive=null) {
    this.key = key;
    this.type = type;
    this.form = form;
    this.exclusive = exclusive;
    this.storage = null;
    this.isLoading = false;
    this.init();
  }

  init() {
    this.exclusive && window.app_storage.clearOthers(this.key, this.type, this.exclusive)
  }

  save(data=null, regex=null) {
    if (this.form && this.form.isInactive) return
    if (this.isLoading) return
    this.storage = data || this.form?.getFormData(true, true);
    let result = window.app_storage?.set(this.key, this.storage, this.type, regex);
  }

  load(exceptions=[]) {
    this.storage = window.app_storage?.get(this.key, this.type);
    if (!this.storage) return false
    let filtered = {};
    for (let key in this.storage) {
      if (!exceptions.includes(key)) {
        filtered[key] = this.storage[key]
      }
    }
    this.isLoading = true;
    this.form?.fill(filtered);
    setTimeout(() => {
      this.isLoading = false;
    }, 0)
    return this.storage
  }

  clear() {
    window.app_storage?.delete(this.key, this.type);
  }
}

export default Saver;