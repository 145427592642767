import Client from "../base/Client";
import Utility from "../base/Utility";
import DynamicHeight from "../utilities/DynamicHeight";

class Tabs {
  constructor() {
    this.collection = new Map();
    this.init();
  }

  init(root = document) {
    const els = root.querySelectorAll('.tabs');
    if (!els || els.length == 0) return
    els.forEach(this.add.bind(this));
  }

  add(el, reinit = false) {
    if (el.id == '') {
      el.id = `tab-instance`;
    }
    Utility.fixDuplicates(el);
    if (this.collection.has(el.id)) {
      if (reinit) {
        this.collection.get(el.id).destroy()
      } else {
        return
      }
    }
    this.collection.set(el.id, new TabInstance(el));
  }
}

class TabInstance {
  constructor(el) {
    this.el = el;
    this.activeKey = null;
    this.default = el.hasAttribute('data-default') ? el.getAttribute('data-default') : '';
    this.form = null;
    this.dialog = el.closest(".dialog")?.getInstance("dialogs");
    this.toggler = el.querySelector(".tabs__toggler");
    this.urlTriggered = el.hasAttribute('data-url-trigger');
    this.container = el.querySelector('.tabs__panels');
    this.tabList = el.querySelector(".tabs__tablist");
    this.getTabs = () => (this.el.querySelectorAll('.tabs__tab'));
    this.getPanels = () => (this.el.querySelectorAll('.tabs__panel'));
    this.getTab = (key) => (this.el.querySelector(`.tabs__tab[value="${key}"]`));
    this.getPanel = (key) => (this.el.querySelector(`.tabs__panel[data-name="${key}"]`));
    this.getActiveTab = () => (this.el.querySelector('.tabs__tab[aria-selected="true"]') || null);
    this.getActivePanel = () => (this.el.querySelector('.tabs__panel.--active') || null);
    // hooks
    this.onChange = (key, duration, instance) => { };
    // init
    this.init();
  }

  init() {
    this.blockAnimation = this.dialog || !this.el.hasAttribute('data-animate');
    this.form = this.el.closest('.form')?.getInstance("forms");
    if (this.getTabs().length != 0 && this.getPanels().length != 0) {
      this.getTabs().forEach((tab, i) => {
        const panel = this.getPanels()[i];
        panel.id = `${this.el.id}-${panel.hasAttribute('data-name') ? panel.getAttribute('data-name') : i}`;
        tab.setAttribute('aria-controls', panel.id);
      })
    }
    if (this.getActiveTab()) {
      this.activate(this.getActiveTab().value, true);
    } else if (this.getTabs().length != 0) {
      this.activate(this.getTabs()[0].value, true);
    }
    window.app_listeners?.add('click', `tab-${this.el.id}-click`, this.clickHandler.bind(this));
    if (this.urlTriggered) {
      this.updateUrl(true)
      window.app_listeners?.add('popstate', `popstate-${this.el.id}`, this.stateHandler.bind(this));
      window.app_listeners?.add('pageshow', `pageshow-${this.el.id}`, this.pageshowHandler.bind(this));
    }
    if (this.toggler) {
      let resizeObserver = new ResizeObserver(() => {
        this.el.classList.add("--block-transitions");
        this.triggerToggler();
        setTimeout(() => {
          this.el.classList.remove("--block-transitions");
        }, 100)
      })
      resizeObserver.observe(this.tabList);
      this.dialog?.el.addEventListener("dialog-opened", () => {
        this.el.classList.remove("--toggler-active");
        setTimeout(() => {
          this.triggerToggler();
        }, 400);
      });
      this.dialog?.el.addEventListener("dialog-closed", () => {
        setTimeout(() => {
          this.el.classList.remove("--toggler-active");
        }, 300);
      });
      this.focusScroll();
    }
  }

  triggerToggler() {
    let tab = this.getActiveTab();
    if (tab.hasAttribute('data-triggers')) {
      let ref = this.getTab(tab.getAttribute('data-triggers'));
      ref.classList.add('--active');
      this.handleToggler(ref);
    } else {
      this.handleToggler(tab);
    }
  }

  destroy() {
    window.app_listeners?.remove('click', `tab-${this.el.id}-click`);
    if (this.urlTriggered) {
      window.app_listeners?.remove('popstate', `popstate-${this.el.id}`);
      window.app_listeners?.remove('pageshow', `pageshow-${this.el.id}`);
    }
  }

  stateHandler(e) {
    if ((e instanceof PopStateEvent) && e.state?.hasOwnProperty('tab')) {
      this.activate(e.state.tab, true);
    }
  }

  pageshowHandler(e) {
    this.activate(this.activeKey, true);
  }

  updateUrl(init = false, extraParams = null) {
    if (!this.urlTriggered) return
    let url = window.location.origin + window.location.pathname;
    const searchParams = new URLSearchParams();
    if (this.activeKey && this.activeKey != this.default) {
      searchParams.append('tab', this.activeKey);
    }
    let params = {
      tab: this.activeKey,
    }
    if (extraParams) {
      params = Object.assign(params, extraParams);
      Object.entries(extraParams).forEach(([key, value]) => {
        searchParams.append(key, value);
      })
    }
    const searchString = searchParams.toString();
    if (searchString && searchString != '') {
      url += '?' + searchString
    }

    window.history.replaceState(params, 'unused', init ? window.location.href : url);
  }

  activate(key, init = false, trigger = true, extraParams = null) {
    if (key && key == '') return
    const tab = this.getTab(key);
    const panel = this.getPanel(key);
    const activeTab = this.getActiveTab();
    const activePanel = this.getActivePanel();
    if (!tab || !panel) return
    this.activeKey = key;
    if (activeTab && activeTab == tab) return
    if (activePanel && activePanel == panel) return
    if (!init && !this.blockAnimation) {
      DynamicHeight.start(this.container);
    }
    if (activeTab) {
      activeTab.setAttribute('aria-selected', false);
      activeTab.classList.remove('--active');
    }
    if (activePanel) {
      activePanel.setAttribute('aria-hidden', true);
      activePanel.classList.remove('--active');
      Client.dispatchEvent('tab-deactivated', { instance: this }, activePanel);
    }
    this.form?.removeErrors();
    this.el.querySelector('.tabs__tab.--active')?.classList.remove('--active');
    tab.setAttribute('aria-selected', true);
    tab.classList.add('--active');
    panel.setAttribute('aria-hidden', false);
    panel.classList.add('--active');
    if (tab.hasAttribute('data-triggers')) {
      let ref = this.getTab(tab.getAttribute('data-triggers'));
      ref.classList.add('--active');
      this.handleToggler(ref);
    } else {
      this.handleToggler(tab);
    }
    let duration = 0;
    if (!init && !this.blockAnimation) {
      duration = DynamicHeight.end(this.container);
    }
    Client.dispatchEvent('tab-change', { key, duration, instance: this });
    if (trigger) {
      Client.dispatchEvent('tab-activated', { instance: this }, panel);
    }
    this.onChange(key, duration, this);
    this.el.dispatchEvent(new Event("change"));
    this.updateUrl(init, extraParams)
  }

  handleToggler(tabEl) {
    if (!this.toggler || !tabEl) return
    let parentBox = this.tabList.getBoundingClientRect();
    let elBox = tabEl.getBoundingClientRect();
    this.toggler.style.setProperty("--toggler-width", `${elBox.width}px`);
    this.toggler.style.setProperty("--toggler-height", `${elBox.height}px`);
    this.toggler.style.setProperty("--toggler-left", `${elBox.left - parentBox.left}px`);
    this.toggler.style.setProperty("--toggler-top", `${elBox.top - parentBox.top}px`);
    if (this.el.classList.contains("--toggler-active")) return
    if (this.dialog) {
      if (this.dialog.isActive) {
        setTimeout(() => {
          this.el.classList.add("--toggler-active");
        }, 300);
      }
    } else {
      setTimeout(() => {
        this.el.classList.add("--toggler-active");
      }, 300);
    }
  }

  clickHandler(e) {
    let target = e.real_target || e.target;

    if (!e.composedPath().includes(this.el)) return
    if (target.hasAttribute('role') && target.getAttribute('role') == 'tab') {
      this.activate(target.value);
    }
    if (target.hasAttribute("aria-controls") && target.classList.contains("tabs-trigger")) {
      this.activate(target.getAttribute("aria-controls"));
    }
  }

  focusScroll(){
    const tabs = document.querySelectorAll(".tabs__tab");
    tabs.forEach(tab => {
      tab.addEventListener("focus", (e) => {
        let target = e.real_target || e.target;
        const grid = target.closest("div");
        const offset = getComputedStyle(grid)?.getPropertyValue('padding-left');
        target.closest("div")?.scrollTo({
          left: e.target.offsetLeft - Number(offset.replace("px", "")),
          behavior: "smooth"
        })
      })
    })
  }
}

export default Tabs;