class Accordion {
  constructor() {
    this.init();
  }

  init() {
    window.app_listeners?.add("click", "accordion-click", this.handleClick.bind(this))
  }

  handleClick(e) {
    let target = e.real_target || e.target;

    if (!target.classList.contains("btn-accordion") && !target.classList.contains("accordion__button")) return
    const trigger = target;
    const container = trigger?.closest(".accordion__container");
    const content = container?.querySelector(".accordion__content");
    if (!trigger || !container || !content) return;
    if (container.classList.contains('--active')) {
      trigger.setAttribute('aria-expanded', false);
      content.setAttribute('aria-hidden', true);
      container.classList.remove('--active');
    } else {
      trigger.setAttribute('aria-expanded', true);
      content.setAttribute('aria-hidden', false);
      container.classList.add('--active');
    }
  }

}

export default Accordion;