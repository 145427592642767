class ProductHelpers {
  static manageSmashupButtons(container, canSend, isHidden) {
    let sendBtn = container.querySelector('#gv-send');
    let previewBtn = container.querySelector('#gv-preview');
    sendBtn?.setAttribute("role", "button");
    sendBtn?.setAttribute("aria-label", "Send");
    sendBtn?.setAttribute("href", "javascript:void(0)");
    sendBtn?.setAttribute("data-cnstrc-btn", "add_to_cart");
    previewBtn?.setAttribute("aria-label", "Preview");
    previewBtn?.setAttribute("role", "button");
    previewBtn?.setAttribute("href", "javascript:void(0)");
    if(isHidden) {
      sendBtn?.classList.add("hidden");
    }
    if (!canSend) {
      // sendBtn?.setAttribute("tabindex", "-1");
      // sendBtn?.setAttribute("data-ignore-tab");
      // sendBtn?.classList.add("smashup-unsendable");
      sendBtn?.remove();
    }
  }
}

export default ProductHelpers