class Render {

  static interpolateTemplate(template, map, return_string = false, clean = true){
    if (!template || !map || !(map instanceof Object)) return
    const fragment = template.content.cloneNode(true);
    let string = this.interpolateString(this.stringFromFragment(fragment), map, false, clean);
    return return_string ? string : this.fragmentFromString(string);
  }

  static interpolateString(string, map, format = false, clean = true){
    if (!string || !map || !(map instanceof Object)) return
    string = `${string}`
    for (const key in map) {
      string = string.replace(new RegExp(`{${key}}`, 'g'), map[key]);
    }
    string = string.replace(`"=""`, `"`);
    if (clean) {
      string = string.replace(new RegExp(`{(.*?)}`, 'g'), '');
    }
    if (format){
      string = string.replace( /  +/g, ' ' ).trim();
    }
    return string;
  }

  static html(string) {
    const el = document.createElement('span');
    el.innerHTML = string;
    return el.innerHTML;
  }

  static removeChildren(parent) {
    while (parent.firstChild) {
      parent.firstChild.remove()
    }
  }

  static escape(s) {
    var ret_val = '';
    for (let char of s) {
      const code = char.codePointAt(0);
      if (code > 127) {
        ret_val += '&#' + code + ';';
      } else {
        ret_val += char;
      }
    }
    return ret_val;
  }

  static fragmentFromString(string) {
    const el = document.createElement('template');
    el.innerHTML = string;
    return el.content;
  }

  static stringFromFragment(fragment) {
    const el = document.createElement('div');
    el.appendChild(fragment);
    return el.innerHTML;
  }

  static getTemplateFragment(holder, key) {
    let query = `.${key}-template`;
    let template = holder?.querySelector(query) || document.querySelector(`.global-templates ${query}`);
    return template?.content.cloneNode(true)
  }

  static getTemplateString(holder, key) {
    let fragment = this.getTemplateFragment(holder, key);
    if (!fragment) return ""
    return this.stringFromFragment(fragment)
  }
}

export default Render;