import Accessibility from "./modules/base/Accessibility";
import Header from "./modules/components/header/Header";
import Forms from "./modules/components/form/Forms";
import Dialogs from "./modules/components/Dialogs";
import Page from "./modules/base/Page.js";
import Listeners from "./modules/base/Listeners";
import Images from "./modules/components/Images";
import LoadMore from "./modules/components/LoadMore";
import Sticky from "./modules/utilities/Sticky";
import Filters from "./modules/components/filter/Filters.js";
import Carousels from "./modules/components/Carousels";
import Wizards from "./modules/components/Wizards";
import Toggle from "./modules/utilities/Toggle";
import Accordion from "./modules/utilities/Accordion";
import RenewBanner from "./modules/components/RenewBanner";
import DynamicHeight from "./modules/utilities/DynamicHeight";
import Reminders from "./modules/components/Reminders";
import Utility from "./modules/base/Utility";
import Client from "./modules/base/Client";
import Addressbooks from "./modules/components/addressbook/Addressbooks";
import Tables from "./modules/components/Tables";
import Tabs from "./modules/components/Tabs";
import Switch from "./modules/utilities/Switch";
import Flows from "./modules/components/flows/Flows";
import Products from "./modules/components/product/Products";
import ScrollingPausePlay from "./modules/utilities/ScrollingPausePlay";
import StorageManager from "./modules/base/StorageManager.js";
import SSOManager from "./modules/components/sso/SSOManager.js";
import Services from "./modules/services/Services.js";
import Helpers from "./modules/base/Helpers.js";

import "./modules/base/prototypes";

window.app_helpers = Helpers;
window.app_utility = null;
window.app_listeners = null;
window.app_storage = new StorageManager;
window.app_services = new Services;
window.app_page = null;
window.app_client = null;
window.app_accessibility = null;
window.app_header = null;
window.app_dialogs = null;
window.app_forms = null;
window.app_images = null;
window.app_filters = null;
window.app_carousels = null;
window.app_wizards = null;
window.app_addressbooks = null;
window.app_tables = null;
window.app_tabs = null;
window.app_flows = null;
window.app_products = null;
window.app_reminders = null;
window.app_sso = null;

const appInit = () => {
  // Stored Modules
  try { window.app_utility = Utility } catch (e) { console.error('[Utility] module error: ', e) };
  try { window.app_listeners = new Listeners } catch (e) { console.error('[Listeners] module error: ', e) };
  try { window.app_page = new Page } catch (e) { console.error('[Page] module error: ', e) };
  try { window.app_client = Client } catch (e) { console.error('[Client] module error: ', e) };
  try { window.app_header = new Header } catch (e) { console.error('[Header] module error: ', e) };
  try { window.app_dialogs = new Dialogs } catch (e) { console.error('[Dialogs] module error: ', e) };
  try { window.app_forms = new Forms } catch (e) { console.error('[Forms] module error: ', e) };
  try { window.app_accessibility = new Accessibility } catch (e) { console.error('[Accessibility] module error: ', e) };
  try { window.app_images = new Images } catch (e) { console.error('[Images] module error: ', e) };
  try { window.app_filters = new Filters } catch (e) { console.error('[Filters] module error: ', e) };
  try { window.app_carousels = new Carousels } catch (e) { console.error('[Carousels] module error: ', e) };
  try { window.app_wizards = new Wizards } catch (e) { console.error('[Wizards] module error: ', e) };
  try { window.app_tabs = new Tabs } catch (e) { console.error('[Tabs] module error: ', e) };
  try { window.app_tables = new Tables } catch (e) { console.error('[Tables] module error: ', e) };
  try { window.app_addressbooks = new Addressbooks } catch (e) { console.error('[Addressbooks] module error: ', e) };
  try { window.app_flows = new Flows } catch (e) { console.error('[Flows] module error: ', e) };
  try { window.app_products = new Products } catch (e) { console.error('[Products] module error: ', e) };
  try { window.app_reminders = new Reminders } catch (e) { console.error('[Reminders] module error: ', e) };
  try { window.app_sso = new SSOManager } catch (e) { console.error('[SSOManager] module error: ', e) };

  // Not Stored Modules
  try { new Toggle } catch (e) { console.error('[Toggle] module error: ', e) };
  try { new Accordion } catch (e) { console.error('[Accordion] module error: ', e) };
  try { new Sticky } catch (e) { console.error('[Sticky] module error: ', e) };
  try { new RenewBanner } catch (e) { console.error('[RenewBanner] module error: ', e) };
  try { new DynamicHeight } catch (e) { console.error('[DynamicHeight] module error: ', e) };
  try { new LoadMore } catch (e) { console.error('[LoadMore] module error: ', e) };
  try { new Switch } catch (e) { console.error('[Switch] module error', e) };
  try { new ScrollingPausePlay } catch (e) { console.error('[ScrollingPausePlay] module error: ', e) };

}


// Features test
const testFeatures = () => (
  window.ResizeObserver
  && window.ResizeObserverEntry
  && window.IntersectionObserver
  && window.IntersectionObserverEntry
  && window.MutationObserver
  && window.MutationRecord
  && window.AbortController
  && window.Intl
)

// Polyfill detection
// #TODO requires further testing and optimizations
const polyfillMiddleware = () => {
  if (testFeatures()) {
    appInit();
  } else {
    Utility.loadResources(
      'polyfill',
      [`${app_env.imghost}/ag/assets/js/vendors/polyfill.js`],
      appInit
    );
  }
}

// Initialization process
if (document.readyState != "loading") {
  polyfillMiddleware();
} else {
  document.addEventListener('DOMContentLoaded', polyfillMiddleware);
}