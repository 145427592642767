import Utility from "../../base/Utility";
import Flow from "./Flow";
import SendFlow from "./SendFlow";

class Flows {
  constructor() {
    this.collection = new Map();
    this.flowsMapping = {
      "send-flow": SendFlow,
    };
    this.init();
  }

  init() {
    const els = document.querySelectorAll('.flow');
    if (!els || els.length == 0) return
    els.forEach(this.add.bind(this));
  }

  add(el, reinit = false) {
    if (el.id == '') {
      el.id = `flow-instance`;
    }
    Utility.fixDuplicates(el);
    if (this.collection.has(el.id)) {
      if (reinit) {
        this.collection.get(el.id).destroy()
      } else {
        return
      }
    }
    if (this.flowsMapping.hasOwnProperty(el.id)) {
      let instantiator = this.flowsMapping[el.id];
      this.collection.set(el.id, new instantiator(el, this));
    } else {
      this.collection.set(el.id, new Flow(el, this));
    }
  }
}

export default Flows;