import Utility from "../base/Utility";

class Images {
  constructor(){
    this.intersectionObserver = new IntersectionObserver(this.intersectionCallback.bind(this), {
      rootMargin: '0px',
      threshold: 0,
    })
    this.mutationObserver = new MutationObserver(this.mutationCallback.bind(this))
    this.init();
  }

  init(el = document){
    if(!el) return
    const imgs = el.querySelectorAll('.page-wrapper :not([aria-hidden="true"]) .lazy-load:not(.--init),.page-wrapper :not([aria-hidden="true"]) .lazy-load-img:not(.--init)');
    imgs.forEach(this.initImage.bind(this));
  }

  initImage(img){
    if (img.classList.contains('--init')) return
    img.classList.add('--init');
    this.intersectionObserver.observe(img);
    // TODO: Add delayed resize logic from smaller to greater to upload the bigger images in case of orientation change
  }

  intersectionCallback(entries, observer){
    entries.forEach(entry => {
      if (entry.isIntersecting){
        Utility.loadImage(entry.target, false);
        let img = entry.target.tagName == 'IMG' ? entry.target : entry.target.querySelector('img');
        img && this.mutationObserver.observe(img, {attributeFilter: ["data-src"]});
        entry.target.classList.add('--onscreen')
        entry.target.classList.remove('--offscreen')
      } else {
        entry.target.classList.add('--offscreen')
        entry.target.classList.remove('--onscreen')
      }
    });
  }

  loadImages(container) {
    container && Utility.loadImages(container);
  }

  mutationCallback(mutations) {
    mutations.forEach(mutation => {
      let imgHolder = mutation.target.closest(".lazy-load") || mutation.target;
      imgHolder && Utility.loadImage(imgHolder, true);
    })
  }

}

export default Images;