"use strict";

class ScrollingPausePlay {

    constructor() {
        this.init();
    }

    init() {
        const x = this;
        const els = document.querySelectorAll('.scrolling-banner');
        if (!els || els.length == 0) return;
        els.forEach(x.initScrollingPausePlay);
    }

    initScrollingPausePlay(el, counter) {
        const trigger = document.getElementById('scrolling-pause');
        const container = el.querySelector('.scrolling-banner__marquee');
        const buttonspan = document.getElementById('play-icon');
        if (!trigger || !container) return;
        trigger.addEventListener('click',() => {
            if (container.classList.contains('--pause-animation')) {
                container.classList.remove('--pause-animation');
                buttonspan.classList.add('--playing');
                trigger.setAttribute('aria-label', 'pause slider')
            } else {
                container.classList.add('--pause-animation');
                buttonspan.classList.remove('--playing');
                trigger.setAttribute('aria-label', 'play slider')
            }
        })
    }
}

export default ScrollingPausePlay;