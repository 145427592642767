import Global from "../../../base/Global";
import Utility from "../../../base/Utility";
import TextareaField from "./TextareaField";

class RichTextField extends TextareaField {
  constructor(el, form) {
    super(el, form);
    this.type = 'rich-text';
    this.isInit = false;
    this.editor = null;
    this.isFocused = false;
    this.editorOverlay = null;
    this.editorReady = false;
    this.inputWrapper = this.field.querySelector(".field__input");
    this.maxCharacterCount = this.el.getAttribute('maxlength') ? Number(this.el.getAttribute('maxlength')) : 1500;
    this.config = {
      selector: `#${this.el.id}`,
      branding: false,
      promotion: false,
      menubar: false,
      width: "100%",
      height: 230,
      min_height: 230,
      typeahead_urls: false,
      block_formats: 'Header=h1; Subheader=h3; Paragraph=p;',
      max_height: 450,
      placeholder: "Write your message...",
      forced_root_block: 'p',
      invalid_elements: "a",
      smart_paste: false,
      paste_block_drop: false,
      paste_data_images: false,
      iframe_aria_text: 'Write Your Message... Rich Text Area. Press ALT-0 or Option-0 for help.',
      toolbar:
        "fontfamily blocks | italic bold forecolor | emoticons help",
      plugins: ["help", "emoticons", "autoresize", "wordcount"],
      help_tabs: ["shortcuts", "keyboardnav"],
      statusbar: false,
      toolbar_mode: "floating",
      mobile: {
        toolbar_mode: "scrolling",
        max_height: 1050,
        toolbar:
          "fontfamily blocks italic bold forecolor",
      },
      color_map: [
        '#2DC26B', 'Green',
        '#F1C40F', 'Yellow',
        '#E03E2D', 'Red',
        '#B96AD9', 'Purple',
        '#3598DB', 'Blue',
        '#169179', 'Dark Turquoise',
        '#E67E23', 'Orange',
        '#BA372A', 'Dark Red',
        '#843FA1', 'Dark Purple',
        '#236FA1', 'Dark Blue',
        '#95A5A6', 'Gray',
        '#7E8C8D', 'Dark Gray',
        '#34495E', 'Navy Blue',
        '#333333', 'Black'
      ],
      content_style: `
        body {
          font-family:"Gordita Content", sans-serif;
          font-size:16px;
          margin: 1rem;
        }
        h1, h2, h3, h4, h5, h6 {
          margin-block-start: .6em;
          margin-block-end: .4em;
          line-height: 1.466666em
        }
        p {
          margin-block-start: .4em;
          margin-block-end: .4em;
          line-height: 1.666666em
        }
        h1, h2 {
          line-height: 1.266666em
        }
        h1 {
          font-size: 1.866em;
        }
        h3 {
          font-size: 1.37em;
        }
        p {
          font-size: 18px;
        }
        hr {
          margin-block-start: 1em;
          margin-block-end: 1em;
        }
        @media (max-width: 500px) {
          p {
            font-size: 16px;
            text-align: left;
          }
          h1 {
            font-size: 1.54em;
          }
          h3 {
            font-size: 1.27em;
          }
        }
        `,
      elementpath: false,
      font_css: Global.richTextEditorCustomFontsSrc,
      font_family_formats: Global.richTextEditorCustomFontsFormat,
      setup: this.setupHandler.bind(this),
    };
    Utility.conditionalInit(this.el, this.loadLibrary.bind(this))
  }

  renderErrors() {
    super.renderErrors();
    setTimeout(() => {
      window.app_accessibility.announce({
        message: this.errors.values().next().value,
        mode: "polite"
      })
    }, 600)
  }

  setSelect() {
    if (this.editor && this.editorReady) {
      this.editor.focus();
      setTimeout(() => {
        this.editor.selection.select(this.editor.getBody(), true);
      }, 100);
    } else {
      this.el.select();
    }
  }

  loadLibrary() {
    if (this.isInit) return
    this.isInit = true;
    if (this.el.value.trim() != "" || this.el.hasAttribute("required")) {
      this.form.fieldUnready(this.truename);
    }
    Utility.loadResources(
      "tinymce",
      [`${app_env.imghost}/ag/assets/js/vendors/tinymce/tinymce.min.js`],
      this.initRichTextField.bind(this),
      false
    );
  }

  setValue(value, trigger = false) {
    if (this.editor && this.editorReady && value) {
      this.editor.setContent(`<p>${value}</p>`);
      this.characterInputHandler();
    }
    this.value = value;
    this.el.value = value;
    if (trigger) {
      this.el.dispatchEvent(new Event("input"));
      this.el.dispatchEvent(new Event("change", {bubbles:true}));
    }
  }

  getValue() {
    let content = this.el.value.trim();
    if (this.editor && this.editorReady) {
      content = this.editor.getContent();
      if (content.endsWith('<p>&nbsp;</p>')) {
        content = content.substring(0, content.length - '<p>&nbsp;</p>'.length)
      }
    }
    return content;
  }

  setFocus() {
    setTimeout(() => {
      if (this.editor && this.editorReady) {
        this.editor.focus();
      } else {
        this.el.focus();
      }
    }, 100);
  }

  initRichTextField() {
    let tick = setInterval(() => {
      try {
        tinymce.init(this.config);
        clearInterval(tick);
      } catch (e) { }
    }, 100);
  }

  setupHandler(editor) {
    this.editor = editor;
    this.editor.on("init", this.editorInitHandler.bind(this));
  }

  editorInitHandler() {
    this.loadedHandler();
    this.editorOverlay = document.querySelector(".tox-tinymce-aux");
    if (this.el.value.trim() != "" || this.el.hasAttribute("required")) {
      this.form.fieldReady(this.truename);
    }
    this.editorReady = true;
    this.editor.on("focus", this.editorFocusHandler.bind(this));
    this.editor.on("blur", this.editorBlurHandler.bind(this));
    this.editor.on("input", this.characterInputHandler.bind(this));
    this.editor.on("CloseWindow", this.dialogCloseHandler.bind(this));
    this.editor.on("OpenWindow", this.dialogOpenHandler.bind(this));
    this.editor.on("Change", () => {
      this.el.dispatchEvent(new Event("change", {bubbles: true}))
    });
    this.rules.set('maxlength', {
      error:
        this.el.getAttribute("data-maxlength-error") ||
        `${this.maxCharacterCount} characters at most`,
      formError:
        this.el.getAttribute("data-maxlength-form-error") ||
        this.el.getAttribute("data-maxlength-error") ||
        `Maximum characters for ${this.nameLowercase} is ${this.maxCharacterCount}`,
      test: (v) =>
        this.editor.plugins.wordcount.body.getCharacterCount() == 0 || this.editor.plugins.wordcount.body.getCharacterCount() <= Number(this.maxCharacterCount),
    })
    this.characterInputHandler();
    window.app_listeners.add(
      "focus",
      `field-${this.truename}-focus`,
      this.fieldStateHandler.bind(this)
    );
    window.app_listeners.add(
      "blur",
      `field-${this.truename}-blur`,
      this.fieldStateHandler.bind(this)
    );
  }

  editorFocusHandler(e) {
    this.setFieldState();
  }

  dialogCloseHandler() {
    window.app_page?.unfreezeScroll(false);
  }

  dialogOpenHandler() {
    window.app_page?.freezeScroll(false);
  }

  editorBlurHandler(e) {
    this.setFieldState(false);
  }

  fieldStateHandler(e) {
    if (
      e.composedPath().includes(this.inputWrapper) ||
      e.composedPath().includes(this.editorOverlay) ||
      document.activeElement == this.editor.iframeElement
    ) {
      this.setFieldState();
    } else {
      this.setFieldState(false);
    }
  }

  setFieldState(active = true) {
    if (active) {
      this.isFocused = true;
      this.inputWrapper.classList.add("--focused");
      if (this.form.el.hasAttribute("data-recaptcha-protect")) {
        window.app_page.showRecaptcha();
      }
      Utility.updateContent({
        "focus-instructions": {
          removeClass: "hidden"
        }
      })
    } else {
      this.isFocused = false;
      clearTimeout(this.announcer);
      this.inputWrapper.classList.remove("--focused");
      Utility.updateContent({
        "focus-instructions": {
          addClass: "hidden"
        }
      })
    }
  }
}

export default RichTextField;
