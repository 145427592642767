import Client from "../../../base/Client";
import Helpers from "../../../base/Helpers";
import Utility from "../../../base/Utility";
import BaseField from "./BaseField";

class DateField extends BaseField {
  constructor(el, form) {
    super(el, form);
    this.type = "date";
    this.picker = null;
    this.isPickerOpen = false;
    this.isEditing = false;
    this.isInit = false;
    this.fieldset = this.field.querySelector('.field__fieldset');
    this.datepickerOptions = {};
    this.minDate = null;
    this.maxDate = null;
    this.monthDays = [31, 29, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
    this.dateBuffer = {
      month: "",
      day: "",
      year: "",
    };
    this.subfields = new Map();
    Utility.conditionalInit(this.el, this.initDate.bind(this))
  }

  initDate() {
    if (this.isInit) return
    this.pickerEl = this.field.querySelector("duet-date-picker");
    this.minDate = new Date();
    this.selectedDate = Helpers.convertDateTimeFromServer(this.el.getAttribute("data-date")) || this.minDate;
    this.maxDays = this.el.getAttribute("data-max-days") || "";
    if(this.maxDays) {
      const nextDays = this.minDate.getTime() + this.maxDays * 24 * 60 * 60 * 1000;
      this.maxDate = new Date(nextDays);
    } else {
      this.maxDate = new Date();
      this.maxDate.setYear(this.minDate.getFullYear() + 1);
    }
    this.el.value = Helpers.formatDate(this.selectedDate);
    this.el.setAttribute("value", Helpers.formatDate(this.selectedDate));
    this.pickerEl?.setAttribute("max", Helpers.formatDate(this.maxDate));
    this.pickerEl?.setAttribute("min", Helpers.formatDate(this.minDate));
    this.pickerEl?.setAttribute("value", Helpers.formatDate(this.selectedDate));
    let day = Number(this.selectedDate.getDate()).pad(2);
    let month = (Number(this.selectedDate.getMonth()) + 1).pad(2);
    let year = this.selectedDate.getFullYear();
    this.setDate(day, month, year, true);
    this.isInit = true;
    this.disable();
    this.el.disabled = true;
    this.fieldset.disabled = true;
    Utility.loadModules(
      "duet-picker",
      [
        {
          nomodule:
            `${app_env.imghost}/ag/assets/js/vendors/duet/www/build/duet.js`,
          module:
            `${app_env.imghost}/ag/assets/js/vendors/duet/www/build/duet.esm.js`,
        },
      ],
      this.waitCustomElement.bind(this)
    );
  }

  setValue(value, trigger = false) {
    let changed = String(this.el.value) != String(value);
    this.value = value;
    this.el.value = value;
    this.el.setAttribute("data-date", value);
    this.el.setAttribute("value", value);
    this.field.querySelector("duet-date-picker")?.setAttribute("value", value);

    if (trigger && changed) {
      this.el.dispatchEvent(new Event("change", {bubbles: true}));
    }
  }

  updateDate(init = false) {
    let date = [];
    this.subfields.forEach((subfield) => {
      if (subfield.value == "") {
        date.push(
          Number(subfield.getAttribute("min")).pad(
            Number(subfield.getAttribute("maxlength"))
          )
        );
      } else {
        date.push(subfield.value);
      }
    });

    let selectedDay = this.setLimits(date[1], date[0], date[2]);

    if (selectedDay < this.minDate) {
      selectedDay = this.minDate;
    }
    if (selectedDay > this.maxDate) {
      selectedDay = this.maxDate;
    }

    let day = Number(selectedDay.getDate()).pad(2);
    let month = (Number(selectedDay.getMonth()) + 1).pad(2);
    let year = selectedDay.getFullYear();

    selectedDay = this.setLimits(day, month, year);
    this.setDate(day, month, year, init);
  }

  setLimits(day, month, year) {
    this.monthDays[1] = Utility.isLeapYear(Number(year)) ? 29 : 28;
    this.subfields.forEach((subfield) => {
      if (subfield.name == "day") {
        let maxDays = this.monthDays[Number(month) - 1];
        subfield.setAttribute("max", 31);
        day =
          Number(subfield.value) > maxDays
            ? maxDays.pad(2)
            : Number(subfield.value).pad(2);
        subfield.value = day;
      }
    });
    return new Date(`${year}-${month}-${day}T${new Date().toTimeString().split(" ")[0]}`);
  }

  setDate(day, month, year, init = false, fromWidget = false) {
    const valuesMap = {day, month, year};
    if (this.subfields.size != 0) {
      this.subfields.forEach(subfield => {
        subfield.value = valuesMap[subfield.name];
      });
    } else {
      this.field.querySelectorAll(`.field__subfield`)?.forEach(subfieldEl => {
        subfieldEl.value = valuesMap[subfieldEl.name]
      })
    }
    if (!init && !fromWidget) {
      this.picker.value = `${year}-${month}-${day}`;
    }
    this.el.value = `${year}-${month}-${day}`;
    this.onChange(this);
    this.el.dispatchEvent(new Event("change", {bubbles: true}))
    this.fieldset?.setAttribute('aria-label', `${this.name} is ${new Date(this.el.value).toLocaleDateString("en-US")}`);
  }

  waitCustomElement() {
    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (
          mutation.attributeName == "class" &&
          mutation.target.classList.contains("hydrated")
        ) {
          this.initPicker();
          observer.disconnect();
        }
      });
    });
    observer.observe(this.pickerEl, { attributes: true });
  }

  handleViewportChange(e) {
    if (window.app_dialogs?.activeDialogs.size != 0) return
    if (window.app_header?.mobileMenu?.menuIsOpen) return
    if (e.matches && this.isPickerOpen) {
      window.app_page?.freezeScroll(false);
    } else {
      window.app_page?.unfreezeScroll(false);
    }
  }

  initPicker() {
    this.picker = this.field.querySelector("duet-date-picker");
    this.mediaQuery = Client.queryMedia("(max-width: 35.9375em)", this.handleViewportChange.bind(this), true);
    this.picker.addEventListener(
      "duetClose",
      () => {
        this.isPickerOpen = false;
        window.app_page?.unfreezeScroll();
        let clickInside = this.picker.contains(document.activeElement);
        setTimeout(() => {
          let label = this.fieldBtn.getAttribute("aria-label");
          label = label.replace("Close", "Open").replace("close", "open");
          this.fieldBtn.setAttribute("aria-label", label);
          if (clickInside) {
            this.fieldBtn.focus();
          }
        }, 20);
      },
      { signal: this.abortController.signal }
    );

    this.picker.addEventListener(
      "duetOpen",
      () => {
        this.isPickerOpen = true;
        let label = this.fieldBtn.getAttribute("aria-label");
        setTimeout(() => {
          if (this.isPickerOpen) {
            this.fieldBtn.setAttribute("aria-label", label);
          }
        }, 400);
      },
      { signal: this.abortController.signal }
    );

    if (this.picker) {
      this.field.querySelector('.duet-date__input-wrapper input[type="hidden"][name="date"]')?.setAttribute('data-skip', '');

      this.picker.dateAdapter = {
        format(date) {
          return `${date.toLocaleDateString("en-US", {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
          })}`;
        },
      };

      this.picker.localization = {
        focusOnClose: false,
        focusDayOnOpen: true,
        externalTrigger: this.fieldBtn,
        buttonLabel: "Choose date",
        placeholder: "mm/dd/yyyy",
        selectedDateMessage: "Selected date is",
        prevMonthLabel: "Previous month",
        nextMonthLabel: "Next month",
        monthSelectLabel: "Month",
        yearSelectLabel: "Year",
        closeLabel: "Close window",
        calendarHeading: "Choose a date",
        dayNames: [
          "Sunday",
          "Monday",
          "Tuesday",
          "Wednesday",
          "Thursday",
          "Friday",
          "Saturday",
        ],
        monthNames: [
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
          "August",
          "September",
          "October",
          "November",
          "December",
        ],
        monthNamesShort: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ],
        locale: "en-US",
      };

      this.picker.addEventListener(
        "duetChange",
        (event) => {
          let date = event.detail.valueAsDate;
          let day = Number(date.getDate()).pad(2);
          let month = (Number(date.getMonth()) + 1).pad(2);
          let year = date.getFullYear();
          this.setDate(day, month, year, false, true);
        },
        { signal: this.abortController.signal }
      );

      this.fieldBtn?.addEventListener("click", () => {
        if (!this.isPickerOpen) {
          this.picker.show();
        } else {
          this.picker.hide(false);
        }
      },
      { signal: this.abortController.signal }
      );
    }

    this.initDateComplete();
  }

  initDateComplete() {
    this.enable();
    this.el.disabled = false;
    this.fieldset.disabled = this.locked;
    this.field?.querySelectorAll(".field__subfield")?.forEach((subfield) => {
      subfield.addEventListener("input", this.handleSubfieldInput.bind(this), {
        signal: this.abortController.signal,
      });
      subfield.addEventListener("focus", this.handleSubfieldFocus.bind(this), {
        signal: this.abortController.signal,
      });
      subfield.addEventListener("blur", this.handleSubfieldBlur.bind(this), {
        signal: this.abortController.signal,
      });
      this.dateBuffer[subfield.name] = Number(subfield.value).pad(
        Number(subfield.getAttribute("maxlength"))
      );
      if (subfield.name == "year") {
        subfield.setAttribute("min", this.minDate.getFullYear());
        subfield.setAttribute("max", this.maxDate.getFullYear());
      }
      this.subfields.set(subfield.name, subfield);
    });
    this.field
      .querySelector(".field__subfields")
      ?.addEventListener("click", this.handleSubfieldClick.bind(this), {
        signal: this.abortController.signal,
      });
    this.updateDate(true);
  }

  blurHandler(e) {
    // nothing
  }

  handleSubfieldBlur(e) {
    this.isEditing = false;
    let max = Number(e.target.getAttribute("max"));
    let min = Number(e.target.getAttribute("min"));
    let userInput = e.target.value;
    userInput = Number(userInput) > max ? max : userInput;
    userInput = Number(userInput) < min ? min : userInput;
    e.target.value = Number(userInput).pad(
      Number(e.target.getAttribute("maxlength"))
    );
    this.dateBuffer[e.target.name] = e.target.value;
    setTimeout(() => {
      if (!this.isEditing) {
        this.updateDate();
      }
    }, 20);
  }

  selectNextSubfield(el) {
    let target = el.nextElementSibling;
    if (!target) return
    if (target.classList.contains("field__subfield")) {
      target.select();
    } else {
      this.selectNextSubfield(target);
    }
  }

  selectPrevSubfield(el) {
    let target = el.previousElementSibling;
    if (!target) return;
    if (target.classList.contains("field__subfield")) {
      target.select();
    } else {
      this.selectPrevSubfield(target);
    }
  }

  handleKeydown(e) {
    if (!this.field.contains(document.activeElement)) return;
    if (e.target.classList.contains("field__subfield")) {
      if (["ArrowRight", "ArrowLeft", "Enter"].includes(e.key)) {
        if (e.key == "ArrowRight") {
          this.selectNextSubfield(e.target);
          this.updateDate();
        } else if (e.key == "ArrowLeft") {
          this.selectPrevSubfield(e.target);
          this.updateDate();
        }
        e.preventDefault();
      }
    }
  }

  handleSubfieldClick(e) {
    if (!this.field.contains(document.activeElement)) {
      if (!document.activeElement.classList.contains("field__subfield")) {
        this.subfields.get("month").select();
      }
    } else {
      if (e.target.classList.contains("field__subfield")) {
        e.target.select();
      }
    }
  }

  setFocus() {
    this.subfields.get("month").select();
  }

  handleSubfieldFocus(e) {
    this.dateBuffer[e.target.name] = "";
    this.isEditing = true;
  }

  getValue() {
    let targetDate = new Date(this.el.value + "T03:00:00");
    let serverTime = Helpers.convertDateTimeForServer(targetDate);
    return serverTime.toISOString().replace("Z", '');
  }

  getLocalValue() {
    let targetDate = new Date(this.el.value + "T" + new Date().toTimeString().split(" ")[0]);
    return targetDate.toISOString();
  }

  handleSubfieldInput(e) {
    let isFinal = true;
    let isComplete = false;
    let max = Number(e.target.getAttribute("max"));
    let min = Number(e.target.getAttribute("min"));
    if (e.target.name == "year") {
      if (e.data && !["", "e", "E", ".", "-", "+"].includes(e.data)) {
        let userInput = String(this.dateBuffer[e.target.name]) + String(e.data);
        if (userInput.length >= Number(e.target.getAttribute("maxlength"))) {
          isComplete = true;
          userInput = Number(userInput) > max ? max : userInput;
          userInput = Number(userInput) < min ? min : userInput;
        }
        e.target.value = userInput;
        this.dateBuffer[e.target.name] = e.target.value;
      } else {
        if (e.inputType == "deleteContentBackward") {
          this.dateBuffer[e.target.name] = Number(e.target.value);
        } else {
          this.dateBuffer[e.target.name] = "";
        }
        e.preventDefault();
        isFinal = false;
        isComplete = false;
      }
    } else {
      // if input is a singular number character
      if (e.data && !["", "e", "E", ".", "-", "+"].includes(e.data)) {
        let userInput = String(this.dateBuffer[e.target.name]) + String(e.data);
        if (userInput.length >= Number(e.target.getAttribute("maxlength"))) {
          isComplete = true;
          userInput = Number(userInput) > max ? max : userInput;
          userInput = Number(userInput) < min ? min : userInput;
        } else {
          userInput = Number(userInput) > max ? e.data : userInput;
          userInput = Number(userInput) < min ? e.data : userInput;
        }
        e.target.value = userInput;
        this.dateBuffer[e.target.name] = e.target.value;
        let range = [];
        for (let i = min; i <= max; i++) {
          if (i != Number(userInput)) {
            range.push(
              Number(i).pad(Number(e.target.getAttribute("maxlength")))
            );
          }
        }
        range.forEach((nr) => {
          let sliced = nr.slice(0, userInput.length);
          if (sliced.includes(userInput)) {
            isFinal = false;
          }
        });
      } else {
        e.preventDefault();
        this.dateBuffer[e.target.name] = "";
        isFinal = false;
        isComplete = false;
      }
    }

    this.addLeading(e.target);

    if (isComplete) {
      this.dateBuffer[e.target.name] = "";
    }


    setTimeout(() => {
      if (isFinal || isComplete) {
        this.selectNextSubfield(e.target);
      } else {
        e.target.focus();
      }
    }, 100)
  }

  addLeading(el) {
    el.value = Number(el.value).pad(Number(el.getAttribute("maxlength")));
  }

  removeLeading(el) {
    el.value = Number(el.value);
  }

  handleInput(e) {
    e.target.value = this.filterRegEx(e.target.value);
    this.value = e.target.value;
    this.afterSubmitValidation();
    this.onInput(this.value);
  }
}

export default DateField;