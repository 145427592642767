import Utility from "../../../base/Utility";
import BaseProduct from "./BaseProduct";

class EcardProduct extends BaseProduct {
  constructor(el, parent, type, env=null) {
    super(el, parent, type, env);
    this.el.classList.add('--waits-visible');
    this.loadProduct();
  }

  afterLoad() {
    Utility.getVideoDimensions(
      this.container,
      this.productInterface?.baseResolution,
      this.setProductDimensions.bind(this)
    ).then(dimensions => {
      this.setProductDimensions(dimensions)
      this.ready();
    })
  }
}

export default EcardProduct;