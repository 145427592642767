import Client from "../../base/Client";
import Format from "../../base/Format";
import Render from "../../base/Render";
import Utility from "../../base/Utility";

class Typeahead {
  constructor(el, form) {
    this.el = el;
    this.isInit = false;
    this.form = form;
    this.entries = [];
    this.filtered = [];
    this.itemTemplate =
      this.el.querySelector(".typeahead-item-template") || null;
    this.itemTemplateString = null;
    this.maxOptions = Number(el.getAttribute('data-max-options-shown')) || 15;
    this.currentKey = "";
    this.currentValue = "";
    this.listbox = this.el.querySelector(".listbox");
    this.listboxList = this.el.querySelector(".listbox__list");
    this.listboxScroll = this.el.querySelector(".listbox__scroll");
    this.listboxMaxShownEl = this.el.querySelector(".listbox__max-shown");
    this.listboxContainer = this.el.querySelector(".listbox__container");
    this.isListboxOpen = false;
    this.scrollOffset = Number(el.getAttribute('data-scroll-offset')) || 4;
    this.key = this.el.getAttribute("data-key") || "addressbook";
    this.type = this.el.getAttribute("data-type") || "contact";
    this.endpoint = this.el.getAttribute("data-get") || null;
    this.filters = new Map();
    this.attachedField = null;
    this.abortController = new AbortController();
    Utility.conditionalInit(this.el, this.init.bind(this));
  }

  init() {
    if (!this.listbox) return
    if (this.isInit) return
    this.isInit = true;
    this.listbox.id = this.listbox.id || "typeahead-instance";
    Utility.fixDuplicates(this.listbox);
    this.listboxMaxShownEl.id = `${this.listbox.id}-max-shown`;
    if (this.itemTemplate) {
      this.itemTemplateString = Render.stringFromFragment(
        this.itemTemplate.content.cloneNode(true)
      );
    }
    if (window.app_tables) {
      let data = window.app_tables.getSource(this.endpoint);
      if (data) {
        this.dataLoad(data, true);
        return;
      }
    }
    fetch(this.endpoint)
      .then((response) => response.json())
      .then((data) => {
        this.dataLoad(data);
      })
      .catch((e) => {
        console.warn(e);
      });
  }

  dataLoad(data, fromSource = false) {
    this.entries = data;
    !fromSource && window.app_tables?.addSource(this.endpoint, this.entries);
    this.initTypeahead();
    let target = document.activeElement;
    if (
      target != document.body
      && target.closest(`#${this.form.id}`)
      && target.getAttribute("data-typeahead") == this.key
    ) {
      this.attachToField(this.form.fields.get(target.name));
    }
  }

  initTypeahead() {
    window.app_listeners?.add(
      "focus",
      `typeahead-${this.key}-focus`,
      this.focusHandler.bind(this)
    );
    window.app_listeners?.add(
      "blur",
      `typeahead-${this.key}-blur`,
      this.blurHandler.bind(this)
    );
    window.app_listeners?.add(
      "click",
      `typeahead-${this.key}-click`,
      this.clickHandler.bind(this)
    );
    window.app_listeners?.add(
      "resize",
      `typeahead-${this.key}-resize`,
      this.resizeHandler.bind(this)
    );
  }

  focusHandler(e) {
    if (e.target == window) return;
    if (!e.composedPath().includes(this.form.el)) return;
    if (
      e.target.hasAttribute("data-typeahead") &&
      e.target.getAttribute("data-typeahead") == this.key
    ) {
      if (this.attachedField && this.attachedField.el != e.target) {
        this.closeListbox();
      }
      this.attachToField(this.form.fields.get(e.target.name));
    }
  }

  resizeHandler() {
    this.isListboxOpen && this.closeListbox();
  }

  blurHandler(e) {
    if (!this.isListboxOpen || !this.attachedField) return;
    let eventInside = e.composedPath().includes(this.attachedField.field) || e.composedPath().includes(this.el);
    if (!eventInside) {
      this.closeListbox();
    }
  }

  clickHandler(e) {
    if (!this.isListboxOpen || !this.attachedField) return;
    let target = e.real_target || e.target;
    let eventInside = e.composedPath().includes(this.attachedField.field) || e.composedPath().includes(this.el);
    if (eventInside) {
      if (target.hasAttribute("data-value")) {
        this.select(target.getAttribute("data-value"));
      }
    } else {
      this.closeListbox();
    }
  }

  getEntry(value, field = "id") {
    if (!this.entries) return null;
    if (!value) return null;
    return this.entries.find(
      (entry) =>
        entry.hasOwnProperty(field) && String(entry[field]) === String(value)
    );
  }

  select(id) {
    let entry = this.getEntry(id);
    if (entry) {
      entry.name = Format.getContactName(entry);
      let rowID = this.attachedField.el.getAttribute("data-id");
      ["name", "email"].forEach((key) => {
        let input = this.form.el.querySelector(
          `input[data-id="${rowID}"][data-key="${key}"]`
        );
        if (input) {
          input.value = entry[key];
          input.dispatchEvent(new Event("change", { bubbles: true }));
        }
      });
      window.app_accessibility?.announce({
        message: `Recipient row was filled with ${entry.name} ${entry.email} contact`
      })
    }
    this.closeListbox();
    this.attachedField.el.focus();
  }

  inputHandler(e, force = false) {
    if (e.target.value.trim().length < 1 && !force) {
      this.closeListbox();
      return;
    }
    this.currentKey = e.target.getAttribute("data-key");
    this.currentValue = e.target.value;
    this.updateFilters();
    this.renderOptions();
    if (this.filtered.length == 0) {
      this.el.classList.add("--empty");
      this.listbox.classList.add("--empty");
      this.closeListbox();
    } else {
      this.el.classList.remove("--empty");
      this.listbox.classList.remove("--empty");
      this.openListbox();
      this.focusOption();
    }
  }

  focusOption(optionEl = null, byHover = false, focusLast = false) {
    let focusedOption = this.listboxList?.querySelector("button.--focused");
    if (optionEl != focusedOption) {
      focusedOption?.classList.remove("--focused");
    }
    if (!this.isListboxOpen) {
      this.attachedField.el.setAttribute("aria-activedescendant", "");
      return
    }
    if (!optionEl) {
      optionEl = this.listboxList?.querySelector(`[aria-selected="true"]`);
    }
    if (!optionEl) {
      let qString = `.listbox-item:${focusLast ? "last-child" : "first-child"}`;
      optionEl = this.listboxList?.querySelector(qString);
    }
    if (optionEl) {
      optionEl.classList.add("--focused");
      this.attachedField.el.setAttribute("aria-activedescendant", optionEl.id);
      this.scrollListboxOption(optionEl);
    } else {
      this.attachedField.el.setAttribute("aria-activedescendant", "");
    }
  }

  scrollListboxOption(el) {
    let holder = this.listboxScroll;
    let offset = this.scrollOffset;
    let topOffset = this.listbox.classList.contains("--max-shown") ? 30 + this.scrollOffset : this.scrollOffset;
    if (holder.scrollTop + holder.offsetHeight < el.offsetTop + el.offsetHeight) {
      holder.scrollTop = el.offsetTop + el.offsetHeight - holder.offsetHeight + offset;
    } else if (holder.scrollTop > el.offsetTop - topOffset) {
      holder.scrollTop = el.offsetTop - topOffset;
    }
  }

  updateFilters() {
    if (this.currentValue.trim().length >= 1) {
      this.filters.set("search", this.currentValue.trim());
    } else {
      this.filters.delete("search");
    }
    if (window.app_tables?.collection.has("recipients")) {
      const emails = [];
      const recipients =
        window.app_tables?.collection.get("recipients").entries;
      const hasContacts = recipients.length != 0;

      let currentEmail = this.currentValue.trim();
      if (this.currentKey == "name") {
        let emailField = this.attachedField.el
          .closest("tr")
          ?.querySelector('input[type="email"]');
        if (emailField && emailField.value.trim() != "") {
          currentEmail = emailField.value.trim();
        }
      }
      if (hasContacts) {
        recipients.forEach((entry) => {
          if (entry.email != "" && currentEmail != entry.email) {
            emails.push(entry.email);
          }
        });
      }
      if (emails.length != 0) {
        this.filters.set("exclude_by_key", {
          key: "email",
          values: emails,
        });
      } else {
        this.filters.delete("exclude_by_key");
      }
    }
  }

  openListbox() {
    if (this.filtered.length == 0) return
    this.attachedField.el.setAttribute("aria-expanded", true);
    this.attachedField.el.setAttribute("aria-controls", this.listbox.id);
    if (this.isListboxOpen) return;
    this.el.classList.remove('--closing');
    this.isListboxOpen = true;
    this.setPosition();
    this.el.classList.add("--active");
    this.listbox.setAttribute("aria-hidden", false);
  }

  closeListbox() {
    if (!this.isListboxOpen) return;
    this.isListboxOpen = false;
    this.attachedField.el.setAttribute("aria-expanded", false);
    this.attachedField.el.removeAttribute("aria-controls");
    this.attachedField.el.removeAttribute("aria-activedescendant");
    this.el.classList.add('--closing');
    setTimeout(() => {
      if (!this.el.classList.contains('--closing')) return
      this.el.classList.remove('--active');
      this.el.classList.remove('--closing');
    }, 200);
    setTimeout(() => {
      this.listbox.setAttribute("aria-hidden", true);
    })
  }

  setPosition() {
    let target = this.attachedField.el.getBoundingClientRect();
    let container = this.form.el.getBoundingClientRect();
    let left = Math.abs(target.left - container.left);
    let top = Math.abs(target.top - container.top + target.height);
    let right = Math.abs(target.right - container.right);
    this.el.style.setProperty("--typeahead-top", top + "px");
    this.el.style.setProperty("--typeahead-bottom", "auto");
    if (container.width - left < 280) {
      this.el.style.setProperty("--typeahead-right", right + "px");
      this.el.style.setProperty("--typeahead-left", "auto");
    } else {
      this.el.style.setProperty("--typeahead-left", left + "px");
      this.el.style.setProperty("--typeahead-right", "auto");
    }
  }

  keydownHandler(e) {
    let focused = this.listboxList?.querySelector("button.--focused");

    if (e.code == "Escape" && this.isListboxOpen) {
      e.preventDefault();
      e.stopPropagation();
      this.closeListbox();
      return
    }

    if (e.code == "Enter" && this.isListboxOpen) {
      e.preventDefault();
      focused ? focused.click() : this.closeListbox();
      return
    }

    if (e.code == "ArrowDown") {
      e.preventDefault();
      if (this.isListboxOpen) {
        let next = this.getNextOption(focused);
        next && this.focusOption(next);
      } else {
        this.inputHandler({ target: this.attachedField.el })
      }
      return
    }

    if (e.code == "ArrowUp") {
      e.preventDefault();
      if (this.isListboxOpen) {
        let prev = this.getPrevOption(focused);
        prev && this.focusOption(prev);
      } else {
        this.inputHandler({ target: this.attachedField.el })
        this.focusOption(null, false, true);
      }
      return
    }
  }

  getNextOption(option) {
    let first = this.listboxList?.querySelector(".listbox-item:first-child");
    let last = this.listboxList?.querySelector(".listbox-item:last-child");

    if (option == last) return first
    let nextOption = option.nextElementSibling;
    if (!nextOption?.classList.contains("listbox-item")) {
      nextOption = nextOption?.nextElementSibling
    }
    return nextOption || first
  }

  getPrevOption(option) {
    let first = this.listboxList?.querySelector(".listbox-item:first-child");
    let last = this.listboxList?.querySelector(".listbox-item:last-child");

    if (option == first) return last
    let prevOption = option.previousElementSibling;
    if (!prevOption?.classList.contains("listbox-item")) {
      prevOption = prevOption?.previousElementSibling
    }
    return prevOption || last
  }

  attachToField(field) {
    if (!field) return
    if (this.attachedField) {
      this.detachFromField();
    }
    this.attachedField = field;
    this.abortController = new AbortController();
    if (this.entries.length) {
      this.attachedField?.el?.setAttribute("aria-autocomplete", "list");
      this.attachedField?.el?.setAttribute("aria-expanded", false);
      this.attachedField?.el?.setAttribute("role", "combobox");
    }
    this.attachedField?.el?.addEventListener(
      "input",
      this.inputHandler.bind(this),
      { signal: this.abortController.signal }
    );
    this.attachedField?.el?.addEventListener(
      "keydown",
      this.keydownHandler.bind(this),
      { signal: this.abortController.signal }
    );
  }

  detachFromField() {
    this.isListboxOpen && this.closeListbox();
    this.abortController?.abort();
  }

  buildExpression(string) {
    return string.replace(new RegExp(/\W/, "ig"), match => `\\${match}`);
  }

  getFilteredEntries() {
    if (!this.entries) return;
    let entries = [...this.entries];
    if (this.filters.size != 0) {
      this.filters.forEach((value, key) => {
        if (key == "search") {
          let expression = this.buildExpression(value);
          entries = entries.filter((item) => {
            let found = false;
            if (this.currentKey == "email") {
              if (item.email.match(new RegExp(`(${expression})`, "gi"))) {
                found = true;
              }
            } else {
              if (
                [item.fname, item.lname]
                  .join(" ")
                  .match(new RegExp(`(${expression})`, "gi"))
              ) {
                found = true;
              }
            }
            return found;
          });
        } else if (key == "exclude_by_key") {
          entries = entries.filter(
            (item) => !value.values.includes(item[value.key])
          );
        }
      });
    }
    this.filtered = [...entries];
  }

  highlight(string) {
    if (this.currentValue.trim() == "") return string;
    string = string.replace(
      new RegExp(`(${this.buildExpression(this.currentValue)})`, "gi"),
      (match) => `<strong class="table__search-match">${match}</strong>`
    );
    string = string.replaceAll(
      '</strong><strong class="table__search-match">',
      ""
    );
    return string;
  }

  filterEntry(entry) {
    let filtered = entry;
    if (this.type == "contact") {
      filtered = {};
      filtered["current_boolean"] = false;
      let inner = "";
      let name = Format.getContactName(entry);
      let email = entry.email;
      if (this.currentKey == "email") {
        if (this.currentValue.trim() === email) {
          filtered["current_boolean"] = true
        }
        inner = `<span class="listbox-item__primary">${this.highlight(
          email
        )}</span> <span class="listbox-item__secondary">${name}</span>`;
      } else {
        if (this.currentValue.trim() === name) {
          filtered["current_boolean"] = true
        }
        inner = `<span class="listbox-item__primary">${this.highlight(
          name
        )}</span> <span class="listbox-item__secondary">${email}</span>`;
      }
      filtered["inner"] = inner;
      filtered["value"] = entry.id;
      filtered["id"] = `${this.listbox.id}-${entry.id}`;
    }
    return filtered;
  }

  renderOptions() {
    this.getFilteredEntries();
    let output = "";
    this.filtered.forEach((entry, index) => {
      if (index >= this.maxOptions) return
      output += this.renderOption(entry);
    });
    this.manageShown();
    this.listboxList.innerHTML = output;
  }

  manageShown() {
    if (this.filtered.length > this.maxOptions) {
      setTimeout(() => {
        this.listboxMaxShownEl.innerHTML = `Showing ${this.maxOptions} of ${this.filtered.length} matching`;
      }, 50);
      this.listboxMaxShownEl.classList.remove("hidden");
      this.listbox.classList.add("--max-shown");
    } else {
      this.listboxMaxShownEl.innerHTML = ""
      this.listboxMaxShownEl.classList.add("hidden");
      this.listbox.classList.remove("--max-shown");
    }
  }

  renderOption(entry) {
    let filteredEntry = this.filterEntry(entry);
    return Render.interpolateString(this.itemTemplateString, filteredEntry);
  }
}

export default Typeahead;
