import Helpers from "../base/Helpers";
import Utility from "../base/Utility";
import Table from "./Table";

class Tables {
  constructor() {
    this.collection = new Map();
    this.sources = new Map();
    this.cacheableSources = [
      "/addressbook/contacts?events=1",
      "/addressbook/contacts?email=1",
    ];
    this.init();
  }

  invalidateCache(exempted = []) {
    this.cacheableSources.forEach(key => {
      if (exempted.includes(key)) return
      window.app_storage.remove(key, "local");
    })
  }

  addSource(key, data, update = false) {
    if (!key || key == '' || !data) return
    if (!this.sources.has(key) || update) {
      data = data instanceof Array ? [...data] : { ...data };
      this.sources.set(key, data);
      this.cacheableSources.includes(key) && this.setCacheableSource(key, data);
    }
  }

  getSource(key) {
    if (this.cacheableSources.includes(key)) {
      return this.sources.get(key) || this.getCacheableSource(key) || null
    }
    return this.sources.get(key) || null
  }

  setCacheableSource(key, data) {
    let previous = window.app_storage.get(key, "local");
    window.app_storage.set(key, {
      dt: previous?.dt || new Date().toJSON(),
      customer: String(app_env.customer?.number || 0),
      data,
    }, "local");
  }

  getCacheableSource(key) {
    const cache = window.app_storage.get(key, "local");
    if (!cache) return null
    if (this.reqiuiresInvalidation(cache)) {
      window.app_storage.remove(key, "local");
      return null
    }
    this.sources.set(key, cache.data)
    return cache.data
  }

  reqiuiresInvalidation(cache) {
    if (cache.customer != String(app_env.customer?.number || 0)) return true
    if (cache.dt) {
      let cacheDate = new Date(Date.parse(cache.dt));
      let minutes = Helpers.getTimeDelta(cacheDate, new Date(), "minutes");
      if (minutes > 60) return true
    }
    return false
  }

  init() {
    const els = document.querySelectorAll('.table:not(.--no-js, .--ignore)');
    if (!els || els.length == 0) return
    els.forEach(this.add.bind(this));
  }

  add(el, reinit = false) {
    if (el.id == '') {
      el.id = `table-instance`;
    }
    Utility.fixDuplicates(el);
    if (this.collection.has(el.id)) {
      if (reinit) {
        this.collection.get(el.id).destroy()
      } else {
        return
      }
    }
    this.collection.set(el.id, new Table(el, this));
  }

}

export default Tables;