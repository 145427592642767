
import TalkingSmashupPreview from "./TalkingSmashupPreview";

class SelfieSmashupPreview extends TalkingSmashupPreview {
  constructor(el, product, pers) {
    super(el, product, pers);
    this.type = 'selfie-smashup';
  }
}

export default SelfieSmashupPreview;