import Client from "../../../base/Client";
import BaseField from "./BaseField";

class PasswordField extends BaseField {
  constructor(el, form) {
    super(el, form);
    this.type = 'password';
    this.showHide = null;
    this.initPassword();
  }

  showPassword(e, show = true) {
    this.el.type = show ? 'text' : 'password';
    this.showHide.setAttribute('aria-label', show ? 'Hide Password' : 'Show Password')
    this.showHide.textContent = show ? 'Hide' : 'Show';
  }

  initPassword() {
    this.showHide = this.field.querySelector('.show-hide-button');
    if (this.showHide) {
      this.showHide.addEventListener('click', (e) => {
        this.showPassword(e, this.el.type == 'password');
      }, {signal: this.abortController.signal})
    }
  }
}

export default PasswordField;