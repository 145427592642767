import Client from "../../base/Client";

class Flow {
  constructor(el) {
    this.el = el;
    this.wizard = null;
    this.dialog = null;
    this.forms = new Map();
    this.externalForms = [];
    this.initFlow();
  }

  initExternalForms() {
    if (this.externalForms) {
      window.app_forms?.collection.forEach(form => {
        if (this.externalForms.includes(form.el.id)) {
          this.forms.set(form.el.id, form);
        }
      })
    }
  }

  initFlow() {
    if (window.app_wizards?.collection.has(this.el.id)) {
      this.wizard = window.app_wizards?.collection.get(this.el.id);
      setTimeout(() => {
        let instance = this.wizard.activePanel;
        Client.dispatchEvent('panel-before-activation', { instance }, instance.el);
        Client.dispatchEvent('panel-activated', { instance }, instance.el);
      }, 0);
    }
    const dialog = this.el.closest(".dialog");
    if (dialog) {
      this.dialog = window.app_dialogs?.collection.get(dialog.id);
    }
    this.el.querySelectorAll('.form:not(.--not-in-flow)').forEach(form => {
      if (window.app_forms?.collection.has(form.id)) {
        this.forms.set(form.id, window.app_forms?.collection.get(form.id))
      }
    })
  }
}

export default Flow;