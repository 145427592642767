class CustomSongClient {
  constructor(el, sendFlow) {
    this.el = el;
    this.sendFlow = sendFlow;
    this.reportingString = "";
    this.beaconQueued = false;
    this.init();
  }

  init() {
    this.client = new SongfinchClient(this.getCustomSongConfig());
    this.client.init();
    this.appendReportingHistory();
    const addCustomSongBtns = document.querySelectorAll("button[data-controls='add-custom-song']");
    addCustomSongBtns.forEach((addCustomSongBtn) => {
      addCustomSongBtn?.addEventListener("click", () => {
        this.client.open();
      });
    });
    const removeCustomSongBtn = this.sendFlow.forms.get("email-send")?.el.querySelector("button[data-controls='remove-custom-song']");
    const removeSharedCustomSong = window.app_forms.collection.get("gift-options-form")?.el.querySelector("button[data-controls='remove-custom-song']");
    const removeCustomSongDialog = window.app_dialogs.collection.get("custom-song-info")?.el.querySelector("button[data-controls='remove-custom-song']");
    const removeButtons = [removeCustomSongBtn, removeCustomSongDialog, removeSharedCustomSong];

    removeButtons.forEach((removeButton) => {
      removeButton?.addEventListener("click", (event) => {
        let removeButton = event.target;
        let form = this.sendFlow.forms.get(removeButton.getAttribute("data-form"));
        if (this.sendFlow.order.features_used.includes("used_songfinch_gift")) {
          this.client.emit("report-record", "deletedsong");
        }
        this.sendFlow.removeGiftAttached();
        this.sendFlow.order.personalizations.gift = {};
        this.sendFlow.order.gift = {};
        let index = this.sendFlow.order.features_used.indexOf("used_songfinch_gift");
        this.sendFlow.order.features_used.splice(index, 1);
        form.fields.get("custom-song").setValue(false, true);
        this.sendFlow.hydrateDeliveryDetails();
        this.sendFlow.hydrateGiftOptionsSubmit();
        this.sendFlow.saveFlow();
      });
    });

    this.client.on("modal-open", () => {
      window.app_page.freezeScroll()
    })
    this.client.on("modal-closed", () => {
      window.app_page.unfreezeScroll()
    })

    this.client.on("song-customization-finished", (songfinchResponse) => {
      this.sendFlow.order.personalizations.gift = songfinchResponse;
      this.sendFlow.order.gift = songfinchResponse;
      this.sendFlow.order.features_used.push("used_songfinch_gift");
      this.sendFlow.addGiftAttached("custom-song", songfinchResponse);
      if (this.sendFlow.order.current_step == "personalize") {
        this.sendFlow.forms.get("gift-options-form").fields.get("custom-song").setValue(true, true);
        window.app_images?.loadImages(window.app_dialogs.collection.get("gift-options").el);
        this.sendFlow.hydrateGiftOptionsSubmit();
      }
      else {
        this.sendFlow.forms.get("email-send").fields.get("custom-song").setValue(true, true);
        this.sendFlow.hydrateDeliveryDetails();
      }
      this.client.emit("report-record", "selectedsong");
      this.sendFlow.saveFlow();
    });

    this.client.on("report-updated", (reportingString) => {
      if (!reportingString || reportingString === this.reportingString) return
      this.beaconQueued = false;
      this.reportingString = reportingString;
    })

    window.addEventListener("visibilitychange", () => {
      if (document.visibilityState === "hidden") {
        this.sendBeacon()
      }
    })
  }

  async appendReportingHistory() {
    const endpoint = new URL(`${app_env.apihost}/simple/miscusage`);
    endpoint.searchParams.append("client_id", app_env.client_id);
    endpoint.searchParams.append("customer_number", app_env.customer?.number || 0);
    endpoint.searchParams.append("nav_type", `send/${app_env.ecard_order.id || 1}`);
    endpoint.searchParams.append("product_number", app_env.product_config?.custom_song?.merch_number);
    try {
      const request = await fetch(endpoint);
      const response = await request.json();
      if (response?.item?.navigation) {
        const currentUsage = this.client.getUsage()
        this.client.setUsage({
          ...currentUsage,
          reporting: response.item.navigation + currentUsage.reporting
        })
      }
    } catch (error) {
      console.log(error)
    }
  }

  sendBeacon() {
    if (this.beaconQueued || this.reportingString === "") return
    const payload = {
      customer_number: app_env.customer?.number || 0,
      nav_type: `send/${app_env.ecard_order.id || 1}`,
      product_number: app_env.product_config?.custom_song?.merch_number,
      navigation: this.reportingString
    }

    this.beaconQueued = navigator.sendBeacon(
      `${app_env.apihost}/simple/miscusage?client_id=${app_env.client_id}`,
      new URLSearchParams(payload)
    )
  }

  getCustomSongConfig() {
    return {
      service: {
        clientId: app_env.client_id,
        baseUrl: app_env.apihost,
        collectionId: app_env.product_config?.custom_song?.collection_id || [],
        customerEmail: app_env.customer?.email || "",
        extraParams: {
          jwt_auth: app_env.product_config?.custom_song?.jwt_auth
        }
      },
      ui: {
        strings: {
          song_price: `$${app_env.checkout_price}`,
        }
      },
      options: {
        reporting: true,
      }
    }
  }
}

export default CustomSongClient;