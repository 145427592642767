import Utility from "../../base/Utility";
import Flow from "./Flow";
import Client from "../../base/Client";
import SmashupPreview from "../product/preview/SmashupPreview";
import EcardPreview from "../product/preview/EcardPreview";
import InteractivePreview from "../product/preview/InteractivePreview";
import Preview from "../product/preview/Preview";
import SelfieSmashupPreview from "../product/preview/SelfieSmashupPreview";
import TalkingSmashupPreview from "../product/preview/TalkingSmashupPreview";
import Render from "../../base/Render";
import Format from "../../base/Format";
import Heartbeat from "@common/modules/utilities/Heartbeat";
import Saver from "../form/Saver";
import Helpers from "../../base/Helpers";
import CustomSongClient from "../product/CustomSongClient";
import Spinner from "../Spinner";


class SendFlow extends Flow {

  constructor(el) {
    super(el);
    this.externalForms = ["delivery-method", "gift-options-form"];
    this.order = { ...window.app_env?.ecard_order } || null; //TODO: This is not working
    this.controllerType = 'fallback';
    this.isCompleted = this.order.is_completed;
    this.isRecovering = false;
    this.purchasedGift = false;
    this.cashGiftModule = null;
    this.cashGiftSettings = window.app_env?.cash_gift_config;
    this.cashGiftSpinner = null;
    this.cashGiftCompleted = false;
    this.cashGiftTotal = 0;
    this.cashGiftFailures = [];
    this.shareGiftOptionDeclined = false;
    this.supportsNativeShare = Boolean(window.navigator.share) && Client.isHandheldDevice();
    this.isResend = false;
    this.snapshot = {
      order: this.order
    };
    this.previewControllerMap = {
      smashup: SmashupPreview,
      ecard: EcardPreview,
      interactive: InteractivePreview,
      'talking-smashup': TalkingSmashupPreview,
      'selfie-smashup': SelfieSmashupPreview,
      fallback: Preview,
    }
    this.saver = null;
    this.deliveryDetails = {
      "select_giftcard": false,
      "select_cash_gift": false,
      "select_custom-song": false,
      "scheduled": false,
    };
    this.preview = null;
    this.actionHandlerMap = {
      preview: this.triggerPreview.bind(this),
      edit: this.focusEditor.bind(this),
      share: this.shareHandler.bind(this),
      copy: this.copyHandler.bind(this),
      facebookShare: this.facebookShareHandler.bind(this),
    };
    this.previewRequestedController = null;
    this.previewRequested = false;
    this.successHandlerMap = {
      personalize: this.personalizeSuccessHandler.bind(this),
      "delivery-method": this.deliveryMethodSuccessHandler.bind(this),
      "email-send": this.emailSendSuccessHandler.bind(this),
      "select-gift-form": this.giftSuccessHandler.bind(this),
      "select-cash-gift-form": this.giftSuccessHandler.bind(this),
    };
    this.isCopyingLink = false;
    this.hasCashGiftSelected = this.order.personalizations.has_cash_gift_selected || false;
    this.giftOptions = window.app_env?.gift_options;
    this.customSongClient = null;
    this.initSendFlow();
  }

  focusEditor() {
    if (this.wizard.panels.has('personalize')) {
      this.setFlowStep("personalize", false);
      setTimeout(() => {
        this.forms.get('personalize')?.fields.get('pn1465')?.setFocus();
      }, 400);
    }
  }

  updateUrl() {
    if (window.location.pathname.includes("resend") && ["RFU", "REG"].includes(window.app_env?.customer.status) && !this.order.product.is_free) {
      let rfu_dialog = window.app_dialogs?.collection.get('rfu-resend');
      rfu_dialog.isBlocking = true;
      rfu_dialog.el.querySelector('.dialog__close').classList.add("hidden");
      window.app_dialogs.open('rfu-resend');
      return
    }
    if (window.location.pathname.includes("resend") && window.app_env?.customer.is_in_collections && !this.order.product.is_free) {
      let collections_dialog = window.app_dialogs?.collection.get('collections-dialog');
      collections_dialog.isBlocking = true;
      collections_dialog.el.querySelector('.dialog__close').classList.add("hidden");
      window.app_dialogs.activate('collections-dialog');
      return
    }
    if (window.location.pathname.includes("resend") && this.order && window.app_env?.customer) {
      let url = new URL(window.location.href);
      this.isResend = Boolean(this.getGift());
      url.pathname = `/${this.order.mode}/${this.order.id}${this.order.sender_id_fragment}`;
      window.history.replaceState({}, "unused", url.href);
    }
  }

  personalizeSuccessHandler(form) {
    if (form.content.hasOwnProperty("delivery_method")) {
      this.deliveryMethodSuccessHandler(form);
    } else {
      let wasKeyboardEvent = window.app_page?.wasKeyboardEvent;
      window.app_dialogs?.activate(
        "share-send",
        form.submitBtns[0],
        !wasKeyboardEvent
      );
    }
  }

  deliveryMethodSuccessHandler(form) {
    window.app_dialogs?.close("share-send");
    let map = {
      email: "send-email",
      copy: "confirm-share",
      facebook: "confirm-share",
    };
    this.updateFlow({ delivery_method: form.content.delivery_method });
    if (!form.lastRequest?.response?.data?.redirect_url) {
      setTimeout(() => {
        this.setFlowStep(map[form.content.delivery_method]);
      }, 200)
    }
  }

  emailSendSuccessHandler(form) {
    this.updateFlow();
    if (
      form.content.hasOwnProperty("giftcard") &&
      form.content.giftcard &&
      this.order.gift == null
    ) {
      this.setFlowStep("select-gift");
    }
    else if (
      form.content.hasOwnProperty("cash_gift") &&
      form.content.cash_gift &&
      this.order.gift == null
      ) {
        this.setFlowStep("select-cash-gift");
    }
    else {
      form.persistLoading = true;
      if (!form.lastRequest.response.data?.redirect_url) {
        this.setFlowStep("confirm-email");
      }
    }
  }

  renderIframeEcardFirst(form, panel, iframeHolder, jifitiListener, heartbeat, iframeString) {
    // Render iframe before panel activation
    panel.el.addEventListener("panel-before-activation", () => {
      jifitiListener?.abort();
      jifitiListener = new AbortController();
      iframeHolder.innerHTML = "";
      iframeHolder.appendChild(Render.fragmentFromString(iframeString));
      let iframe = iframeHolder.querySelector("iframe");
      let loader = iframeHolder.querySelector(".lazy-load-iframe__loader");
      if (!iframe) return
      iframe.setAttribute("src", this.order.giftcard_iframe);
      iframe.addEventListener("load", () => {
        setTimeout(() => {
          iframeHolder.classList.add("--loaded");
          setTimeout(() => {
            loader.remove();
          }, 300)
        }, 500);
      });
      // Listen for message event
      window.addEventListener('message', e => {
        if (!e.origin.includes("jifiti.com") || e.data?.event != "success") return
        panel.disableNavigation();
        heartbeat?.kill();
        heartbeat = new Heartbeat(30, 15);
        heartbeat.onSuccess = (data) => {
          form.el.submit();
        }
        heartbeat.onFail = (reason) => {
          form.addError("server", "Unable to connect to merchant, please try again later.");
          form.renderErrors();
          panel.enableNavigation();
        }
        heartbeat.start(`${app_env.shost}/gifts/transaction-started/${this.order.id}${this.order.sender_id_fragment}`, {
          method: "POST",
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Content-Type': 'application/json',
          }
        });
      }, { signal: jifitiListener.signal });
    });

    // Clear iframe and errors after deactivation
    panel.el.addEventListener("panel-deactivated", () => {
      jifitiListener?.abort();
      form.clearErrors(["server"]);
      heartbeat?.kill();
      iframeHolder.classList.remove("--loaded");
      iframeHolder.innerHTML = "";
    });
  }

  async renderIframeGiftCardFirst(form, panel, heartbeat, iframeHolder) {
    let loader = iframeHolder.querySelector(".lazy-load-iframe__loader");
    const searchParams = new URL(location.href).searchParams;
    await Utility.resourcesLoaded(
      "jifiti",
      [this.order.script_src],
      false,
      () => (window.GiftitSDK)
    );
    setTimeout(() => {
      iframeHolder.classList.add("--loaded");
      setTimeout(() => {
        loader.remove();
      }, 300)
    }, 500);
    let fullName;
    if (app_env.customer) {
      let firstName = app_env.customer.name;
      let lastName = app_env.customer.lname;
      fullName = firstName.concat(" ", lastName);
    }

    const giftitSDK = new GiftitSDK({
      element: {
        selector: '.jifiti-container__iframe',
        width: "100%",
        height: "725px",
      },
      partnerId: this.order.partner_id,
      token: this.order.callback_token,
      profileId: this.order.profile_id,
      ecard_id: this.order.id,
      gifter: {
        name: fullName,
        email: app_env.customer.email
      },
      sendDate: new Date(), // Today or some other date.
      store: searchParams.get("store"),
      onFinish(qrCodes) {
        panel.disableNavigation();
        heartbeat?.kill();
        heartbeat = new Heartbeat(30, 15);
        heartbeat.onSuccess = (data) => {
          form.el.submit();
        }
        heartbeat.onFail = (reason) => {
          form.addError("server", "Unable to connect to merchant, please try again later.");
          form.renderErrors();
          panel.enableNavigation();
        }
        heartbeat.start(`${app_env.shost}/gifts/transaction-started/${app_env.ecard_order.id}${app_env.ecard_order.sender_id_fragment}`, {
          method: "POST",
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'X-Csrftoken': Client.getCSRFToken(),
          }
        });
      }
    })
  }

  initGiftPanel() {
    let form = this.forms.get("select-gift-form");
    let panel = this.wizard.panels.get("select-gift");
    let iframeHolder = panel?.el.querySelector("#jifiti-merchant");
    if (!panel || !form || !iframeHolder) return
    let jifitiListener = new AbortController();

    let heartbeat = null;
    let iframeString = iframeHolder.innerHTML || "";
    if (this.order.mode == 'send' || this.order.mode == 'edit') {
      this.renderIframeEcardFirst(form, panel, iframeHolder, jifitiListener, heartbeat, iframeString);
    } else {
      this.renderIframeGiftCardFirst(form, panel, heartbeat, iframeHolder);
    }
  }

  giftSuccessHandler() {
    this.purchasedGift = true;
    window.app_services?.track("send-gift-attached", this.order);
    if (this.order.gift?.name == "Cash Gift") {
      // don't proceed to confirmation page automatically
      // for cash gift since customer must click the "done"
      // button within the birdie interface first.
      this.cashGiftCompleted = true;
    } else {
      this.showConfirmPage();
    }
  }

  initCashGiftPanel() {
    let form = this.forms.get("select-cash-gift-form");
    let panel = this.wizard.panels.get("select-cash-gift");
    let container = panel?.el.querySelector("#cash-gift-container");
    if (!panel || !form || !container) return
    let cash_amount_element = panel.el.querySelector(
      "#cash-amount-form #cash-amount"
    );
    cash_amount_element.addEventListener(
      "keypress", this.handleCashAmountKeyPress.bind(this)
    );
    cash_amount_element.addEventListener(
      "input", this.handleCashAmountChange.bind(this)
    );
    panel.el.querySelectorAll("#cash-amount-form .cash-amount-option").forEach(
      (element) => {
        element.addEventListener(
          "click", this.handleCashAmountOptionChange.bind(this)
        );
      }
    );
    let cash_gift_phone_element = panel.el.querySelector(
      "#cash-amount-form #cash-gift-recipient-phone-number"
    );
    cash_gift_phone_element.addEventListener(
      "keypress", this.handleCashGiftRecipPhoneKeyPress.bind(this)
    );
  }

  handleCashAmountKeyPress(event) {
    let key_code = event.which ? event.which : event.keyCode;
    if (key_code > 31 && (key_code < 48 || key_code > 57)) {
      event.preventDefault();
    }
  }

  handleCashAmountChange(event) {
    let value = parseInt(event.target.value);
    let new_value = null;
    if (isNaN(value)) {
      new_value = ""
    } else if (value < 1) {
      new_value = 1;
    } else if (value > 2000) {
      new_value = 2000;
    } else {
      new_value = value;
    }
    const line_item = event.target.closest("li");
    event.target.value = new_value;
    if (event.target.name == "cash_amount") {
      Utility.updateContent({
        cash_gift_image_amount: "$" + new_value
      }
      );
    } else {
      this.displayCashGiftTotal();
    }
    this.updateCashAmountOptions(line_item, new_value);
  }

  handleCashAmountOptionChange(event) {
    let cash_amount;
    if (event.target.classList.contains("--selected")) {
      cash_amount = "";
    } else {
      cash_amount = parseInt(
        event.target.getAttribute("data-cash-amount")
      );
    }
    const line_item = event.target.closest("li");
    let cash_amount_element = line_item.querySelector(
      `input[name^="cash_amount"]`
    );
    cash_amount_element.value = cash_amount;
    cash_amount_element.dispatchEvent(new Event("input"))
    if (cash_amount_element.name == "cash_amount") {
      Utility.updateContent({
        cash_gift_image_amount: "$" + cash_amount
      }
      );
    } else {
      this.displayCashGiftTotal();
    }
    this.updateCashAmountOptions(line_item, cash_amount);
  }

  updateCashAmountOptions(line_item, current_cash_amount) {
    line_item.querySelectorAll(".cash-amount-option").forEach(
      (element) => {
        let cash_amount = parseInt(
          element.getAttribute("data-cash-amount")
        );
        if (cash_amount == current_cash_amount) {
          element.classList.add("--selected");
        } else {
          element.classList.remove("--selected");
        }
      }
    );
  }

  handleCashGiftRecipPhoneKeyPress(event) {
    let key_code = event.which ? event.which : event.keyCode;
    if (
      key_code > 31 &&
      (key_code < 48 || (key_code > 57 && key_code != 189))
    ) {
      event.preventDefault();
    }
  }

  initGiftOptionsDialog() {
    let form = this.forms.get("gift-options-form");
    let sendForm = this.forms.get("email-send");
    if (!form || !form.fields.size) return
    this.giftOptions.forEach((giftOption) => {
      if (giftOption.can_be_shared && giftOption.keyword != "custom-song") {
        let addGiftBtn = form.el.querySelector(`button[data-controls='add-${giftOption.keyword}']`);
        addGiftBtn?.addEventListener("click", () => {
          form.fields.get(giftOption.keyword).setValue(true, true);
          this.addGiftAttached(giftOption.keyword);
        });
        let removeGiftBtn = form.el.querySelector(`button[data-controls='remove-${giftOption.keyword}']`);
        removeGiftBtn?.addEventListener("click", () => {
          form.fields.get(giftOption.keyword).setValue(false, true);
          this.removeGiftAttached();
        });
      }
    });
    this.hydrateGiftOptionsSubmit();

    form.fields.forEach(
      (field) => {
        field.onChange = (input) => {
          this.deliveryDetails[
            `select_${input.name}`
          ] = input.checked;
          if(sendForm?.fields?.get(input.name)) {
            sendForm.fields.get(input.name).setValue(input.checked, false);
          }
          if (input.checked) {
            form.fields.forEach(
              (secondaryInput) => {
                if (secondaryInput.name != input.name) {
                  secondaryInput.setValue(false, true);
                  if(sendForm?.fields?.get(secondaryInput.name)) {
                    sendForm.fields.get(secondaryInput.name).setValue(false, false);
                  }
                  this.deliveryDetails[
                    `select_${secondaryInput.name}`
                  ] = false;
                }
              }
            );
          }
          this.hydrateGiftOptionsSubmit();
        };
      }
    );
  }

  hydrateGiftOptionsSubmit() {
    let form = this.forms.get("gift-options-form");
    let isGiftOptionSelected = false;
    let shareCustomSong = this.deliveryDetails["select_custom-song"];
    form.fields.forEach(
      (field) => {
        if (field.el.checked) {
          isGiftOptionSelected = true;
        }
      }
    );
    const getGiftOptionsSubmitLabel = () => {
      let action = this.isResend ? "Resend" : "Share";
      if (isGiftOptionSelected && !shareCustomSong) {
        return `Add Gift & ${action}`
      } else if (shareCustomSong) {
        return `Checkout & ${action}`
      } else {
        return `${action} without gift`
      }
    }

    Utility.updateContent({
      share_greeting_submit_label: getGiftOptionsSubmitLabel(),
      share_greeting_submit: {
        addClass: isGiftOptionSelected && !shareCustomSong ? "--has-gift" : "",
        removeClass: !(isGiftOptionSelected && !shareCustomSong) ? "--has-gift" : "",
        "aria-label": getGiftOptionsSubmitLabel(),
      },
    });

    this.giftOptions.forEach((giftOption) => {
      if (giftOption.can_be_shared && this.deliveryDetails[`select_${giftOption.keyword}`] && !shareCustomSong) {
        this.addGiftAttached(giftOption.keyword, this.order.personalizations.gift);
      }
    });

    if (!isGiftOptionSelected) {
      this.removeGiftAttached();
    }
  }

  async savePendingCashGiftAmounts() {
    let cash_amounts = [];
    let form_name = `cash-amount${this.order.recipients.length > 1 ? "s" : ""}-form`;
    let form = this.forms.get(form_name);
    if (this.order.delivery_method == "copy") {
      let cash_amount_element = this.el.querySelector(
        `#${form_name} #cash-amount`
      );
      let cash_amount = parseFloat(cash_amount_element.value);
      cash_amounts.push(cash_amount);
    } else {
      this.order.recipients.forEach(
        (recipient, index) => {
          let amount_selector;
          if (this.order.recipients.length > 1) {
            amount_selector = `#${form_name} [name="cash_amount_${index}"]`
          } else {
            amount_selector = `#${form_name} [name="cash_amount"]`
          }
          let cash_amount_element = this.el.querySelector(
            amount_selector
          );
          let cash_amount = parseFloat(cash_amount_element.value);
          cash_amounts.push(cash_amount);
        }
      );
    }
    let data = {
      ecard_id: this.order.id,
      cash_amounts: cash_amounts,
    };
    let response = await fetch(
      "/api/gifts/save-pending-cash-gift-amounts", {
      method: "POST",
      body: JSON.stringify(data)
    });
    const response_json = await response.json();
    if (response_json.status == "success") {
      form.complete("custom");
      this.show_birdie_interface();
    }
  }

  show_birdie_interface() {
    let birdie_recipients = [];
    let external_id = `${this.order.id}${this.order.sender_id_fragment}`;
    if (this.order.delivery_method == "copy") {
      let cash_amount_element = this.el.querySelector(
        "#cash-amount-form #cash-amount"
      );
      let cash_amount = parseFloat(cash_amount_element.value) * 100;
      let recip_phone_element = this.el.querySelector(
        "#cash-amount-form #cash-gift-recipient-phone-number"
      );
      let recip_phone = recip_phone_element.value.replace(/\-/g, "");
      birdie_recipients.push({
        phone: "+1" + recip_phone,
        giftAmount: cash_amount,
        externalId: external_id,
      });
    } else {
      this.order.recipients.forEach(
        (recipient, index) => {
          let amount_selector;
          if (this.order.recipients.length > 1) {
            amount_selector = `#cash-amounts-form [name="cash_amount_${index}"]`
          } else {
            amount_selector = `#cash-amount-form [name="cash_amount"]`
          }
          let cash_amount_element = this.el.querySelector(
            amount_selector
          );
          let cash_amount = parseFloat(cash_amount_element.value) * 100;
          birdie_recipients.push({
            email: recipient.email,
            giftAmount: cash_amount,
            externalId: external_id,
          });
        }
      );
    }
    let birdie_user = {
      email: window.app_env.customer.email,
      firstName: window.app_env.customer.name,
      lastName: window.app_env.customer.lname
    };
    let prefilled_data = {
      recipients: birdie_recipients,
      user: birdie_user
    };
    if (this.cashGiftModule) {
      this.cashGiftModule.close();
    }
    this.cashGiftModule = BirdieGifting({
      clientId: this.cashGiftSettings.birdie_client_id,
      themeKey: "agi",
      prefilledData: prefilled_data,
      onGiftSend: (redemptionInfo) => {
        this.saveCashGiftRedemptionInfo(redemptionInfo);
      },
      onError: () => { },
      onClose: () => { this.handleCashGiftInterfaceDoneClicked(); },
      onCancel: () => { this.setFlowStep("select-cash-gift"); },
      onLoad: () => { },
    });
    this.el.querySelector("#cash-amount-container").style.display = "none";
    this.el.querySelector("#cash-amounts-container").style.display = "none";
    this.cashGiftModule.render("#cash-gift-container");
    this.el.querySelector("#cash-gift-container").style.display = "";
  }

  handleCashGiftInterfaceDoneClicked() {
    if (this.cashGiftCompleted) {
      this.showConfirmPage();
    } else if (this.cashGiftTotal) {
      this.showCashGiftSpinner();
    } else {
      this.setFlowStep("select-cash-gift");
    }
  }

  showCashGiftSpinner() {
    let totalValidRecpients = (
      (this.order.recipients.length || 1) - this.cashGiftFailures.length
    );
    let title = `Finalizing your cash gift${totalValidRecpients > 1 ? "s" : ""}...`;
    let imageConfig = {
      image_icon_class: "hidden",
      image_src: "",
      image_alt: "",
      image_width: "10",
      image_height: "10",
    };
    this.cashGiftSpinner = new Spinner({
      image: "",
      trigger: null,
      spinner_title: title,
      status: { title },
      ...imageConfig
    }, true);

    this.cashGiftSpinner.dialog.isBlocking = true;
    this.cashGiftSpinner.update(
      { spinner__close: { addClass: "hidden" } }
    );
    this.cashGiftProcessingInterval = setInterval(() => {
      if (this.cashGiftCompleted) {
        this.cashGiftSpinner.completed();
        this.cashGiftSpinner.dialog.isBlocking = false;
        this.cashGiftSpinner.dialog.close();
        this.showConfirmPage();
        clearInterval(this.cashGiftProcessingInterval);
        return;
      }
    }, Utility.getRandomInt(100, 1000));
  }

  showConfirmPage() {
    this.setFlowStep(
      `confirm-${this.order.delivery_method == "copy" ? "share" : "email"}`
    );
  }

  setFlowStep(key, focus = true) {
    if (key == "confirm-email") {
      this.sentHandler();
      this.hydrateConfirmEmail();
    } else if (key == "confirm-share") {
      this.sentHandler();
      this.hydrateConfirmShare();
    } else if (key == "select-cash-gift") {
      if (this.order.recipients.length < 2) {
        this.hydrateSelectSingleCashGift();
      } else {
        this.hydrateSelectMultipleCashGifts();
      }
    } else if (key == "send-email") {
      this.hydrateDeliveryDetails();
    }

    if (key != this.wizard.activePanel?.name) {
      window.app_services?.tealium.view({
        page_type: "sendflow",
        page_sub_type: key,
      });
    }

    this.wizard?.step(key, focus);
    window.scrollTo({
      top: 0,
      behavior: "instant",
    })
  }

  hydrateSelectSingleCashGift() {
    this.wizard.panels.get("select-gift")?.disable();
    if (this.order.delivery_method == "copy") {
      window.app_dialogs.close("gift-options");
      window.app_dialogs.close("share-send");
      this.wizard.panels.get("send-email")?.disable();
      this.el.querySelector(
        "#share-phone-number-container"
      ).style.display = "";
    } else {
      this.el.querySelector(
        "#share-phone-number-container"
      ).style.display = "none";
    }
    this.el.querySelector("#cash-gift-container").style.display = "none";
    this.el.querySelector("#cash-amounts-container").style.display = "none";
    this.el.querySelector("#cash-amount-container").style.display = "";
    this.wizard.panels.get("select-cash-gift").enable();
  }

  displayCashGiftTotal() {
    let total = 0.00;
    this.el.querySelectorAll(`input[id^="cash_amount"]`).forEach(
      (cash_amount_element) => {
        let cash_amount = parseFloat(cash_amount_element.value);
        if (!isNaN(cash_amount)) {
          total += cash_amount;
        }
      }
    );
    this.el.querySelector("#total-gift-amt").innerHTML = "$" + total.toFixed(0);
  }

  hydrateSelectMultipleCashGifts() {
    this.wizard.panels.get("select-gift").disable();
    this.el.querySelector(
      "#share-phone-number-container"
    ).style.display = "none";

    let cash_recipients_content = "";
    this.order.recipients.forEach(
      (recipient, index) => {
        const template = this.el.querySelector(
          ".recip-cash-amount-template"
        );
        const template_vars = {
          email: recipient.email,
          name: recipient.name,
          index: index
        };
        cash_recipients_content += Render.interpolateTemplate(
          template, template_vars, true
        );
      }
    );
    Utility.updateContent({
      "recip-cash-amounts": cash_recipients_content
    });
    let form = this.forms.get("cash-amounts-form");
    form.initFields();
    form.el.querySelectorAll(`input[id^="cash_amount"]`).forEach(
      (cash_amount_element) => {
        cash_amount_element.addEventListener(
          "keypress", this.handleCashAmountKeyPress.bind(this)
        );
        cash_amount_element.addEventListener(
          "input", this.handleCashAmountChange.bind(this)
        );
      }
    );
    form.el.querySelectorAll(".cash-amount-option").forEach(
      (element) => {
        element.addEventListener(
          "click", this.handleCashAmountOptionChange.bind(this)
        );
      }
    );
    this.el.querySelector("#cash-gift-container").style.display = "none";
    this.el.querySelector("#cash-amount-container").style.display = "none";
    this.el.querySelector("#cash-amounts-container").style.display = "";
    this.wizard.panels.get("select-cash-gift").enable();
  }

  async saveCashGiftRedemptionInfo(redemption_info) {
    try {
      let redeem_urls = [];
      let partner_order_ids = [];
      let product_prices = [];
      let service_fees = [];
      let card_fees = [];

      this.cashGiftTotal = 0;
      this.cashGiftFailures = [];
      this.cashGiftCompleted = false;

      redemption_info.forEach(
        (item) => {
          if (item.transactionStatus == "Failed") {
            this.cashGiftFailures.push(item);
            return;
          }
          let redeem_url_item = {
            redeem_url: item.accessUrl
          };
          if (this.order.delivery_method == "copy") {
            redeem_url_item.phone = item.phone.replace("+1", "");
          } else {
            redeem_url_item.email = item.email;
          }
          redeem_urls.push(redeem_url_item);
          partner_order_ids.push(item.identifier);
          product_prices.push(
            item.giftAmount.replace("$", "")
          );
          service_fees.push(item.platformFee);
          card_fees.push(item.cardFee);
        }
      );
      product_prices.map(
        (gift_amount) => {
          this.cashGiftTotal += parseInt(gift_amount);
        }
      );
      if (!redeem_urls.length) {
        // we don't have any successful redemptions
        return;
      } else {
        this.wizard.panels.get("select-cash-gift").disableNavigation();
      }
      let data = {
        ecard_id: this.order.id,
        product_title: "Cash Gift",
        product_retailer_name: "Birdie",
        partner_order_ids: partner_order_ids,
        product_image: "https://images.contentstack.io/v3/assets/bltcbd0de6834ffd7a9/blt8d53f00743823e32/cash-gift-red.png",
        product_prices: product_prices,
        service_fees: service_fees,
        card_fees: card_fees,
        redeem_urls: redeem_urls,
        status: "success"
      };
      let response = await fetch(
        "/api/gifts/complete-cash-gift-transaction", {
        method: "POST",
        body: JSON.stringify(data)
      });
      if (response.status == 200) {
        let select_cash_gift_form = this.wizard.panels.get("select-cash-gift").form;
        select_cash_gift_form.fields.get("delivery_method").setValue(
          this.order.delivery_method
        );
        select_cash_gift_form.submit();
      }
    } catch (error) {
      alert("error saving cash gift redemption info");
    }
  }

  pruneFailedCashGiftRecipients() {
    if (this.cashGiftFailures.length) {
      let recipientsTable = window.app_tables.collection.get("recipients");
      // we have at least 1 failed redemption
      // for the multi-recipient email flow
      // so let's prune the bad recipients(s)
      let failed_emails = this.cashGiftFailures.map(
        (item) => { return item.email; }
      );
      let prune_filter = (item) => {
        return !failed_emails.includes(item.email);
      }
      this.order.recipients = this.order.recipients.filter(prune_filter);
      recipientsTable.data = recipientsTable.data.filter(prune_filter);
      recipientsTable.entries = recipientsTable.entries.filter(prune_filter);
      recipientsTable.renderItems(recipientsTable.entries);
    }
  }

  sentHandler() {
    if (this.order.addressbook_updated) {
      window.app_tables?.invalidateCache()
    }
    if (this.order.mode == "edit") {
      window.history.replaceState({}, 'unused', window.location.href.replace("edit", "send"));
    }
    if (this.order.mode == "reply") {
      window.app_storage?.remove("ag-reply-token", "session");
    }
    if (this.order.mode != "edit" || this.purchasedGift) {
      window.app_services?.track("send-completed", this.order);
    }
    window.app_storage?.remove(`ag-pdp-${this.order.product.product_number}`, "session");
    this.clearFlow();
  }

  initSendFlow() {
    this.controllerType = this.order.product.model;
    if (app_env.product_config?.custom_song && this.order.product.model != "creatacard"
      && this.order.mode != "edit" && !(["confirm-email", "confirm-share"].includes(this.order.current_step))) {
      this.customSongClient = new CustomSongClient(this.el, this);
    }
    this.initSendEmailForm();
    this.handleSentFromCheckout();
    this.initExternalForms();
    this.manageNativeShare();
    this.manageMembershipStart();
    if (this.order.current_step == "confirm-email") {
      this.hydrateConfirmEmail();
    } else if (this.order.current_step == "confirm-share") {
      this.hydrateConfirmShare();
    }
    if (this.hasCashGiftSelected) {
      this.initGiftOptionsForCac();
    }
    this.initPreview();
    this.initGiftPanel();
    this.initCashGiftPanel();
    if (!this.hasCashGiftSelected) {
      this.initGiftOptionsDialog();
    }
    this.forms.forEach((form) => {
      form.requestJSON = true;
      form.filterGetter = (data) => this.filterGetterHandler(data, form);
      if (form.el.id == "delivery-method") {
        form.onSubmit = () => {
          form.getFormData(true);
          if (
            form.content.hasOwnProperty("delivery_method") &&
            form.content.delivery_method == "email"
          ) {
            form.complete("custom");
            this.deliveryMethodSuccessHandler(form);
            return false;
          } else if (
            form.content.hasOwnProperty("delivery_method") &&
            form.content.delivery_method == "copy" &&
            !this.shareGiftOptionDeclined &&
            window.app_dialogs.collection.get("gift-options")
          ) {
            form.complete("custom");
            this.order.delivery_method = "copy";
            this.order.recipients = [];
            window.app_dialogs.open("gift-options");
            this.hydrateGiftOptionsSubmit();
            return false;
          } else {
            return true;
          }
        };
      }
      if (form.el.id == "personalize") {
        form.onSubmit = () => {
          form.getFormData(true);
          if (
            (form.content.hasOwnProperty("pn1465") &&
              form.content.pn1465 == "") ||
            (this.order.personalizations?.Msg &&
              this.order.personalizations.Msg == form.content.pn1465) ||
              this.order.features_used?.includes("used_songfinch_gift")
          ) {
            form.complete("custom");
            this.personalizeSuccessHandler(form);
            this.updateFlow({
              personalizations: {
                ...this.order.personalizations,
                Msg: form.content.pn1465,
              },
            });
            return false;
          } else {
            return true;
          }
        };
      }
      if (form.el.id == "gift-options-form") {
        form.onSubmit = () => {
          form.getFormData(true);
          if (this.deliveryDetails.select_cash_gift) {
            form.complete("custom");
            this.setFlowStep("select-cash-gift");
          } else {
            window.app_dialogs.close("gift-options");
            this.shareGiftOptionDeclined = true;
            this.forms.get("delivery-method").el.querySelector(
              `button[name="delivery_method"][value="copy"]`
            ).click();
          }
          return false;
        };
      }
      if (["cash-amount-form", "cash-amounts-form"].includes(form.el.id)) {
        form.onBeforeSubmit = () => {
          form.removeErrors();
          form.fields.forEach(
            (field) => {
              field.rules = new Map();
            }
          );
        };
        form.onSubmit = () => {
          form.getFormData(true);
          let field_errors = {};
          if (form.el.id == "cash-amount-form") {
            let cash_amount = form.content.cash_amount;
            if (cash_amount.length == 0) {
              field_errors.cash_amount = "Amount is required";
            }
          } else {
            form.fields.forEach(
              (field) => {
                let cash_amount = form.content[field.name];
                if (cash_amount.length == 0) {
                  field_errors[field.name] = "Enter amount";
                }
              }
            );
          }
          if (this.order.delivery_method == "copy") {
            let recip_phone = form.content.recip_phone
            recip_phone = recip_phone.replace(/\-/g, "");
            if (recip_phone.length == 0) {
              field_errors["recip_phone"] = "Phone number is required";
            } else if (recip_phone.length != 10) {
              field_errors["recip_phone"] = "The phone number you entered is not a valid US phone number. Please enter a number in the form XXX-XXX-XXXX";
            }
          }
          if (Object.keys(field_errors).length) {
            form.appendErrorsToFields(field_errors);
            if (form.el.id == "cash-amounts-form") {
              form.addError("server", "Enter an amount for each recipient.");
            }
            form.complete("external-validation-error");
          } else {
            this.savePendingCashGiftAmounts();
          }
          return false;
        };
      }
      if (this.successHandlerMap.hasOwnProperty(form.el.id)) {
        form.onSuccess = () => {
          this.updateFlow(form.lastRequest.response?.data);
          this.successHandlerMap[form.el.id](form);
          return true;
        };
      }
    });
    window.app_listeners?.add(
      "click",
      `flow-${this.el.id}-click`,
      this.clickHandler.bind(this)
    );

    this.updateUrl();
    this.initSaver();

    // show the "share or send" dialog window if customer
    // is coming from a successful join with a usage ID
    if (window.app_env?.is_from_join_confirm) {
      let personalize_form = this.forms.get("personalize");
      if (personalize_form && !personalize_form.submitDisabled) {
        setTimeout(
          () => { personalize_form.el.submit(); }, 300
        );
      }
    }

    // show the "share or send" dialog window if customer
    // is coming from a successful join with a usage ID
    if (window.app_env?.is_from_join_confirm) {
      let personalize_form = this.forms.get("personalize");
      if (personalize_form && !personalize_form.submitDisabled) {
        setTimeout(
          () => { personalize_form.el.submit(); }, 300
        );
      }
    }
  }

  handleSentFromCheckout() {
    if (!this.order.current_step.includes("confirm")) return
    const url = new URL(window.location.href);
    if (url.searchParams.has("track_send")) {
      if (url.searchParams.has("addressbook_updated")) {
        this.order.addressbook_updated = true;
        url.searchParams.delete("addressbook_updated");
      }
      url.searchParams.delete("track_send");
      window.history.replaceState({}, "unused", url.href);
      this.sentHandler()
    }
  }

  initGiftOptionsForCac() {
    this.order.delivery_method = "copy";
    this.deliveryDetails.select_cash_gift = true;
    this.el.querySelector(
      "#share-phone-number-container"
    ).style.display = "";
    this.el.querySelector("#cash-gift-container").style.display = "none";
    this.el.querySelector("#cash-amounts-container").style.display = "none";
    this.el.querySelector("#cash-amount-container").style.display = "";
    this.wizard.panels.get("select-cash-gift")?.enable();
  }

  initSaver() {
    ["session", "local", "page", "cookie"].forEach((type) => {
      if (this.el.hasAttribute(`data-save-${type}`)) {
        let key = this.el.getAttribute(`data-save-${type}`) || `gs-${type}`;
        this.saver = new Saver(key, type, null, this.el.getAttribute("data-save-exclusive"));
        this.loadFlow();
      }
    });
    if (this.saver) {
      if (!this.saver?.load() && !this.isCompleted) {
        // Initial save if nothin was saved to remain in session storage
        window.app_services?.track("send-started", this.order);
        this.saveFlow();
      }
      window.app_forms?.collection.forEach(form => {
        if (!form.el.closest(`#${this.el.id}`)) return
        form.el.addEventListener("change", () => {
          if (this.isRecovering) return
          let formData = form.getFormData(true, true);
          this.saveFlow("form", form.id, formData);
        })
      })
      window.app_tables?.collection.forEach(table => {
        if (!table.el.closest(`#${this.el.id}`)) return
        table.el.addEventListener("change", () => {
          if (this.isRecovering) return
          this.saveFlow("table", table.el.id, table.entries);
        })
      })
      window.app_tabs?.collection.forEach(tabs => {
        if (!tabs.el.closest(`#${this.el.id}`)) return
        tabs.el.addEventListener("change", () => {
          if (this.isRecovering) return
          this.saveFlow("tabs", tabs.el.id, { tab: tabs.activeKey });
        })
      })
      this.wizard.onPanelChange = () => {
        this.order.current_step = this.wizard.activePanel.name;
        if (this.isRecovering) return
        this.saveFlow();
      }
    }
  }

  recovering(isRecovering = true) {
    this.isRecovering = isRecovering;
    if (isRecovering) {
      this.el.classList.add("--recovering");
    } else {
      this.el.classList.add("--recovered");
      setTimeout(() => {
        this.el.classList.remove("--recovering");
      }, 300)
    }
  }

  loadFlow() {
    let recoveredData = this.saver.load();
    if (!recoveredData) return
    if (["confirm-email", "confirm-share"].includes(recoveredData.order.step)) {
      this.clearFlow();
      return
    }
    if (!this.order.steps.includes(recoveredData.order.current_step)) {
      this.clearFlow();
      return
    }
    let silentRecover = false;
    if (Object.keys(recoveredData).length == 1
      && recoveredData.order
      && recoveredData.order.current_step == this.order.current_step) {
      silentRecover = true;
    }
    !silentRecover && this.recovering();
    this.snapshot = recoveredData;
    this.isResend = Boolean(recoveredData.isResend);
    setTimeout(() => {
      if (recoveredData.order) {
        this.order = recoveredData.order;
      }
      if (recoveredData.tabs) {
        for (let key in recoveredData.tabs) {
          window.app_tabs?.collection.get(key)?.activate(recoveredData.tabs[key]["tab"], true)
        }
      }
      if (recoveredData.forms) {
        for (let key in recoveredData.forms) {
          window.app_forms?.collection.get(key)?.fill(recoveredData.forms[key])
        }
      }
      if (recoveredData.tables) {
        for (let key in recoveredData.tables) {
          let table = window.app_tables?.collection.get(key);
          if (table) {
            table.entries = recoveredData.tables[key];
            table.data = recoveredData.tables[key];
            table.dataReady();
          }
        }
      }
      if (this.order.steps.includes("checkout") && window.app_env?.checkout == "pps") {
        this.order.steps = window.app_env.ecard_order.steps;
        this.order.order_type = window.app_env.ecard_order.order_type;
      }
      // in case customer signs in and already had an active
      // usage record, then we make sure to override the
      // prior sender information found in session storage
      if (this.order.sender_id_fragment != window.app_env.ecard_order.sender_id_fragment) {
        this.order.sender_id_fragment = window.app_env.ecard_order.sender_id_fragment;
        this.order.sender = window.app_env.ecard_order.sender;
      }
      this.setFlowStep(this.order.current_step);
    }, 300);
    // Here add handling for populating the flow with recovered personalisations
    if (!silentRecover) {
      setTimeout(() => {
        this.recovering(false);
      }, 1000)
    }
  }

  saveFlow(saveType = "order", key = null, dataToSave = {}) {
    if (!this.saver || this.isRecovering) return
    if (["personalize-cac"].includes(this.order.current_step)) return
    if (["confirm-email", "confirm-share"].includes(this.order.current_step)) {
      this.clearFlow();
      return
    }
    if (saveType == "order") {
      this.snapshot["order"] = this.order;
      this.snapshot["isResend"] = this.isResend;
    } else if (saveType == "table") {
      if (!this.snapshot.tables) {
        this.snapshot["tables"] = {}
      }
      this.snapshot["tables"][key] = dataToSave;
    } else if (saveType == "form") {
      if (!this.snapshot.forms) {
        this.snapshot["forms"] = {}
      }
      this.snapshot["forms"][key] = dataToSave;
    } else if (saveType == "tabs") {
      if (!this.snapshot.tabs) {
        this.snapshot["tabs"] = {}
      }
      this.snapshot["tabs"][key] = dataToSave;
    }
    this.saver.save(this.snapshot, this.el.getAttribute("data-save-clear-regex") || null);
  }

  clearFlow() {
    this.saver?.clear();
  }

  initPreview() {
    let previewEl = document.querySelector('.product-preview');
    if (previewEl) {
      if (this.previewControllerMap.hasOwnProperty(this.controllerType)) {
        this.preview = new this.previewControllerMap[this.controllerType](previewEl, this.order.product, this.order.personalizations);
      } else {
        this.preview = new this.previewControllerMap.fallback(previewEl, this.order.product, this.order.personalizations);
      }
    }
  }

  manageMembershipStart() {
    document.addEventListener("app-customer-membership-started", e => {
      let scheduleTime = this.order.steps.includes("checkout") ? "30 days" : "a year";
      Utility.updateContent({
        "send-email-description": `Add recipient(s) for your greeting, customize your name, send now or schedule up to ${scheduleTime} in advance.`
      }, this.el)
    })
  }

  clickHandler(e) {
    let target = e.real_target || e.target;
    let key = target?.getAttribute("data-flow-action") || null;
    if (e.isTrusted) {
      this.previewRequestedController?.abort();
      this.el.querySelectorAll('button[data-flow-action="preview"]').forEach(el => el.classList.remove('--loading'));
    }
    if (key && this.actionHandlerMap.hasOwnProperty(key)) {
      this.actionHandlerMap[key](e);
    }
  }

  triggerPreview(e) {

    if (this.order.product.model == "creatacard") {
      e.preventDefault();
      this.el.querySelector(".ub-preview-button")?.click();
      return
    }

    let form = this.forms.get("personalize");
    this.previewRequestedController = new AbortController();
    e.target.classList.add('--loading');
    if (form && !form.submitDisabled) {
      const frontendValid = form.validate();
      if (frontendValid) {
        form.syncErrors();
        form.renderErrors();
        this.preview.setMessage(form.fields.get('pn1465').getValue());
        if (this.preview.isReady) {
          this.openPreview(e);
        } else {
          this.preview.el.addEventListener('preview-ready', () => {
            this.openPreview(e);
          }, { signal: this.previewRequestedController.signal })
        }
      } else {
        this.el.querySelectorAll('button[data-flow-action="preview"]').forEach(el => el.classList.remove('--loading'));
        form.complete("error");
      }
    } else {
      if (this.preview.isReady) {
        this.openPreview(e);
      } else {
        this.preview.el.addEventListener('preview-ready', () => {
          this.openPreview(e);
        }, { signal: this.previewRequestedController.signal })
      }
    }
  }

  openPreview(e) {
    this.el.querySelectorAll('button[data-flow-action="preview"]').forEach(el => { el.classList.remove('--loading') });
    // Show edit/add message cta only for personalize and send-email panels of the flow.
    let canEdit = ["send-email", "personalize"].includes(this.wizard.activePanel.name);
    Utility.updateContent({
      "edit-trigger": {
        addClass: canEdit ? "" : "hidden",
        removeClass: canEdit ? "hidden" : ""
      }
    })
    window.app_dialogs?.activate("send-preview", e?.target);
  }

  updateFlow(updatedOrder) {
    this.order = { ...this.order, ...updatedOrder };
    this.saveFlow();
  }

  filterGetterHandler(data, form) {
    this.isCompleted = false;
    this.order.gift = this.getGift();
    let panel = form.el.closest(".wizard-panel");
    let step = panel?.getAttribute("data-name") || form.el.id;
    let product_number = Number(this.order?.product?.product_number) || null;
    let update = { step, product_number };
    if (this.order.product.model != "creatacard") {
      let pn1595 = this.order?.personalizations?.NETA || null;
      if (pn1595) {
        update["pn1595"] = pn1595;
      }
      let LobsterId = this.order?.personalizations?.LobsterId || null;
      if (LobsterId) {
        update["pn1595"] = encodeURIComponent(JSON.stringify({ LobsterId }));
      }
    }
    let chatGPT = this.order?.personalizations?.chatGPT || null;
    if (chatGPT) {
      update["generate_message"] = chatGPT;
    }
    let pn1465 = this.order?.personalizations?.Msg || null;
    if (pn1465 && step != "personalize") {
      update["pn1465"] = pn1465;
    }

    if (
      ["delivery-method", "personalize-cac"].includes(step) &&
      ["copy", "facebook", "print"].includes(data.delivery_method)
    ) {
      update['send'] = true;
    }

    if (step == "send-email" && (!data.giftcard && !data.cash_gift || this.getGift())) {
      if (this.getGift() && !this.order.features_used?.includes("used_songfinch_gift")) {
        update["giftcard"] = "1";
      }
      update['send'] = true;
    }

    if (this.order.personalizations?.gift && this.order.features_used?.includes("used_songfinch_gift")) {
      update['custom_song'] = JSON.stringify({
        'name_key': this.order.personalizations.gift.name_key,
        'spelling': this.order.personalizations.gift.spelling,
        'answers': this.order.personalizations.gift.answers,
        'selectedSong': this.order.personalizations.gift.selectedSong,
        'number_key': this.order.personalizations.gift.number_key,
        'collectionId': this.order.personalizations.gift.collectionId,
      });
      this.customSongClient?.sendBeacon()
    }

    // TODO-songfinch this needs to be addressed
    if (step == "send-email" && this.order.product.model == "creatacard") {
      update = this.appendUBPers(update);
    }

    if (["select-gift", "select-cash-gift"].includes(step)) {
      if (
        this.order.delivery_method != "copy" &&
        this.forms.has("email-send")
      ) {
        update = { ...this.forms.get("email-send").content, ...update };
      }
      if (this.order.product.model == "creatacard") {
        update = this.appendUBPers(update);
      }
      update['send'] = true;
    }

    if (!data.hasOwnProperty('csrfmiddlewaretoken')) {
      update['csrfmiddlewaretoken'] = Client.getCSRFToken();
    }

    if (data.hasOwnProperty("recipients")) {
      let recipients = [];
      for (const key in data.recipients) {
        recipients.push(data.recipients[key]);
      }
      data.recipients = recipients;
    }

    if (data.hasOwnProperty("sender_name")) {
      let sender_name = data["sender_name"];
      let sender_email = window.app_env.is_signed_in ? app_env.customer.email : "";
      delete data.sender_name;

      if (data.hasOwnProperty("sender_email")) {
        sender_email = data["sender_email"];
        delete data.sender_email;
      }

      data["sender"] = {
        name: sender_name,
        email: sender_email
      }
    } else if (
      window.app_env?.is_from_join_confirm &&
      step == "delivery-method" &&
      ["copy", "facebook"].includes(data.delivery_method)
    ) {
      // make sure we update the default sender
      // from the "direct to personalize" flow
      update["sender"] = window.app_env?.ecard_order.sender;
    }

    // Add Client Timezone Offset
    let offsetMinutes = new Date().getTimezoneOffset();
    let offsetHours = offsetMinutes === 0 ? 0 : -(offsetMinutes / 60);
    update["client_utcoffset"] = Math.round(offsetHours);

    // Add Generation Reporting
    if (update.send && app_forms.collection.has("ai-message") && this.order?.personalizations?.Msg) {
      let generateForm = app_forms.collection.get("ai-message");
      let generateContent = generateForm.getFormData(true);
      let features_used = this.order.features_used || [];
      if (this.order?.personalizations?.Msg) {
        let feature = Utility.getFeatureCode(generateContent.message_state, "message_state");
        if (!features_used.includes(feature)) {
          features_used.push(feature);
        }
        update["generate_message"] = generateContent.generate_message;
        update["features_used"] = JSON.stringify(features_used);
      }
    }

    this.isCompleted = update.hasOwnProperty('send');

    if (this.order?.personalizations?.gift?.selectedSong && this.order.mode != 'edit') {
      update['send'] = false;
      update["features_used"] = JSON.stringify(this.order.features_used);
      update["checkout"] = 'custom-song';
    }

    if (update['send'] && this.order.steps.includes("checkout")) {
      update['send'] = false;
      update['checkout'] = window.app_env?.checkout
    }

    return { ...data, ...update };
  }

  appendUBPers(update) {
    if (this.forms.has("personalize-cac")) {
      update = { ...this.forms.get("personalize-cac").content, ...update }
    } else if (this.order.personalizations?.LobsterId) {
      let cac_pers = {}
      for (let key in this.order.personalizations) {
        if (["pending_recip", "gift", "chatGPT"].includes(key)) continue
        cac_pers[key] = this.order.personalizations[key];
      }
      update = {
        "cac_pers": cac_pers,
        "features_used": JSON.stringify(this.order.features_used),
        ...update
      }
    }
    return update;
  }

  removeGiftAttached() {
    this.giftOptions.forEach((giftOption) => {
      Utility.updateContent({
        [`${giftOption.keyword}-option`]: {
          removeClass: "hidden",
          addClass: "--border",
        },
      })
    });
    if (!this.customSongClient) {
      Utility.updateContent({
        "custom-song-option": {
          addClass: "hidden"
        }
      });
    }
    Utility.updateContent({
      digital_gifts_title: "Add a Digital Gift",
      gift_attached: {
        addClass: "hidden",
      },
      add_digital_gift: {
        removeClass: "hidden",
      },
      remove_digital_gift: {
        addClass: "hidden",
      },
      "send-email-description": `Add recipient(s) for your greeting, customize your name, send now or schedule up to a year in advance.`,
      "hide-on-lock": "You can schedule up to a year in advance",
    });
    let datePicker = this.forms.get("email-send").fields.get("delivery_date");
    if (datePicker && datePicker.isInit) {
      datePicker.setMaxDate();
    }
  }

  hasPaidGift() {
    let gift = this.getGift();
    return Boolean(gift && (this.deliveryDetails["select_custom-song"] ? gift.name == "Custom Song" : gift))
  }

  addGiftAttached(gift, response = {}) {
    this.giftOptions.forEach((giftOption) => {
      Utility.updateContent({
        [`${giftOption.keyword}-option`]: {
          addClass: "hidden",
        },
      });
    });
    Utility.updateContent({
      digital_gifts_title: "Digital Gift Selected ",
      gift_selection: {
        removeClass: "hidden",
      },
      add_digital_gift: {
        addClass: "hidden",
      },
      [`${gift}-option`]: {
        removeClass: ["hidden", "--border"],
      },
    });
    if (this.order.mode != "edit" || !this.hasPaidGift()) {
      Utility.updateContent({
        remove_digital_gift: {
          removeClass: "hidden",
        },
      });
    }
    if (this.order.mode == "edit" && this.hasPaidGift()) {
      Utility.updateContent({
        info_digital_gift: {
          addClass: "hidden",
        },
      });
    }

    if (gift == "custom-song") {
      Utility.updateContent({
        gift_img: {
          "data-src": response?.selectedSong?.image_url,
          alt: response?.selectedSong?.title,
          addClass: "--square",
        },
        gift_attached: {
          removeClass: "hidden",
        },
        gift_img_styling: {
          addClass: "--square",
        },
        gift_title: response?.selectedSong?.artist,
        gift_description: response?.selectedSong?.title,
        "send-email-description": `Add recipient(s) for your greeting, customize your name, send now or schedule up to 30 days in advance.`,
        "hide-on-lock": "You can schedule up to 30 days in advance",
      });
      if (this.order.current_step == "personalize" && this.order.delivery_method == "copy") {
        Utility.updateContent({
          remove_digital_gift: {
            "data-form": "gift-options-form",
          },
        });
      } else {
        Utility.updateContent({
          remove_digital_gift: {
            "data-form": "email-send",
          },
        });
      }
      if (this.order.product.model == "creatacard") {
        Utility.updateContent({
          remove_digital_gift: {
            addClass: "hidden",
          },
          info_digital_gift: {
            addClass: "hidden",
          },
        });
      }
      if (this.order.mode != 'edit') {
        Utility.updateContent({
          gift_price: {
            removeClass: "hidden",
            "inner": `$${app_env.checkout_price}`,
          }
        });
        let datePicker = this.forms.get("email-send").fields.get("delivery_date");
        if (datePicker && datePicker.isInit) {
          datePicker.setMaxDate(30);
          this.checkFutureDate(datePicker.getLocalValue());
        }
      }
    }
  }

  initSendEmailForm() {
    let form = this.forms.get("email-send");
    if (!form) return;
    if (this.order.steps.includes("checkout") && window.app_env.checkout == "custom-song" && !this.order.features_used?.includes("used_songfinch_gift")) {
      let index = this.order.steps.indexOf("checkout");
      this.order.steps.splice(index, 1);
    }
    this.checkFutureDate();
    this.initGiftOptions(form);
    this.hydrateDeliveryDetails();
    let dateField = form.fields.get("delivery_date");
    if (dateField) {
      dateField.onChange = () => {
        this.checkFutureDate(dateField.getLocalValue());
        this.hydrateDeliveryDetails();
      }
    }
  }

  initGiftOptions(form) {
    let shareForm = window.app_forms.collection.get("gift-options-form");
    this.giftOptions.forEach((giftOption) => {
      let giftField = form.fields.get(giftOption.keyword);
      if (!giftField) return
      if (giftOption.keyword != "custom-song") {
        let addGiftBtn = form.el.querySelector(`button[data-controls='add-${giftOption.keyword}']`);
        addGiftBtn?.addEventListener("click", () => {
          form.fields.get(giftOption.keyword).setValue(true, true);
          this.addGiftAttached(giftOption.keyword);
        });
        let removeGiftBtn = form.el.querySelector(`button[data-controls='remove-${giftOption.keyword}']`);
        removeGiftBtn?.addEventListener("click", () => {
          form.fields.get(giftOption.keyword).setValue(false, true);
          this.hydrateDeliveryDetails();
        });
      }
      giftField.onChange = (input) => {
        this.deliveryDetails[
          `select_${input.name}`
        ] = input.checked;
        if(shareForm?.fields?.get(input.name)) {
          shareForm.fields.get(input.name).setValue(input.checked, false);
        }
        if (input.checked) {
          this.giftOptions.forEach(
            (secondaryGiftOption) => {
              if (secondaryGiftOption.keyword != input.name) {
                let secondaryGiftField = form.fields.get(secondaryGiftOption.keyword);
                secondaryGiftField.setValue(false, true);
                if(shareForm?.fields?.get(secondaryGiftOption.keyword)) {
                  shareForm.fields.get(secondaryGiftOption.keyword).setValue(false, false);
                }
                this.deliveryDetails[
                  `select_${secondaryGiftField.name}`
                ] = false;
              }
            }
          );
        }
        if (input.name != "custom-song") {
          this.hydrateDeliveryDetails();
        }
      };
      window.app_dialogs.collection.get(`${giftOption.keyword}-info`).onOpen = (dialog) => {
        if (this.order.current_step == "personalize" && this.order.delivery_method == "copy") {
          Utility.updateContent({
            [`button_${giftOption.keyword}`]: {
              "data-form": "gift-options-form"
            }
          }, dialog.el);
        } else {
          Utility.updateContent({
            [`button_${giftOption.keyword}`]: {
              "data-form": "email-send"
            }
          }, dialog.el);
        }
      }
    });
    if (!this.customSongClient) {
      Utility.updateContent({
        "custom-song-option": {
          addClass: "hidden"
        }
      });
    }
  }

  checkFutureDate(targetValue = false) {
    let targetDate = targetValue ? new Date(targetValue) : this.getDeliveryDate();
    let daysLeft = Helpers.getDateDelta(new Date(), targetDate, "days");
    this.deliveryDetails.scheduled = daysLeft > 0;
    let field_errors = {};
    if (this.order.gift?.selectedSong) {
      if (daysLeft > 30) {
        field_errors["delivery_date"] = "Cards containing Custom Song can not be scheduled more then 30 days in advance."
        this.forms.get("email-send").appendErrorsToFields(field_errors);
      } else {
        field_errors = {}
        this.forms.get("email-send").fields.get("delivery_date").clearErrors();
      }
    }
  }

  hydrateDeliveryDetails() {
    const selectGift = this.deliveryDetails.select_giftcard;
    const selectCashGift = this.deliveryDetails.select_cash_gift;
    const selectCustomSong = this.deliveryDetails["select_custom-song"] || this.order.features_used.includes("used_songfinch_gift");
    const hasGift = this.getGift();
    const hasCashGift = hasGift && this.order.gift.name == "Cash Gift";
    const hasGiftCard = hasGift && this.order.gift.name == "Gift Card";
    const hasCusomSong = hasGift && this.order.gift.name == "Custom Song";
    const hasCheckout = this.order.steps.includes("checkout");
    this.handle_cash_gift_scheduling(hasCashGift, selectCashGift);
    const scheduled = this.deliveryDetails.scheduled;
    const getSubmitLabel = () => {
      let action = scheduled ? "Schedule" : "Send";
      if (this.isResend) {
        action = "Resend"
      }
      if ((selectGift || selectCashGift) && !hasGift) {
        return `Add Gift & ${action}`
      } else if (hasCheckout) {
        return `Continue to Checkout`
      } else if (selectCustomSong && this.order.mode != "edit") {
        return `Checkout & ${action}`
      } else {
        return `${action} Greeting`
      }
    }
    if (selectCashGift || hasCashGift) {
      this.addGiftAttached("cash_gift");
    }
    if (selectCustomSong || hasCusomSong) {
      this.addGiftAttached("custom-song", this.order.personalizations.gift);
    }
    if (selectGift || hasGiftCard) {
      this.addGiftAttached("giftcard");
    }
    if (!(selectGift || selectCashGift || selectCustomSong || hasGift)) {
      this.removeGiftAttached();
    }

    Utility.updateContent({
      delivery_submit_label: getSubmitLabel(),
      delivery_submit: {
        addClass: selectGift || selectCashGift || hasCashGift || hasGiftCard ? "--has-gift" : "",
        removeClass: !(selectGift || selectCashGift || hasGiftCard || hasCashGift) ? "--has-gift" : "",
        "aria-label": getSubmitLabel(),
      },
    });

    if (hasCheckout) {
      Utility.updateContent({
        "delivery-button-icon": {
          addClass: "hidden"
        }
      });
      if (window.app_env?.checkout == "pps") {
        Utility.updateContent({
          "add-recipients-button": {
            addClass: "dialog-trigger",
            "aria-haspopup": "dialog",
            "aria-controls": "locked-multiple-recipients",
            "data-action": ""
          }
        });
      }
    }

    if ((hasGiftCard || hasCusomSong) && !this.el.classList.contains('--has-gift')) {
      this.el.classList.add('--has-gift');
      Utility.updateContent({
        "recipients-table": {
          addClass: "--table-locked"
        },
      });
    }
  }

  handle_cash_gift_scheduling(has_cash_gift, is_cash_gift_selected) {
    let form = this.forms.get("email-send");
    let delivery_date_field = form.fields.get("delivery_date");
    let cash_gift_instructions = form.el.querySelector("#cash-gift-instructions");
    if (
      has_cash_gift &&
      delivery_date_field &&
      !delivery_date_field.locked
    ) {
      this.toggle_cash_gift_scheduling(true);
    } else if (
      is_cash_gift_selected &&
      delivery_date_field &&
      !delivery_date_field.locked
    ) {
      this.toggle_cash_gift_scheduling(true);
    } else if (
      !has_cash_gift &&
      !is_cash_gift_selected &&
      delivery_date_field &&
      delivery_date_field.locked &&
      cash_gift_instructions?.classList.contains("--primary-lock-reason")
    ) {
      this.toggle_cash_gift_scheduling(false);
    }
    if (has_cash_gift || is_cash_gift_selected) {
      cash_gift_instructions?.classList.remove("--hidden");
    } else {
      cash_gift_instructions?.classList.add("--hidden");
    }
  }

  toggle_cash_gift_scheduling(lock = false) {
    let form = this.forms.get("email-send");
    let delivery_date_field = form.fields.get("delivery_date");
    let field_element = form.el.querySelector("#delivery-date-field");
    let field_label = form.el.querySelector("#delivery-date-label");
    let field_lock_icon = form.el.querySelector("#delivery-date-lock-icon");
    let field_instructions = form.el.querySelector("#delivery-date-instructions");
    let field_inputs = form.el.querySelector("#delivery-date-inputs-fieldset");
    let delivery_date = form.el.querySelector("#delivery-date");
    let cash_gift_instructions = form.el.querySelector("#cash-gift-instructions");

    delivery_date_field.lock(lock);
    if (lock) {
      field_element?.classList.add("--locked");
      field_lock_icon?.classList.remove("--hidden");
      field_label?.classList.add("--has-icon");
      field_instructions?.setAttribute("hidden", "");
      field_inputs?.setAttribute("disabled", "");
      delivery_date?.setAttribute("data-locked", "");
      cash_gift_instructions?.classList.add("--primary-lock-reason");
      let today = new Date();
      this.checkFutureDate(today);
      delivery_date_field.setValue(today.toJSON());
      Utility.updateContent({
        "send-email-description": "Select or create recipient(s) for your greeting and customize your name."
      });
    } else {
      field_element?.classList.remove("--locked");
      field_lock_icon?.classList.add("--hidden");
      field_label?.classList.remove("--has-icon");
      field_instructions?.removeAttribute("hidden");
      field_inputs?.removeAttribute("disabled");
      delivery_date?.removeAttribute("data-locked");
      cash_gift_instructions?.classList.remove("--primary-lock-reason");
      Utility.updateContent({
        "send-email-description": "Add recipient(s) for your greeting, customize your name, send now or schedule up to a year in advance."
      });
    }
  }

  copyHandler(e) {
    if (this.isCopyingLink) return
    this.isCopyingLink = true;
    const buttonText = e.target.querySelector(".btn__text");
    const initialText = buttonText.textContent;
    const message = this.order.delivery_details.short_url;
    const manageInnerCopy = () => {
      buttonText.textContent = "Copied!";
      setTimeout(() => {
        this.isCopyingLink = false;
        buttonText.textContent = initialText;
      }, 1000);
    }
    const copyFallback = () => {
      Utility.copyFallback(message);
      manageInnerCopy();
    }
    if (navigator.clipboard) {
      navigator.clipboard.writeText(message).then(manageInnerCopy, copyFallback);
    } else {
      copyFallback();
    }
  }

  shareHandler() {
    window.navigator?.share?.({
      title: `Your Greeting from ${Render.html(this.order.sender.name)} | American Greetings`,
      url: this.order.delivery_details.short_url,
    });
  }

  facebookShareHandler(e = null) {
    e?.preventDefault();
    let facebookUrl = new URL("https://www.facebook.com/share.php");
    facebookUrl.searchParams.append("u", this.order.delivery_details.short_url);
    window.open(facebookUrl, 'popup', 'width=555,height=599,top=50,left=50');
  }

  hydrateConfirmShare() {
    this.hydrateGift();
    if (this.order.order_summary) {
      this.el.classList.add(`--has-order-summary`);
    }
    const hasCashGift = this.order.gift?.name == "Cash Gift";
    const templateQuery = `.confirm-share-${this.order.delivery_method}-template`;
    const template = this.el.querySelector(templateQuery);
    const content = Render.interpolateTemplate(template, {
      pickup_url: this.order.delivery_details.short_url,
      cash_gift_amount: hasCashGift ? this.order.gift.price : "",
    }, true);
    Utility.updateContent({
      "confirm-share-content": content,
      "confirm-view-url": { "href": this.order.delivery_details.view_url },
      "confirm-resend-url": { "href": this.order.delivery_details.resend_url },
    });
    if (hasCashGift) {
      this.el.querySelector("#cash-gift-amount").style.display = "";
      this.el.querySelector("#cash-gift-text").style.display = "";
    }
    this.manageNativeShare();
    setTimeout(() => {
      if (this.supportsNativeShare) {
        this.shareHandler();
      } else if (this.order.delivery_method == "facebook") {
        this.facebookShareHandler();
      }
    }, 1000);
  }

  manageNativeShare() {
    Utility.updateContent({
      "default-share": {
        addClass: this.supportsNativeShare ? "hidden" : ""
      },
      "native-share": {
        removeClass: this.supportsNativeShare ? "hidden" : ""
      }
    })
  }

  hydrateRecipientTable() {
    let table = window.app_tables.collection.get("recipients-confirm");
    let recipinetsTable = window.app_tables.collection.get("recipients");
    if (!table || !recipinetsTable) return;
    table.data = recipinetsTable.data;
    table.entries = recipinetsTable.entries;
    table.renderItems(table.entries);
    table.el.classList.remove("--no-entries");
    table.dialog?.el.classList.remove("--no-entries");
  }

  getGift() {
    return Boolean(this.order.gift) && Object.keys(this.order.gift).length != 0 ? this.order.gift : null
  }

  hydrateConfirmEmail() {
    this.hydrateGift();
    this.hydrateReminders();
    this.pruneFailedCashGiftRecipients();
    this.hydrateRecipientTable();
    this.hydrateCashGiftFailures();

    if (this.order.order_summary) {
      this.el.classList.add(`--has-order-summary`);
    }
    let product_label = `${this.order.product.model_label}${this.getGift() ? ` and ${this.order.gift.name}` : ""}`;
    if (this.order.features_used?.includes("used_songfinch_gift")) {
      product_label = `${this.order.product.model_label}${this.getGift() ? " and Custom Song" : ""}`;
    }

    Utility.updateContent({
      "confirm-email-panel": {
        "aria-label": `You Have Successfully ${this.order.is_future ? 'Scheduled' : 'Sent'} your ${product_label}!`,
        "data-title": `${product_label} was ${this.order.is_future ? 'Scheduled' : 'Sent'} | American Greetings`,
      },
      "confirm-email-title": this.getConfirmEmailTitle(product_label),
      "confirm-email-description": this.getConfirmEmailDescription(),
      "confirm-mailbox-link": {
        "inner": this.order.is_future ? "Scheduled Cards" : "Sent Cards",
        "href": this.order.is_future ? "/myaccount/ecardsscheduled" : "/myaccount/ecardssent"
      },
      "confirm-view-url": { "href": this.order.delivery_details.view_url },
      "confirm-resend-url": { "href": this.order.delivery_details.resend_url }
    })

    if (this.order.mode == "giftcard-first") {
      Utility.updateContent({
        confirm_image: {
          "data-src": this.order.product.thumb
        }
      })
    }
  }

  getConfirmEmailTitle(product_label) {
    return this.order.is_future
      ? `Your ${product_label} Is On Its Way!`
      : `You Have Successfully Sent Your ${product_label}!`
  }

  getConfirmEmailDescription() {
    let deliverables = this.order.product.model_label;
    if (this.getGift() && !this.order.features_used?.includes("used_songfinch_gift")) {
      deliverables += ` and <span class="--lowercase">${this.order.gift.title}</span>`
    }
    else if (this.order.features_used?.includes("used_songfinch_gift")) {
      deliverables += ` and ${this.order.gift.name}`
    }
    let recipient = this.order.recipients[0] || {};
    let multiple_recipinets = "";
    if (this.order.recipients.length > 1) {
      let template = this.el.querySelector(".confirm-multiple-recipients-button-template");
      if (!template) return
      let label = this.order.recipients.length == 2 ? "other" : "others";
      let recipients_count = `+${this.order.recipients.length - 1} ${label}`;
      multiple_recipinets = Render.interpolateTemplate(template, { recipients_count }, true);
      multiple_recipinets = ` ${multiple_recipinets.trim()}`;
    }
    return this.order.is_future
      ? `This ${deliverables} will be sent to ${recipient.name} at ${recipient.email}${multiple_recipinets} on ${this.getDeliveryDate(true)}.`
      : `This ${deliverables} was sent to ${recipient.name} at ${recipient.email}${multiple_recipinets}.`
  }

  getDeliveryDate(format = false) {
    if (!this.order.delivery_date) return null
    let date = Helpers.convertDateTimeFromServer(this.order.delivery_date);
    if (format) {
      return date.toLocaleDateString("en-US", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      })
    }

    return date
  }

  hydrateCashGiftFailures() {
    let cash_gift_failures_content = "";
    let failures_text = "";
    if (
      this.getGift() &&
      this.order.gift.name == "Cash Gift" &&
      this.cashGiftFailures.length
    ) {
      this.cashGiftFailures.forEach(
        (item) => {
          const template = this.el.querySelector(
            "template#cash-gift-failure"
          );
          const template_vars = {
            recipient_email: item.email,
          };
          cash_gift_failures_content += Render.interpolateTemplate(
            template, template_vars, true
          );
        }
      );
      failures_text = (
        this.cashGiftFailures.length > 1 ?
          "Multiple payment transactions have" :
          "A payment transaction has"
      );
    }
    Utility.updateContent({
      "cash-gift-failed-recips": cash_gift_failures_content,
      "cash-gift-transaction-failures-text": failures_text,
      "confirm-try-again": {
        "href": this.order.delivery_details.resend_url
      }
    });
    this.el.querySelector("#cash-gift-failures").style.display = (
      cash_gift_failures_content.length ? "" : "none"
    );
  }

  hydrateGift() {
    this.order.gift = this.getGift();
    if (this.order.gift) {
      this.el.classList.add("--has-gift")
    } else {
      this.el.classList.remove("--has-gift")
    }
    let cash_gift_amount = "";
    if (this.order.gift?.name == "Cash Gift") {
      cash_gift_amount = "$" + (this.cashGiftTotal || this.order.gift.price);
    }
    Utility.updateContent({
      "gift_img": {
        "data-src": this.order.gift ? this.order.gift.image_url : "",
        "alt": this.order.gift ? this.order.gift.title : "",
        "title": this.order.gift ? this.order.gift.title : ""
      },
      "gift_description": this.order.gift ? this.order.gift.title : "",
      "cash_gift_amount": cash_gift_amount,
    });
  }

  hydrateReminders() {
    this.el.classList.remove("--has-anniversary-reminder", "--has-birthday-reminder");
    if (!this.order.contact_reminder || !this.order.contact_reminder.hasOwnProperty("occasion_ids")) return
    let eventsMap = {
      1: {
        "label": "Birthday",
        "slug": "birthday"
      },
      2: {
        "label": "Anniversary",
        "slug": "anniversary"
      },
    }
    let activeReminder = eventsMap[this.order.contact_reminder.occasion_ids[0]];
    let date = this.getDeliveryDate();
    let reminderForm = window.app_forms.collection.get("confirm-reminders");
    let isSingleEvent = this.order.contact_reminder.occasion_ids.length == 1;
    let contactDetails = Format.contactToForm(this.order.contact_reminder);
    this.order.contact_reminder.occasion_ids?.forEach(id => {
      let slug = eventsMap[id].slug;
      this.el.classList.add(`--has-${slug}-reminder`);
      if (activeReminder.slug == slug) {
        contactDetails[`${slug}_reminder`] = 1;
      }
      contactDetails[`${slug}_month`] = date.getMonth() + 1;
      contactDetails[`${slug}_day`] = date.getDate();
    })
    let recipient = this.order.recipients[0];
    Utility.updateContent({
      "confirm-reminder-title": isSingleEvent
        ? `Add ${activeReminder.label} Reminder for ${recipient.name}`
        : `Add Reminders for ${recipient.name}`,
      "confirm-reminder-subtitle": recipient.email
    })
    reminderForm.disableTransition();
    reminderForm.fill(contactDetails);
    setTimeout(() => {
      reminderForm?.enableTransition();
    }, 200);
  }
}

export default SendFlow;