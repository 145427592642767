import Global from "../../base/Global";
import Utility from "../../base/Utility";
import MobileDrawer from "./MobileDrawer";

class HeaderMobile {
  constructor() {
    this.el = document.querySelector('.header-mobile');
    this.trigger = null;
    this.menuEl = null;
    this.menuContent = null;
    this.menuBackdropEl = null;
    this.menuIsOpen = false;
    this.dropdowns = new Map();
    this.drawers = new Map();
    this.activeDrawer = null;
    this.activeDropdown = null;
    this.init();
  }

  init() {
    if (!this.el) return
    this.trigger = this.el.querySelector('.mobile-menu-button.--mobile-menu');
    this.menuEl = this.el.querySelector('.mobile-menu');
    this.menuContent = this.el.querySelector('.mobile-menu__content');
    this.menuBackdropEl = this.el.querySelector('.mobile-menu__backdrop');
    this.trigger?.addEventListener('click', this.toggleMenu.bind(this));
    this.menuBackdropEl?.addEventListener('click', (e) => { if (e.target == this.menuBackdropEl) { this.closeMenu(e) } });
    this.initDropdowns();
    this.initDrawers();
  }

  initDropdowns() {
    const x = this;
    const dropdowns = x.el.querySelectorAll('.mobile-menu-item.--has-dropdown');
    if (dropdowns && dropdowns.length != 0) {
      dropdowns.forEach((el, counter) => {
        if (el.id == '') {
          el.id = `mobile-dropdown-item-${counter}`;
        }
        x.dropdowns.set(el.id, new MobileDropdown(el, x));
      })
    }
  }

  initDrawers() {
    const drawers = this.el.querySelectorAll('.drawer');
    if (drawers && drawers.length != 0) {
      drawers.forEach((el, counter) => {
        if (el.id == '') {
          el.id = `mobile-drawer-item-${counter}`;
        }
        this.drawers.set(el.id, new MobileDrawer(el, this));
      })
    }
  }

  keydownHandler(e) {
    if (e.key == 'Escape') {
      if (this.activeDrawer) {
        this.activeDrawer.deactivate(e);
      } else if (this.activeDropdown) {
        this.activeDropdown.deactivate(e, true);
      } else {
        this.closeMenu(e, true);
      }
    } else if (e.key == 'Tab') {
      let focusables = []
      if (this.activeDrawer) {
        focusables = this.activeDrawer.el.querySelectorAll(Global.focusableQueryString);
      } else {
        focusables = this.menuContent.querySelectorAll(Global.focusableQueryString);
      }
      if (!focusables || focusables.length == 0) return
      const first = focusables[0];
      const last = focusables[focusables.length - 1];
      let looped = this.activeDrawer ? true : false;
      if (e.shiftKey) {
        switch (document.activeElement) {
          case first:
            if (looped) {
              last.focus();
            } else {
              this.trigger.focus({ preventScroll: true });
            }
            e.preventDefault();
            break;
        }
      } else {
        switch (document.activeElement) {
          case this.trigger:
            first.focus({ preventScroll: true });
            e.preventDefault();
            break;
          case last:
            if (looped) {
              first.focus();
            } else {
              document.querySelector('.header-mobile__main button:nth-child(2)')?.focus({ preventScroll: true });
            }
            e.preventDefault();
            break;
        }
      }
    }
  }

  focusHandler(e) {
    if (document.activeElement != this.trigger && !this.menuEl.contains(document.activeElement)) {
      this.closeMenu(e);
    }
  }

  openMenu(e = null) {
    if (this.menuIsOpen) return
    if (window.app_page.scrollBarWidth == 0) {
      this.menuEl.classList.remove('--with-scrollbar')
    } else {
      this.menuEl.classList.add('--with-scrollbar')
    }
    this.menuIsOpen = true;
    this.el.style.setProperty('--header-mobile-offset', this.el.getBoundingClientRect().top + 'px');
    this.el.classList.add('--open');
    this.trigger.ariaExpanded = true;
    this.menuEl.ariaHidden = false;
    window.app_page?.freezeScroll(false);
    window.app_listeners?.add('focus', 'mobile-header', this.focusHandler.bind(this));
    window.app_listeners?.add('keydown', 'mobile-header', this.keydownHandler.bind(this));
  }

  closeMenu(e = null, focusTrigger = false) {
    if (!this.menuIsOpen) return
    this.menuIsOpen = false;
    this.el.style.setProperty('--header-mobile-offset', '');
    setTimeout(() => {
      this.activeDrawer?.deactivate();
      this.activeDropdown?.deactivate(false);
    }, 200);
    this.el.classList.remove('--open');
    this.trigger.ariaExpanded = false;
    this.menuEl.ariaHidden = true;
    window.app_page?.unfreezeScroll();
    window.app_listeners?.remove('focus', 'mobile-header');
    window.app_listeners?.remove('keydown', 'mobile-header');
    if (focusTrigger && this.trigger != document.activeElement) {
      if (window.app_page?.wasKeyboardEvent) this.trigger.focus({ preventScroll: true });
    }
  }

  toggleMenu(e = null) {
    if (this.menuIsOpen) {
      this.closeMenu(e);
    } else {
      this.openMenu(e);
    }
  }
}

class MobileDropdown {
  constructor(el, headerMobile) {
    this.headerMobile = headerMobile;
    this.el = el;
    this.isActive = false;
    this.container = el.querySelector('.mobile-menu-item__dropdown');
    this.trigger = el.querySelector('.mobile-menu-item__link');
    this.init();
  }

  activate(e = null) {
    if (this.isActive) return
    this.isActive = true;
    this.headerMobile.activeDropdown?.deactivate(e);
    this.headerMobile.activeDropdown = this;
    this.trigger.ariaExpanded = true;
    this.container.ariaHidden = false;
    this.el.classList.add('--active');
    Utility.loadImages(this.container);
  }

  deactivate(e = null, focusTrigger = false) {
    if (!this.isActive) return
    this.isActive = false;
    this.headerMobile.activeDropdown = null;
    this.trigger.ariaExpanded = false;
    this.container.ariaHidden = true;
    this.el.classList.remove('--active');
    if (focusTrigger) {
      this.trigger.focus();
    }
  }

  toggle(e = null) {
    if (this.isActive) {
      this.deactivate(e)
    } else {
      this.activate(e);
    }
  }

  init() {
    Utility.addResizeObserver(this.container, null, '--dropdown-height');
    this.trigger?.addEventListener('click', this.toggle.bind(this));
  }
}

export default HeaderMobile;