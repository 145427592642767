import Utility from "../../../base/Utility";
import BaseField from "./BaseField";

class FileField extends BaseField {
  constructor(el, form) {
    super(el, form);
    this.initFileField();
    this.type = 'file';
    this.lastValue = "";
    this.fileList = this.el.files || null;
    this.fileBuffer = null;
    this.maxSizes = this.el.getAttribute("data-max-sizes")?.split("x") || null;
    this.fileThumb = this.field.querySelector(".field__edit-thumb");
  }

  initFileField() {
    this.field.querySelectorAll('[data-input-trigger]').forEach(trigger => trigger.addEventListener('click', () => {
      this.el.click();
    }, { signal: this.abortController.signal }));
    this.el.addEventListener("change", this.handleChange.bind(this))
  }

  handleChange(e) {

  }

  setFocus() {
    this.field.querySelector('.field__upload:not(.hidden) [data-input-trigger], .field__edit:not(.hidden) [data-input-trigger]')?.focus();
  }

  handleFiles(e = null) {
    let changed = this.el.value != this.lastValue;
    if (changed) {
      this.readFile(e);
      this.lastValue = this.el.value;
    }
    if (this.el.files.length == 0) {
      !changed && this.handleUpload(e);
      this.field?.querySelector('.field__edit')?.classList.add('hidden');
      this.field?.querySelector('.field__upload')?.classList.remove('hidden');
    } else {
      this.field?.querySelector('.field__edit')?.classList.remove('hidden');
      this.field?.querySelector('.field__upload')?.classList.add('hidden');
    }
  }

  initProxy() {
    // Overide BaseField method
  }

  readFile(e = null) {
    if (!this.el.files.length) return ""
    const file = this.el.files[0];
    const reader = new FileReader();
    this.fileBuffer = null;
    reader.addEventListener("load", () => {
      this.fileBuffer = reader.result;
      this.handleUpload(e);
      if (!this.maxSizes) {
        this.el.dispatchEvent(new Event("change", { bubbles: true }));
      }
    }, false);

    reader.readAsDataURL(file);
  }

  handleUpload(e = null) {
    let thumbLoader = this.field.querySelector(".field__edit-thumb-loader");
    if (this.fileBuffer) {
      var image = new Image();
      image.addEventListener('load', () => {
        let imageHeight = image.naturalHeight;
        let imageWidth = image.naturalWidth;
        let resize = false;
        if (imageHeight > Number(this.maxSizes[1]) && imageWidth > Number(this.maxSizes[0])) {
          resize = Number(this.maxSizes[1]);
        }
        this.fileThumb.src = Utility.resizeImage(image, 18 * (window.devicePixelRatio || 1));
        this.fileThumb?.classList.remove("hidden");
        thumbLoader?.classList.add("hidden");
        if (e?.isTrusted || window.Cypress) {
          this.fileBuffer = Utility.resizeImage(image, resize, true, .9);
          this.el.files = this.dataToFiles(this.fileBuffer);
        }
        this.handleProxyInput();
        this.el.dispatchEvent(new Event("change", { bubbles: true }));
        this.el.dispatchEvent(new Event("file-loaded"));
      });
      image.src = this.fileBuffer;
    } else {
      this.fileThumb?.classList.add("hidden");
      this.fileThumb?.setAttribute("src", "");
      thumbLoader?.classList.remove("hidden");
    }
  }

  getValue(forStorage = false) {
    if (!forStorage) {
      return this.el?.value || "";
    } else {
      return this.fileBuffer || "";
    }
  }

  dataToFiles(data, name = "saved-image.png") {
    const dataTransfer = new DataTransfer()
    const file = Utility.dataURLtoFile(data, name);
    dataTransfer.items.add(file)
    return dataTransfer.files;
  }


  setValue(value, files, trigger = false) {
    if (!value.startsWith("data:")) {
      this.value = value;
      this.el.value = "";
      this.el.files = files;
    } else {
      this.el.files = this.dataToFiles(value);
    }
    if (trigger) {
      this.el.dispatchEvent(new Event("input"));
      this.el.dispatchEvent(new Event("change", { bubbles: true }));
    }
  }

  handleProxyInput(e) {
    if (this.proxy && this.el.files.length != 0) {
      this.proxy.files = this.el.files;
      this.proxy.dispatchEvent(new Event('input'));
      this.proxy.dispatchEvent(new Event('change'));
    }
  }

  handleInput(e = null) {
    if (this.el.hasAttribute('data-no-null') && this.el.files.length == 0 && this.fileList && this.fileList.length != 0) {
      this.el.files = this.fileList;
    }
    this.form.fields.get('zoom')?.setValue('1');
    this.value = this.el.files;
    this.fileList = this.el.files;
    this.handleFiles(e);
    !this.maxSizes && this.handleProxyInput(e);
    this.afterSubmitValidation();
    this.onInput(this.value);
  }
}

export default FileField