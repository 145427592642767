import Utility from "../../../base/Utility";

class BaseField {
  constructor(el, form) {
    this.form = form;
    this.el = el;
    this.id = el.id;
    this.key = el.name;
    this.truename = el.name;
    this.value = el.value;
    this.type = "text";
    this.describedBy = new Set();
    this.enabled = true;
    this.field = el.closest(".field") ? el.closest(".field") : null;
    this.snapshot = this.field?.cloneNode(true);
    this.loader = this.field.querySelector('.field__loader');
    this.locked = this.el.hasAttribute("data-locked");
    this.watchedAttributes = ["data-guard", "required", "maxlength", "minlength", "min", "max", "pattern"];
    this.name =
      el.getAttribute("data-name") ||
      this.field.querySelector(".field__label")?.textContent.replace(/[\n\r]+|[\s]{2,}/g, ' ').trim() ||
      el.name;
    this.nameLowercase = this.name.toLowerCase();
    this.fieldset = el.closest("fieldset")
      ? el.closest("fieldset").getAttribute("aria-label") || el.closest("fieldset").getAttribute("name") || null
      : null;
    this.rules = new Map();
    this.errors = new Map();
    this.formErrors = new Map();
    this.abortController = new AbortController();
    this.searchCancelable = this.field?.classList.contains(
      "--cancelable-search"
    );
    this.fieldBtn = this.field?.querySelector(".field__button");
    this.valid = true;
    this.ready = false;
    this.isInactive = this.field?.classList.contains('--inactive');
    this.isVisible = this.field?.classList.contains('hidden');
    this.proxy = null;
    this.removeErrorOnResolve = true;
    this.fieldOutput = null;
    this.validatedValue = el.value || null;
    this.hasPreventAutofill = this.el.hasAttribute("data-prevent-autofill")
    this.characterGuards = {
      email: "[^a-zA-Z0-9._%+-{}$#!%^&@]",
      strict: "[^a-zA-Z0-9 -]",
      search: "[^a-zA-Z0-9 \'-]",
      alphanumeric: "[^a-zA-Z0-9 ]",
      letters: "[^\w\s\']",
      numbers: "[^0-9]",
    }
    this.filterRegEx = (value) => value;
    // Hooks
    this.onChange = (instance) => { };
    this.onInput = (value) => { };
    this.onErrorsResolved = (instance) => { };
    // Initialization
    this.init();
  }

  get requiresValidation() {
    return (
      this.el.value.trim() !== ""
      || ["error", "server-error", "internal"].includes(this.form.status)
      || this.errors.size != 0
    )
  }

  clearDescription() {
    this.describedBy.clear();
    this.el.removeAttribute("aria-describedby");
  }

  addDescription(key) {
    if (!key || this.describedBy.has(key)) return
    this.describedBy.add(key);
    let description = Array.from(this.describedBy).join(" ");
    this.el.setAttribute("aria-describedby", description);
  }

  removeDescription(key) {
    if (!key || !this.describedBy.has(key)) return
    if (this.describedBy.size == 1) return this.clearDescription()
    this.describedBy.delete(key);
    let description = Array.from(this.describedBy).join(" ");
    this.el.setAttribute("aria-describedby", description);
  }

  setInactive(isInactive = true) {
    this.isInactive = isInactive;
    if (isInactive) {
      this.field?.classList.add('--inactive');
      this.field?.closest('.field___holder')?.classList.add('--inactive');
    } else {
      this.field?.classList.remove('--inactive');
      this.field?.closest('.field___holder')?.classList.remove('--inactive');
    }
  }

  setVisibility(isVisible = true) {
    this.isVisible = isVisible;
    if (isVisible) {
      this.field?.classList.remove('hidden');
    } else {
      this.field?.classList.add('hidden');
    }
  }

  setLoading(isLoading = true) {
    if (isLoading) {
      this.field?.classList.add('--loading');
    } else {
      this.field?.classList.remove('--loading');
    }
  }

  setFocus() {
    this.el.focus();
  }

  enable() {
    this.enabled = true;
  }

  initLoading() {
    let dots = this.loader?.querySelector('.field__loader-dots');
    if (dots) {
      let interval = setInterval(() => {
        if (this.field.classList.contains('--loading')) {
          switch (dots.textContent) {
            case '': dots.textContent = '.'; break;
            case '.': dots.textContent = '..'; break;
            case '..': dots.textContent = '...'; break;
            case '...': dots.textContent = ''; break;
          }
        } else {
          clearInterval(interval)
        }
      }, 400);
    }
  }

  loadedHandler() {
    this.field.classList.remove('--loading');
    this.el.removeAttribute('disabled');
    setTimeout(() => {
      this.loader.classList.add('--stop-animation');
    }, 300);
  }

  lock(isLocked = true) {
    this.locked = isLocked;
    this.el.disabled = isLocked;
    if (this.fieldset) {
      this.fieldset.disabled = isLocked;
    }
    Utility.updateContent({
      "hide-on-lock": {
        addClass: isLocked ? "hidden" : "",
        removeClass: !isLocked ? "hidden" : "",
      },
      "hide-on-unlock": {
        addClass: !isLocked ? "hidden" : "",
        removeClass: isLocked ? "hidden" : "",
      }
    }, this.field);
  }

  disable() {
    this.enabled = false;
    this.validate();
    let removed = this.form.errors.delete(this.key);
    let removedHidden = this.form.hiddenErrors.delete(this.key);
    (removed || removedHidden) && this.form.renderErrors();
  }

  init() {
    this.el.setAttribute("spellcheck", false);
    this.initRules();
    this.initLoading();
    this.hasPreventAutofill && this.preventAutofill();
    this.abortController = new AbortController();
    this.field.querySelector("label")?.setAttribute("for", this.id);

    this.el.getAttribute("aria-describedby")?.split(" ")?.forEach(id => {
      this.describedBy.add(id);
    })

    this.el.addEventListener("blur", this.blurHandler.bind(this), {
      signal: this.abortController.signal,
    });

    if (this.el.hasAttribute('data-proxy')) {
      let target = document.querySelector(this.el.getAttribute('data-proxy'));
      if (target) {
        this.proxy = target;
        this.initProxy();
      }
    }

    if (this.searchCancelable && this.fieldBtn) {
      this.fieldBtn.addEventListener(
        "click",
        () => {
          this.setValue("", "input");
          if (window.app_page?.wasKeyboardEvent) {
            this.el.focus();
          }
        },
        { signal: this.abortController.signal }
      );
    }

    if (this.el.hasAttribute('data-conditional-visibility')) {
      let field = this.form.fields.get(this.el.getAttribute('data-conditional-visibility'));
      field.el.addEventListener('change', () => {
        this.setVisibility(field.el.value != '');
      }, { signal: this.abortController.signal })
    }

    this.el.addEventListener("input", this.handleInput.bind(this), {
      signal: this.abortController.signal,
    });
    window.app_listeners?.add('keydown', `${this.name}-field-keydown`, this.handleKeydown.bind(this));
    if (
      !this.field.classList.contains("--no-output") ||
      !this.form.topLevelErrors
    ) {
      this.field.classList.remove("--no-output");
      this.renderErrorOutput();
    }
    this.setReady();
    this.initInputMutation();
  }

  forwardChange(e) {
    this.onChange(e);
    this.afterSubmitValidation();
  }

  initProxy() {
    if (!this.proxy) return
    if (!["SELECT"].includes(this.proxy.tagName) && !["radio", "checkbox", "file"].includes(this.proxy.type)) {
      this.setValue(this.proxy.value, true);
      if (this.type == 'textarea') {
        this.characterInputHandler();
      }
    }
  }

  preventAutofill() {
    let uid = `no-fill-${this.id}-${new Date().getTime()}`;
    this.el.setAttribute("autocomplete", uid);
    this.el.setAttribute("name", uid);
  }

  initInputMutation() {
    const observer = new MutationObserver(mutationList => {
      mutationList.forEach(mutation => {
        if (mutation.type !== 'attributes') return
        this.attributeChangeHandler(mutation);
      })
    });
    observer.observe(this.el, { attributes: true });
  }

  attributeChangeHandler(mutation) {
    if (this.watchedAttributes.includes(mutation.attributeName)) {
      this.initRules();
    }
  }

  handleProxyInput(e) {
    if (this.proxy) {
      this.proxy.value = this.el.value;
      this.proxy.dispatchEvent(new Event('input'));
    }
  }

  handleInput(e) {
    this.handleProxyInput(e);
    e.target.value = this.filterRegEx(e.target.value);
    this.value = e.target.value;
    if (this.searchCancelable && this.fieldBtn) {
      if (this.value == "") {
        this.fieldBtn.disabled = true;
        this.fieldBtn.setAttribute("disabled", "");
      } else {
        this.fieldBtn.disabled = false;
        this.fieldBtn.removeAttribute("disabled");
      }
    }
    if (this.rules.has("maxlength") && this.el.hasAttribute("maxlength")) {
      const maxLength = Number(this.el.getAttribute("maxlength"));
      if (e.target.value.length >= maxLength) {
        e.target.value = e.target.value.substr(0, maxLength);
      }
    }
    if (this.rules.has("max") && this.el.hasAttribute("max") && !this.el.hasAttribute("data-skip-correction")) {
      const max = Number(this.el.getAttribute("max"));
      if (
        e.target.value != "" &&
        !isNaN(Number(e.target.value)) &&
        Number(e.target.value) > max
      ) {
        e.target.value = max;
      }
    }

    this.afterSubmitValidation();
    this.onInput(this.value);
  }

  handleKeydown(e) { }

  initRules() {
    if (this.el.hasAttribute("required")) {
      this.el.setAttribute("aria-required", "true");
      this.field.classList.add("--required");
      this.rules.set("required", {
        error:
          this.el.getAttribute("data-required-error") ||
          (this.type == 'select' ? `Select ${this.nameLowercase}` : `${this.name} is required`),
        formError:
          this.el.getAttribute("data-required-form-error") ||
          this.el.getAttribute("data-required-error") ||
          (this.type == 'select' ? `Select ${this.nameLowercase}` : `${this.name} is required`),
        test: (v) => v.trim().length != 0,
      });
    } else {
      this.el.removeAttribute("aria-required");
      this.field.classList.remove("--required");
      this.rules.delete("required");
    }
    if (this.el.hasAttribute("minlength")) {
      const minlength = this.el.getAttribute("minlength");
      this.rules.set("minlength", {
        error:
          this.el.getAttribute("data-minlength-error") ||
          `${minlength} characters at least`,
        formError:
          this.el.getAttribute("data-minlength-form-error") ||
          this.el.getAttribute("data-minlength-error") ||
          `Minimum length for ${this.nameLowercase} field is ${minlength}`,
        test: (v) =>
          v.trim().length == 0 ||
          v.trim().length >= Number(minlength),
      });
    } else {
      this.rules.delete("minlength");
    }
    if (this.el.hasAttribute("maxlength")) {
      const maxlength = this.el.getAttribute("maxlength");
      this.rules.set("maxlength", {
        error:
          this.el.getAttribute("data-maxlength-error") ||
          `${maxlength} characters at most`,
        formError:
          this.el.getAttribute("data-maxlength-form-error") ||
          this.el.getAttribute("data-maxlength-error") ||
          `Maximum length for ${this.nameLowercase} field is ${maxlength}`,
        test: (v) =>
          v.trim().length == 0 || v.trim().length <= Number(maxlength),
      });
    } else {
      this.rules.delete("maxlength");
    }
    if (this.el.hasAttribute("min")) {
      const min = this.el.getAttribute("min");
      this.rules.set("min", {
        error: this.el.getAttribute("data-min-error") || `Greater than ${min}`,
        formError:
          this.el.getAttribute("data-min-form-error") ||
          this.el.getAttribute("data-min-error") ||
          `Minimum value for ${this.nameLowercase} field is ${min}`,
        test: (v) => v.trim().length == 0 || Number(v.trim()) >= Number(min),
      });
    } else {
      this.rules.delete("min");
    }
    if (this.el.hasAttribute("max")) {
      this.rules.set("max", {
        error:
          this.el.getAttribute("data-max-error") ||
          `Less than ${this.el.getAttribute("max")}`,
        formError:
          this.el.getAttribute("data-max-form-error") ||
          this.el.getAttribute("data-max-error") ||
          `Maximum value for ${this.nameLowercase
          } field is ${this.el.getAttribute("max")}`,
        test: (v) =>
          v.trim().length == 0 ||
          Number(v.trim()) <= Number(this.el.getAttribute("max")),
      });
    } else {
      this.rules.delete("max");
    }

    if (this.el.hasAttribute("pattern") || this.el.hasAttribute("data-pattern")) {
      let regexString = this.el.getAttribute("pattern") || this.el.getAttribute("data-pattern");
      this.rules.set("pattern", {
        error:
          this.el.getAttribute("data-pattern-error") ||
          `Enter a valid ${this.nameLowercase}`,
        formError:
          this.el.getAttribute("data-pattern-form-error") ||
          this.el.getAttribute("data-pattern-error") ||
          `Wrong format for ${this.nameLowercase} field`,
        test: (v) =>
          v.trim().length == 0 ||
          v.trim().match(new RegExp(regexString)),
      });
    }

    if (this.el.hasAttribute("data-guard")) {
      let guardKey = this.el.getAttribute("data-guard");
      if (this.characterGuards.hasOwnProperty(guardKey)) {
        this.filterRegEx = (value) =>
          value.replace(new RegExp(this.characterGuards[guardKey], "gi"), "");
      } else if (guardKey == "name") {
        this.filterRegEx = (value) =>
          value.replace(/[^\w\s'\-\.\u00C0-\u024F\u0400-\u04FF\u0500-\u052F]/giu, "");
      }
    }
  }

  setReady() {
    this.form.fieldReady(this.key);
    this.ready = true;
  }

  unsetReady() {
    this.form.fieldUnready(this.key);
    this.ready = false;
  }

  getValue() {
    return this.el?.value || ''
  }

  setValue(value, trigger = false) {
    let changed = String(this.el.value) != String(value);
    this.value = value;
    this.el.value = value;
    if (trigger) {
      this.el.dispatchEvent(new Event("input"));
      changed && this.el.dispatchEvent(new Event("change", { bubbles: true }));
    }
  }

  destroy() {
    if (!this.snapshot) return;
    this.unsetReady();
    this.field.parentNode.replaceChild(this.snapshot, this.field);
    this.abortController.abort();
    window.app_listeners?.remove('keydown', `${this.name}-field-keydown`);
  }

  addServerError(message, temporary = false) {
    const currentValue = this.getValue();
    this.rules.set("server", {
      error: this.el.getAttribute("data-server-error") || message,
      formError: this.el.getAttribute("data-server-form-error") || message,
      test: (v) => v != currentValue,
    });
    this.validate();
    if (temporary) {
      this.rules.set("server", {
        error: this.el.getAttribute("data-server-error") || message,
        formError: this.el.getAttribute("data-server-form-error") || message,
        test: (v) => true,
      });
    }
  }

  blurHandler(e) {
    this.hasPreventAutofill && this.preventAutofill();
    if (this.rules.has("min") && this.el.hasAttribute("min")) {
      const min = Number(this.el.getAttribute("min"));
      if (
        e.target.value != "" &&
        !isNaN(Number(e.target.value)) &&
        Number(e.target.value) < min
      ) {
        e.target.value = min;
      }
    }
    if (this.requiresValidation) {
      setTimeout(() => this.validate(), 100);
    }
  }

  handleErrorsResolved() {
    this.onErrorsResolved(this);
    this.form.handleFieldErrorResolved(this);
  }

  setSelect() {
    this.el.select();
  }

  afterSubmitValidation() {
    const wasValid = this.valid;
    const hasErros = this.revalidate();
    if (this.errors.size != 0) {
      this.validate();
    }
    if (!wasValid && this.valid) {
      this.handleErrorsResolved();
    }
    if (hasErros) {
      this.renderErrors();
    }
  }

  revalidateError(key, errorEl) {
    if (!["pattern", "required"].includes(key)) return
    let errorId = this.fieldOutput ? `${this.id}-${key}` : `${this.id}-error`;
    errorEl.firstElementChild.innerText = this.rules.get(key).formError;
    if (!this.rules.get(key) || this.rules.get(key).test(this.getValue())) {
      errorEl.classList.add("--resolved");
      this.removeDescription(errorId);
      this.el.setAttribute("aria-invalid", false);
    } else {
      errorEl.classList.remove("--resolved");
      this.addDescription(errorId);
      this.el.setAttribute("aria-invalid", true);
    }
  }

  revalidate() {
    const errorEl = this.form.el.querySelector(
      `#${this.form.id}-${this.key}-error`
    );
    if (!errorEl) return false;

    if (this.errors.size == 0) {
      errorEl.classList.add("--resolved");
      if (this.removeErrorOnResolve) {
        errorEl.remove();
      }
      return false;
    }

    this.errors.forEach((error, key) => {
      this.revalidateError(key, errorEl);
    });

    return true;
  }

  renderErrorOutput() {
    this.fieldOutput = this.field.querySelector(".field__output");
    if (!this.fieldOutput) {
      let inner = `<span class="field__output"><div class="error-wrapper"></div></span>`;
      if (this.form.topLevelErrors) {
        this.field
          .querySelector(".field__header")
          ?.appendChild(Utility.fragmentFromString(inner));
      } else {
        this.field.insertBefore(
          Utility.fragmentFromString(inner),
          this.field.querySelector(".field__wrapper")?.nextSibling
        );
      }
    }
    this.fieldOutput = this.field.querySelector(".field__output");
    this.outputList = this.field.querySelector(".error-wrapper");
    Utility.addResizeObserver(this.fieldOutput, this.outputChange.bind(this));
  }

  outputChange() {
    if (this.outputList.children.length != 0) {
      this.fieldOutput.classList.remove("--empty");
      window.app_accessibility?.enable(this.fieldOutput);
    } else {
      this.fieldOutput.classList.add("--empty");
      window.app_accessibility?.disable(this.fieldOutput);
    }
  }

  renderErrors() {
    this.errors.forEach((value, key) => {
      if (
        this.fieldOutput &&
        !this.fieldOutput.querySelector(`#${this.id}-${key}`)
      ) {
        let inner = `
        <span id="${this.id}-${key}" class="field__error --new">${value}</span>
        `;
        this.fieldOutput?.firstElementChild?.appendChild(
          Utility.fragmentFromString(inner)
        );
      } else {
        this.fieldOutput
          ?.querySelector(`#${this.id}-${key}`)
          .classList.add("--new");
      }
      let errorId = this.fieldOutput ? `${this.id}-${key}` : `${this.id}-error`;
      this.addDescription(errorId);
      this.el.setAttribute("aria-invalid", true);
    });
    this.clearErrors();
  }

  clearErrors() {
    const errors = this.fieldOutput?.querySelectorAll(".field__error");
    if (errors && errors.length != 0) {
      errors.forEach((error) => {
        if (error.classList.contains("--new")) {
          error.classList.remove("--new");
        } else {
          this.removeDescription(error.id);
          error.remove();
        }
      });
      const afterErrors = this.fieldOutput?.querySelectorAll(".field__error");
      if (afterErrors.length == 0) {
        this.field.classList.remove("--error");
        this.el.removeAttribute("aria-invalid");
      }
    } else {
      this.field.classList.remove("--error");
      this.el.removeAttribute("aria-invalid");
    }
  }

  addError(key, error, formError) {
    this.errors.set(key, error);
    this.formErrors.set(key, formError);
    this.form.addError(this.key, formError);
    this.renderErrors();
  }

  removeError(key) {
    this.errors.delete(key);
    this.formErrors.delete(key);
    this.form.errors.delete(this.key);
    this.form.hiddenErrors.delete(this.key);
    this.renderErrors();
  }

  validate(caller = null) {
    if (this.form.validationDisabled) return
    this.errors = new Map();
    this.formErrors = new Map();
    this.form.errors.delete(this.key);
    this.form.hiddenErrors.delete(this.key);
    this.renderErrors();
    this.valid = true;
    let first = true;
    this.rules.forEach((rule, key) => {
      if (!this.enabled) return
      if (this.el.hasAttribute("data-disabled")) return
      if (!rule.test(this.getValue())) {
        if (first) {
          this.addError(key, rule.error, rule.formError);
          if (caller == "submit") {
            this.validatedValue = this.el.value;
          }
          this.valid = false;
          first = false;
        }
      } else {
        if (key == "server") {
          if (this.form.appendedFieldErrors.hasOwnProperty(this.key)) {
            delete this.form.appendedFieldErrors[this.key];
          }
        }
      }
    });

    this.revalidate();
    if (this.valid) {
      this.field.classList.remove("--error");
    } else {
      this.field.classList.add("--error");
    }
    return this.valid;
  }
}

export default BaseField;
