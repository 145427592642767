import Carousel from "./Carousel";
import Utility from "../base/Utility";
import Client from "../base/Client";

class Carousels {
  constructor() {
    this.collection = new Map();
    this.observer = null;
    this.init();
  }

  init() {
    const els = document.querySelectorAll('.carousel');
    if (!els || els.length == 0) return
    els.forEach(this.add.bind(this));
  }

  add(el) {
    if (el.id == '') {
      el.id = `carousel-instance`;
    }
    Utility.fixDuplicates(el);
    if (this.collection.has(el.id)) return
    this.collection.set(el.id, new Carousel(el));
    if (!this.observer) {
      this.observer = new IntersectionObserver(this.intersectionHandler.bind(this));
    }
    this.observer.observe(el);
    this.initFocus();
  }

  intersectionHandler(entries) {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        this.collection.get(entry.target.id).init();
      }
    })
  }

  initFocus() {
    if (this.collection.size == 0) return
    window.app_listeners?.add('keydown', 'slider-changer', this.onSlideFocus.bind(this));
  }

  onSlideFocus(e) {
    if (!window.app_page?.wasKeyboardEvent) return
    const carousel_item = document.activeElement.closest('.carousel__item');
    const carousel = carousel_item?.closest('.carousel');
    if (!carousel) return
    const instance = this.collection.get(carousel.id);
    if (!instance || !(instance instanceof Carousel) || !instance.swiper) return
    if (instance.type == '--billboard-banner') return;
    instance.swiper.slideTo(Number(carousel_item.getAttribute('data-index')), instance.swiperSpeed, false);
  }
}

export default Carousels;