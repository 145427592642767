"use strict";

import HeaderMobile from "./HeaderMobile";
import Submenu from "./Submenu";

class Header {

  constructor() {
    this.header = null;
    this.headerHeight = null;
    this.headerTop = null;
    this.barHeight = null;
    this.bannerHeight = 0;
    this.banner = null;
    this.cssProps = new Map();
    this.submenus = new Map();
    this.activeSubmenu = null;
    this.mobileMenu = null;
    this.header = document.querySelector('header');
    if (!this.header) return;
    this.banner = document.querySelector('.top-hat') ? document.querySelector('.top-hat') : null;
    this.init();

  }

  init() {
    // this.bannerResizeWatcher();
    this.mainMenuInit();
    this.mobileMenu = new HeaderMobile();
  }

  // bannerResizeWatcher() {
  //   if (this.banner) {
  //     new ResizeObserver(() => {
  //       this.bannerHeight = this.banner.firstElementChild.getBoundingClientRect().height;
  //       this.banner.style.setProperty('--top-hat-height', this.bannerHeight + 'px');
  //     }).observe(this.banner.firstElementChild);
  //   }
  // }

  // Main menu init
  mainMenuInit() {
    const mainMenu = document.querySelector(".header__main");
    if (!mainMenu) return;
    const dropdowns = mainMenu.querySelectorAll('.menu-item.--has-submenu');
    if (!dropdowns || dropdowns.length == 0) return
    dropdowns.forEach((el, counter) => {
      if (el.id == '') {
        el.id = `submenu-item-${counter}`;
      }
      this.submenus.set(el.id, new Submenu(el, this));
    })
    window.app_listeners?.add('focus', 'header', this.focusHandler.bind(this));
    window.app_listeners?.add('click', 'header-exit', this.clickHandler.bind(this));
  }

  clickHandler(e) {
    if (!this.activeSubmenu) return
    if (this.header.contains(e.target)) return
    this.activeSubmenu.deactivate();
    e.preventDefault();
  }

  focusHandler(e) {
    if (this.activeSubmenu
      && !this.activeSubmenu.el.contains(document.activeElement)
      && document.activeElement != document.body) {
      this.activeSubmenu.deactivate();
    }
  }

}
export default Header;
