import BaseField from "./BaseField";

class EmailField extends BaseField {
  constructor(el, form) {
    super(el, form);
    this.type = "email";
    this.initEmail();
  }
  initEmail() {
    this.el.addEventListener("blur", this.handleEmailBlur.bind(this), {
      signal: this.abortController.signal,
    });
    if (!this.el.hasAttribute("pattern")) {
      this.rules.set("pattern", {
        error:
          this.el.getAttribute("data-pattern-error") ||
          `Enter a valid ${this.nameLowercase}`,
        formError:
          this.el.getAttribute("data-pattern-form-error") ||
          this.el.getAttribute("data-pattern-error") ||
          `Wrong format for ${this.nameLowercase} field`,
        test: (v) =>
          v.trim() == "" ||
          v.trim().match(/^[a-zA-Z0-9\.\_\%\+{}$#!^&-]+@[a-zA-Z0-9\.-]+\.[a-zA-Z]{2,15}$/),
      });
      if (this.el.closest(`.table[data-type="recipients"]`)) {
        this.rules.get("pattern").test = (v) =>
          v.trim() == "" ||
          v.trim().match(/^[a-zA-Z0-9\.\_\+{}$#^&-]+@[a-zA-Z0-9\.-]+\.[a-zA-Z]{2,15}$/)
      }
    }
  }

  getValue() {
    return this.el?.value.trim().toLowerCase() || ''
  }

  handleEmailBlur(e) {
    e.target.value = e.target.value.toLowerCase();
  }
}

export default EmailField;
