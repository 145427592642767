import Utility from "../../base/Utility";

class FormRequest {

  constructor(form, callback) {
    this.form = form;
    this.callback = callback;
    this.method = form.el.getAttribute('method') || 'POST';
    this.customEndpoint = this.form.submitConfig.customEndpoint || false;
    this.customPackage = this.form.customPayload || this.form.submitConfig.customPackage || false;
    this.endpoint = this.form.customEndpoint || this.form.submitConfig.customEndpoint || form.el.getAttribute('data-endpoint') || form.el.getAttribute('action');
    if (this.endpoint == 'self') {
      this.endpoint = window.location.origin + window.location.pathname;
    }
    this.package = this.customPackage || { body: this.form.getFormData(), method: this.method };
    this.response = {};
    this.request = {};
    if (this.form.el.hasAttribute('data-test')) {
      this.test();
    } else {
      this.init();
    }
  }

  init() {
    this.form.onRequest(this);
    this.prepareData();
    this.initializeRequest(this.endpoint);
  }

  getRequestObject() {
    if (!this.package?.body) return null
    if (this.package.body instanceof FormData) {
      return Utility.formDataToObject(this.package.body)
    } else {
      if (this.package.body instanceof String) {
        return JSON.parse(this.package.body)
      } else {
        return this.package.body
      }
    }
  }

  test() {
    // use this to test form request, but clear it after.
    if (this.form.el.getAttribute('data-test') != '') {
      this.response = JSON.parse(this.form.el.getAttribute('data-test'));
      this.callback(this);
    }
  }

  prepareData() {
    if (this.package.hasOwnProperty('body')) {
      if (this.form.requestJSON) {
        this.request = this.form.filterRequest(this.form.getFormData(true));
        this.package.method = 'POST';
        this.package.headers = {};
        this.package.headers['Accept'] = 'application/json';
        this.package.headers['Content-Type'] = 'application/json';
        this.package.body = JSON.stringify(this.request);
        if (this.request.hasOwnProperty('csrfmiddlewaretoken')) {
          this.package.headers['X-Csrftoken'] = this.request['csrfmiddlewaretoken'];
          delete this.request['csrfmiddlewaretoken'];
        }
      }

      if (["GET", "get"].includes(this.package.method)) {
        const data = [...this.package.body.entries()];
        const asString = data
          .map(x => `${encodeURIComponent(x[0])}=${encodeURIComponent(x[1])}`)
          .join('&');
        this.endpoint += asString.length != 0 ? `?${asString}` : '';
        delete this.package.body;
      }
    }
    if (this.form.el.hasAttribute("data-include-credentials")) {
      this.package.credentials = 'include'
    }
  }

  async initializeRequest(endpoint) {
    this.package['signal'] = this.form.abortController.signal;

    try {
      const raw_response = await fetch(endpoint, this.package)
      const contentType = raw_response.headers.get("content-type");
      if (!contentType || !contentType.includes("application/json")) {
        throw new Error("Non-JSON Response");
      }
      this.response = await raw_response.json();
    } catch (error) {
      console.warn(error);
      if (["Failed to fetch", "Non-JSON Response"].includes(error.message)) {
        this.response = {
          status: 'error',
          data: {
            message: `Server error occured, please try again later`
          }
        };
      } else {
        this.response = { status: 'abort' };
      }
    } finally {
      this.callback(this)
    }
  }

}

export default FormRequest;