import Preview from "./Preview";
import Utility from "../../../base/Utility";

class PicsnwishesPreview extends Preview {
  constructor(el, product, pers) {
    super(el, product, pers);
  }

  afterInit() {
    this.initPicsnwishes();
  }

  getOptions(pers = {}) {
    return {
      "external-product-id" : this.product.product_number,
      "base-asset-path": `https://ak.imgag.com/imgag/product/picsandwishes/${this.product.product_number}/`,
      ...pers,
    }
  }

  async initPicsnwishes() {
    if (!this.config) return;

    let personalizations = await this.loadPersonalizations();
    if (!personalizations) return console.log("No personalizations");
    const loader = new agi.CardLoader(this.container);
    loader.load(this.getOptions(personalizations));

    Utility.elementRendered('.App img.loader-start', this.container).then((el) => {
      document.querySelector(".pnw-loader__loading").classList.add("hidden");
      document.querySelector(".pnw-loader__button").classList.remove("hidden");
      document.querySelector(".pnw-loader__button").addEventListener("click", () => {
        document.querySelector("img.loader-start").click();
        document.querySelector(".pnw-loader").classList.add("hidden");
        document.querySelector(".product-preview__content").classList.add("no-before");
      })
    })
  }
}

export default PicsnwishesPreview;