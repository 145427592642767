class Tealium {
  constructor(services) {
    this.services = services;
  }

  view(update = {}) {
    let page_data = window.utag_data || {};
    window.utag?.view({...page_data, ...update});
  }
}

export default Tealium;