import Format from "../../base/Format";
import AddressbookAction from "./AddressbookAction";

class Addressbook {
  constructor(el, main) {
    this.el = el;
    this.main = main;
    this.form = this.el?.getInstance("forms");
    this.tabs = null;
    this.tables = new Map();
    this.isFetching = false;
    this.actions = new Map();
    this.dialog = null;
    this.init();
  }

  init() {
    if (this.form && this.form.id == "confirm-reminders") {
      this.form.filterRequest = Format.formToContact;
    }
    const tabsEl = this.el.querySelector(".tabs");
    const tableEls = this.el.querySelectorAll(".table");
    if (tabsEl) {
      this.tabs = window.app_tabs?.collection.get(tabsEl.id);
    }
    const dialogEl = this.el.closest(".dialog");
    if (dialogEl) {
      this.dialog = window.app_dialogs?.collection.get(dialogEl.id);
    }
    tableEls.forEach((table) => {
      this.tables.set(table.id, window.app_tables?.collection.get(table.id));
    });

    this.initTriggers();
  }

  initTriggers() {
    if (this.tables.size == 0) return;
    this.tables.forEach((table) => {
      table.onClick = this.clickHandler.bind(this);
    });
  }

  initAction(action, params = {}, table = null, e = null) {
    const key = `${action}-${Date.now()}`;
    const actionInstance = new AddressbookAction(
      this,
      key,
      action,
      params,
      table,
      e
    );
    this.actions.set(key, actionInstance);
    this.main.actions.set(key, actionInstance);
    actionInstance.run();
  }

  deleteGroupContacts(ids) {
    let first = true;
    window.app_tables?.collection.forEach((table) => {
      if (table.type == "group" && table.data) {
        let newEntries = [];
        table.data.forEach((entry) => {
          let obj = { ...entry };
          obj.contacts = obj.contacts.filter((el) => !ids.includes(String(el)));
          obj.count = obj.contacts.length;
          if (entry.count != obj.count) {
            newEntries.push(obj);
          }
        });
        table.updateEntry(newEntries, first);
        first = false;
      }
    });
  }

  addGroupContacts(ids, groupIDs) {
    let first = true;
    window.app_tables?.collection.forEach((table) => {
      if (table.type == "group" && table.data) {
        let newEntries = [];
        table.data.forEach((entry) => {
          if (!groupIDs.includes(String(entry.id))) return;
          let obj = { ...entry };
          ids.forEach((id) => {
            if (!obj.contacts.includes(String(id))) {
              obj.contacts.push(String(id));
            }
          });
          obj.count = obj.contacts.length;
          if (entry.count != obj.count) {
            newEntries.push(obj);
          }
        });
        table.selectAll(false);
        table.updateEntry(newEntries, first);
        first = false;
      }
    });
  }

  removeGroupContacts(ids, groupIDs) {
    let first = true;
    this.tables.forEach((table) => {
      if (table.type == "group" && table.data) {
        let newEntries = [];
        table.data.forEach((entry) => {
          if (!groupIDs.includes(String(entry.id))) return;
          let obj = { ...entry };
          obj.contacts = obj.contacts.filter((el) => !ids.includes(String(el)));
          obj.count = obj.contacts.length;
          if (entry.count != obj.count) {
            newEntries.push(obj);
          }
        });
        table.updateEntry(newEntries, first);
        first = false;
      }
    });
  }

  clickHandler(e, action, params, table) {
    if (this.isFetching && !(action == "remove" || action == "remove-all"))
      return;
    if (action == "view") {
      this.initGroupDetails(params, table);
    } else {
      this.initAction(action, params, table, e);
    }
  }

  initGroupDetails(params, originalTable) {
    const table = this.tables.get("group") || this.tables.get("group-1");
    const group = originalTable.getEntry(params.id);
    table.filters.set("include", group.contacts);
    table.entriesCount = group.count;
    let description = "";
    table.fillHeader({
      "header-title": group.name,
      description,
      id: group.id,
    });

    table.updateHeader();
    table.el.setAttribute(
      "data-get",
      table.el.classList.contains("--is-send")
        ? "/addressbook/contacts?email=1"
        : "/addressbook/contacts?events=1"
    );
    setTimeout(() => {
      this.tabs.activate(table.tabKey, false, false, { id: params.id });
      if (
        table.el.classList.contains("--is-send") &&
        table.dialog &&
        table.tabs &&
        table.tabs.activeKey == table.tabKey
      ) {
        table.checkEmpty();
      }
      setTimeout(() => {
        table.scrollToTop(".table__header");
      }, 200);
    }, 50);
    if (window?.app_page.wasKeyboardEvent) {
      setTimeout(() => {
        table.focusEmpty() || window.app_accessibility?.focus(table.el);
      }, 500)
    }
  }

  completeAction(action) {
    this.actions.delete(action.key);
    this.main.actions.delete(action.key);
    if (
      action.action == "delete" &&
      action.completed &&
      this.actions.size != 0
    ) {
      this.actions.forEach((savedAction, key) => {
        savedAction.kill();
        this.actions.delete(key);
        this.main.actions.delete(key);
      });
    }
  }
}

export default Addressbook;
