import Utility from "../../../base/Utility";
import SelectField from "./SelectField";

class CountryField extends SelectField {
  constructor(el, form) {
    super(el, form);
    this.initCountry();
    this.loadedCountry = "";
  }

  get requiresClone() {
    return true
  }

  initCountry() {
    this.form.el.addEventListener('form-init', () => {
      if (this.optionsData) {
        this.onCountryChange(false);
      } else {
        this.el.addEventListener('data-loaded', () => {
          this.onCountryChange(false);
        });
      }
    });
    this.el.addEventListener("select", () => {
      this.onCountryChange(true);
    });
  }

  onCountryChange(byUser = true) {
    if (!this.optionsData) return
    if (this.label && !this.optionsData.hasOwnProperty(this.label)) return
    if (this.loadedCountry == this.label) return
    this.loadedCountry = this.label;
    let country = this.optionsData[this.label] || {};
    this.changeSCP(byUser, country);
    this.changePostalCode(byUser, country);
  }

  changeSCP(byUser, country) {
    if (!this.form.fields.has('state')) return
    const replaced_value = this.form.fields.get('state')?.el.value;
    this.form.fields.get('state').destroy();
    this.form.fields.delete('state');
    const input = this.form.el.querySelector('[name="state"]');
    if (!input) return;
    input.value = replaced_value;
    const field = input.closest('.field');
    if (!field) return;

    field.querySelector('.field__label').textContent = country.scp_heading || "State";
    if (country.scp && country.scp.length != 0) {
      input.setAttribute('required', true);
      field.classList.add('--select');
      const scp = {};
      country.scp.forEach(obj => {
        scp[obj.name] = obj.abbreviation;
      })
      input.setAttribute('data-type', 'select');
      input.setAttribute('data-ready', JSON.stringify(scp));
    } else {
      field.classList.remove('--select');
      input.removeAttribute('data-type');
      input.removeAttribute('data-ready');
      input.removeAttribute('required');
    }
    this.form.initField(field);
    if (!this.enabled) {
      this.form.fields.get('state')?.disable();
    }
    if (field.classList.contains("--required") && this.field.classList.contains("--stealth-required")) {
      field.classList.remove("--required");
      field.classList.add("--stealth-required");
    }
    if (byUser && this.enabled && !this.form.notSubmitted && !field.classList.contains("--stealth-required")) {
      this.form.fields.get('state').validate();
    }
  }

  changePostalCode(byUser, country) {
    if (!this.form.fields.has('zip_code')) return
    let postalCode = this.form.fields.get('zip_code');
    postalCode.field.querySelector('.field__label').textContent = country.zip_heading || "Postal Code";
    if (country.validation) {
      postalCode.rules.set('pattern', {
        error: country.validation.invalid,
        formError: country.validation.invalid,
        test: v => (v.trim().match(new RegExp(country.validation.regex))),
      });
    } else {
      postalCode.rules.delete('pattern');
    }
    if (byUser && !this.form.notSubmitted && this.enabled) {
      postalCode.validate();
    }
  }

  getValue() {
    if (!this.optionsData) return this.el.value
    let value = this.el.value.trim();
    if (this.optionsData.hasOwnProperty(value)) {
      return String(this.optionsData[value].iso);
    }
    return value;
  }

  getOptionByLabel(label) {
    if (!this.optionsData) return false
    if (this.optionsData.hasOwnProperty(label)) {
      return this.optionsData[label].iso
    }
    return false
    this.navigator?.init();
  }

  renderCloneOptions() {
    let finalString = `<option value=""></option>`;
    for (const item in this.optionsData) {
      finalString += `<option value=${this.optionsData[item].iso}>${item}</option>`;
    }
    this.clone.innerHTML = '';
    this.clone.appendChild(Utility.fragmentFromString(finalString));
  }

  getOption(key) {
    if (!this.optionsData) return
    let label = null;
    if (this.optionsData.hasOwnProperty(key)) {
      label = key;
      key = this.optionsData[key].iso;
    }
    if (!label) {
      for (const item in this.optionsData) {
        if (this.optionsData[item].iso == key) {
          label = item;
        }
      }
    }
    return {
      value: key,
      label: label || key
    }
  }

  optionTemplateMap(map) {
    map["value"] = this.optionsData[map.label].iso;
    return this.optionTemplateMapFilter(map)
  }

  searchOptions() {
    if (!this.optionsData) return {};
    if (this.getOptionByLabel(this.queriedTerm)) {
      return this.optionsData
    }
    let expression = this.buildExpression(this.queriedTerm);
    let filtered = Object.entries(this.optionsData).filter(([key, value]) => (
      `${key} ${value.iso}`.match(new RegExp(`(${expression})`, 'gi'))
    ));
    return this.optionsSearchFilter(Object.fromEntries(filtered), this.queriedTerm)
  }
}

export default CountryField;
