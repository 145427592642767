import "../../../../compiler/node_modules/regenerator-runtime/runtime";
import Client from "./Client";
import Global from "./Global";

class Utility {

  static applyToAll(string, fn, root_el = document, params = null) {
    let items;
    if (string instanceof NodeList) {
      items = string;
    } else {
      items = root_el.querySelectorAll(string);
    }
    if (items && items.length !== 0) {
      items.forEach(function (el) {
        if (params) {
          fn(el, params);
        } else {
          fn(el);
        }
      });
    }
  }

  static flatten(object) {
    if (!object) return {}
    if (object instanceof Array) {
      let flattened = {};
      if (object.length == 0 || typeof object[0] != 'object') return flattened
      object.forEach(group => {
        for (const item in group) {
          flattened[item] = group[item]
        }
      })
      return flattened
    }
    return object
  }

  static enumerate(array) {
    if (!array) return ""
    let string = "";
    array.forEach((hint, index) => {
      let first = index == 0;
      let last = index == array.length - 1;
      if (first) {
        string += hint;
      } else if (last) {
        string += ` and ${hint}`;
      } else {
        string += `, ${hint}`;
      }
    })
    return string
  }

  static getFeatureCode(key, domain = "") {
    if (domain == "message_state") {
      if (key == "Generated") return "used_generated_message_state"
      if (key == "Edited") return "used_edited_message_state"
      if (key == "Default") return "used_default_message_state"
      if (key == "Own") return "used_own_message_state"
    }
    return "";
  }

  static getRandomInt(min = 200, max = 500) {
    return Math.floor(Math.random() * (max - min + 1) + min);
  }

  static dataURLtoFile(dataurl, filename = "default.jpeg") {
    let arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[arr.length - 1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }

  static resizeImage(image, resize = 0, adaptive = true, optimize = 0) {
    if (!image) return ""

    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    let canvasHeight = image.naturalHeight;
    let canvasWidth = image.naturalWidth;
    if (resize) {
      const aspect = image.naturalWidth / image.naturalHeight;
      canvasHeight = resize;
      canvasWidth = resize * aspect;
      if (canvasWidth < resize && adaptive) {
        canvasHeight = resize / aspect;
        canvasWidth = resize;
      }
    }
    canvas.height = canvasHeight;
    canvas.width = canvasWidth;

    if (optimize) {
      ctx.fillStyle = 'white';
      ctx.fillRect(0, 0, canvas.width, canvas.height);
      ctx.drawImage(image, 0, 0, canvas.width, canvas.height);
      return canvas.toDataURL("image/jpeg", optimize);
    } else {
      ctx.drawImage(image, 0, 0, canvas.width, canvas.height);
      return canvas.toDataURL("png");
    }
  }

  static copyFallback(message) {
    let textArea = document.createElement("textarea");
    textArea.value = message;
    textArea.style.opacity = "0";
    textArea.style.position = "fixed";
    textArea.classList.add("client-logger");
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    try { document.execCommand('copy') } catch (err) { }
    document.body.removeChild(textArea);
  }

  static elementHasClasses(el, list) {
    if (!el) return
    let result = false;
    el.classList?.forEach(classKey => {
      if (list.includes(classKey)) {
        result = true;
        return true
      }
    })
    return result;
  }

  static getOrdinal(nr) {
    if (!nr) return ''
    const ordinalSuffixes = {
      "one": "st",
      "two": "nd",
      "few": "rd",
      "other": "th"
    };
    const ordinalPluralRules = new Intl.PluralRules("en", { type: "ordinal" });
    const ordinal = ordinalPluralRules.select(nr);
    return ordinalSuffixes[ordinal]
  }

  static loadImages(root = document) {
    const imgs = root.querySelectorAll('.lazy-load-active, .lazy-load');
    if (!imgs || imgs.length == 0) return;
    imgs.forEach(img => this.loadImage(img));
  }

  static getImageSource(el, img) {
    let originalSrc = img.getAttribute("data-src");
    if (originalSrc.includes("<!--")) return console.warn(`Invalid image url: ${originalSrc}`)
    if (!originalSrc.includes("contentstack.io") || originalSrc.endsWith(".svg")) return originalSrc;
    let width = null;
    if (!el.classList.contains("--original-width")) {
      let scale = window.devicePixelRatio || 1;
      let clientWidth = Math.round(el.getBoundingClientRect().width * scale);
      if (el.hasAttribute('data-loaded-width')) {
        let currentWidth = Number(el.getAttribute('data-loaded-width'));
        width = currentWidth < clientWidth ? clientWidth : currentWidth;
      } else {
        width = clientWidth;
      }
      el.setAttribute('data-loaded-width', width);
    }
    const url = new URL(originalSrc);
    width && url.searchParams.set("width", width);
    Client.supportsWebP() && url.searchParams.set("format", "webp");

    return url.href
  }

  static getImageDimensions(url) {
    return new Promise((resolve) => {
      if (!url) return resolve({})
      const img = new Image;
      img.onerror = function() {
        resolve({})
      };
      img.onload = function() {
        resolve({
          width: img.naturalWidth,
          height: img.naturalHeight,
        })
      };
      img.src = url;
    })
  }

  static getVideoDimensions(container, baseResolution = {}, callback = () => {}) {
    return new Promise(resolve => {
      this.elementRendered('video[poster]', container).then((el) => {
        if (el.videoHeight) {
          return resolve([el.videoWidth, el.videoHeight])
        }

        el.addEventListener("loadedmetadata", () => {
          if (el.videoHeight) {
            resolve([el.videoWidth, el.videoHeight])
          }
        });

        el.addEventListener("resize", () => {
          if (el.videoHeight) {
            callback([el.videoWidth, el.videoHeight])
            resolve([el.videoWidth, el.videoHeight])
          }
        });

        this.getImageDimensions(el.poster).then(posterSizes => {
          resolve([baseResolution.width, baseResolution.height])
        });
      })
      // Fallback if something fails
      setTimeout(() => {
        resolve([baseResolution.width, baseResolution.height])
      }, 8000)
    })
  }

  static loadImage(el, force = false) {
    if (!el) return
    let img = el.tagName == "IMG" ? el : el.querySelector("img");
    if (!img || !img.hasAttribute("data-src")) return
    if ((el.classList.contains("--loading") || el.classList.contains("--loaded")) && !force) return
    if (el.classList.contains("lazy-load") && force) {
      el.classList.remove("--loading", "--loaded");
      if (!el.querySelector(".lazy-load__loader")) {
        let loader = document.createElement("span");
        loader.classList.add("lazy-load__loader");
        el.appendChild(loader);
      }
      img.src = "";
    }
    let src = Utility.getImageSource(el, img);
    if (src && img.src != src) {
      img.src = src;
      el.classList.add("--loading");
      img.addEventListener("load", () => {
        if (el.classList.contains("--recheck-dimesions")) {
          img.style.setProperty("--img-width", img.naturalWidth);
          img.style.setProperty("--img-height", img.naturalHeight);
          img.setAttribute("width", img.naturalWidth);
          img.setAttribute("height", img.naturalHeight);
        }
        el.classList.remove("--loading");
        el.classList.add("--loaded");
        setTimeout(() => {
          if (el.classList.contains("--loaded")) {
            el.querySelector(".lazy-load__loader, .lazy-load-active__loader")?.remove();
          }
        }, 400)
      })
    }
  }

  static addResizeObserver(el, callback = null, cssVarName = '--js-height') {
    if (!el || !el.firstElementChild) return
    if (Client.prefersReducedMotion()) {
      el.style.setProperty(cssVarName, 'auto');
      return
    }

    let processEntry = (entry) => {
      const parent = entry.target.parentElement;
      let height = null;
      if (entry.borderBoxSize) {
        height = entry.borderBoxSize[0].blockSize;
      } else {
        height = entry.target.getBoundingClientRect().height;
      }
      height = Math.ceil(height)
      if (parent?.hasAttribute('data-last-height')) {
        if (parseInt(parent.getAttribute('data-last-height')) == height) return
        if (cssVarName) {
          if (!parent.classList.contains('--no-transition')
              && !entry.target.closest(".--block-transitions")) {
            parent.classList.add('--transitioning');
          }
        };
      }
      if (cssVarName) parent.style.setProperty(cssVarName, height + 'px');
      if (typeof callback == 'function') {
        callback(height, entry.target);
      }
      parent.setAttribute('data-last-height', height);
    }

    el.addEventListener('transitionend', () => {
      el.classList.remove('--transitioning');
    })
    let observer = new ResizeObserver(entries => {
      entries.forEach(processEntry);
    });
    observer.observe(el.firstElementChild);
    return observer;
  }

  static addWidthObserver(el, cssVarName = '--js-width', callback = () => { }) {
    if (!el) return
    function widthHandler(entries) {
      for (const entry of entries) {
        let width = null;
        if (entry.borderBoxSize) {
          width = entry.borderBoxSize[0].inlineSize;
        } else {
          width = entry.target.getBoundingClientRect().width;
        }
        if (entry.target.hasAttribute('data-last-width')) {
          if (parseFloat(entry.target.getAttribute('data-last-width')) == width) return
        }
        entry.target.style.setProperty(cssVarName, width + 'px');
        entry.target.setAttribute('data-last-width', width);
        callback(width, entry.target);
      }
    }
    let observer = new ResizeObserver(widthHandler);
    observer.observe(el);
  }

  static updateCopy(el, obj = {}, parse = false) {
    if (!el) return
    const tokens = el.querySelectorAll('[data-text-key]');
    tokens.forEach(token => {
      const key = token.getAttribute('data-text-key');
      if (obj.hasOwnProperty(key)) {
        if (parse) {
          token.innerHTML = obj[key];
        } else {
          token.textContent = obj[key];
        }
      }
    })
  }

  static clearArray(arr) {
    return arr.filter((item) => item != '')
  }

  static updateContent(obj, root = document) {
    if (!root) return
    const tokens = root.querySelectorAll('[data-content-key]');
    tokens.forEach(token => {
      const key = token.getAttribute('data-content-key');
      if (!obj.hasOwnProperty(key)) return
      if (typeof obj[key] == "string") {
        token.innerHTML = obj[key];
        return
      }
      for (const contentKey in obj[key]) {
        if (contentKey == 'inner') {
          token.innerHTML = obj[key][contentKey];
        } else if (contentKey == 'addClass') {
          let list = obj[key][contentKey] instanceof Array ? obj[key][contentKey] : [obj[key][contentKey]];
          list = this.clearArray(list);
          if (list.length != 0) {
            token.classList.add(...list);
          }
        } else if (contentKey == 'removeClass') {
          let list = obj[key][contentKey] instanceof Array ? obj[key][contentKey] : [obj[key][contentKey]];
          list = this.clearArray(list);
          if (list.length != 0) {
            token.classList.remove(...list);
          }
        } else {
          if (obj[key][contentKey] === null) {
            token.removeAttribute(contentKey);
          } else {
            token.setAttribute(contentKey, obj[key][contentKey]);
          }
        }
      }
    })
  }

  static conditionalInit(el, callback = () => { }) {
    if (!el) return
    let tab = el.closest('.tabs__panel:not(.--active)');
    let dialog = el.closest('.dialog');
    let wizard = el.closest('.wizard-panel:not(.--active)');
    if (tab) {
      tab.addEventListener('tab-activated', callback)
    } else if (dialog) {
      dialog.addEventListener('dialog-opened', callback)
    } else if (wizard) {
      wizard.addEventListener('panel-before-activation', callback)
    } else {
      callback()
    }
  }

  static elementRendered(selector, root = document) {
    return new Promise(resolve => {
      if (root.querySelector(selector)) {
        return resolve(root.querySelector(selector));
      }

      const observer = new MutationObserver(mutations => {
        let result = root.querySelector(selector)
        if (result) {
          resolve(result);
          observer.disconnect();
        }
      });

      observer.observe(root == document ? root.body : root, {
        childList: true,
        subtree: true
      });
    });
  }

  static inViewport(el, offsets = null) {
    const rect = el.getBoundingClientRect();
    return (
      rect.top >= (offsets?.top || 0) &&
      rect.left >= 0 &&
      rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
  }

  static addChildrenObserver(el, fn = null) {
    if (fn) {
      const observer = new MutationObserver(fn);
      observer.observe(el, { childList: true, subtree: true });
    }
  }

  static waitVisibleLoad(root = document, callback = () => { }, queryString = 'img') {
    let element = root.querySelector(queryString);
    if (element && element.tagName == "IMG" && !element.complete) {
      element.addEventListener('load', callback)
    } else {
      callback()
    }
  }

  static sleep(time = 0) {
    return new Promise(resolve => {
      setTimeout(() => {
        resolve();
      }, time)
    })
  }

  static waitBackgroundLoad(el, callback = () => { }) {
    if (!el) return;
    let style = el.currentStyle || window.getComputedStyle(el, false);
    let src = style.backgroundImage.slice(4, -1).replace(/"/g, "");
    if (src != '') {
      let image = new Image();
      image.addEventListener('load', () => {
        setTimeout(() => {
          callback();
        }, 200);
      });
      image.src = src;
    } else {
      callback();
    }
  }

  static getRootCssVar(key, type = 'string') {
    let value = getComputedStyle(document.documentElement).getPropertyValue(
      key
    );
    if (type == 'number') {
      if (value.includes('rem')) {
        value = 16 * Number(value.replace('rem', ''));
      } else if (value.includes('px')) {
        value = Number(value.replace('px', ''));
      }
    }
    return value;
  }

  static fixDuplicates(el) {
    if (el.id == '') return
    const check = document.querySelector(`#${el.id}`);
    if (!check) return
    if (check != el) {
      const number = check.id.replace(/\D/g, "");
      if (isNaN(parseInt(number))) {
        el.id = `${el.id}-1`;
      } else {
        el.id = el.id.replace(number, parseInt(number) + 1);
      }
      this.fixDuplicates(el);
    }
  }

  static counter(count, singular, plural = null) {
    if (count === undefined || typeof count != "number") return ""
    if (singular === undefined || typeof singular != "string") return ""
    if (count === 1) return `${count} ${singular}`
    return `${count} ${plural || singular + "s"}`
  }

  static slugify(string = null) {
    if (!string) return ''
    string = string.replace(/^\s+|\s+$/g, '');
    string = string.toLowerCase();

    // Remove accents, swap ñ for n, etc
    const from = "ÁÄÂÀÃÅČÇĆĎÉĚËÈÊẼĔȆÍÌÎÏŇÑÓÖÒÔÕØŘŔŠŤÚŮÜÙÛÝŸŽáäâàãåčçćďéěëèêẽĕȇíìîïňñóöòôõøðřŕšťúůüùûýÿžþÞĐđßÆa·/_,:;";
    const to = "AAAAAACCCDEEEEEEEEIIIINNOOOOOORRSTUUUUUYYZaaaaaacccdeeeeeeeeiiiinnooooooorrstuuuuuyyzbBDdBAa------";
    for (let i = 0, l = from.length; i < l; i++) {
      string = string.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
    }

    // Remove invalid chars
    string = string.replace(/[^a-z0-9 -]/g, '')
      // Collapse whitespace and replace by -
      .replace(/\s+/g, '-')
      // Collapse dashes
      .replace(/-+/g, '-');

    return string;
  }

  static scrollTo(el, offset = 0, ifOutside = false) {
    if (!el) return
    if (ifOutside && this.inViewport(el, {top: offset})) return
    const y = el.getBoundingClientRect().top + window.scrollY - offset;
    window.scrollTo({ top: y, behavior: 'smooth' });
  }

  static focusTrapper(e, el) {
    let focusables = el.querySelectorAll(Global.activeFocusableQueryString);
    if (!focusables || focusables.length == 0) return
    focusables = Array.from(focusables).filter((el) => !Client.isHidden(el));
    if (!focusables || focusables.length == 0) return
    const first = focusables[0];
    const last = focusables[focusables.length - 1];
    if (e.key !== 'Tab' || e.keyCode !== 9) return

    if (e.shiftKey) {
      if (document.activeElement === first || document.activeElement === el) {
        last.focus(); // add focus for the last focusable element
        e.preventDefault();
      }
    } else {
      if (document.activeElement === last) {
        first.focus(); // add focus for the first focusable element
        e.preventDefault();
      }
    }
  }

  // Fetch Inject | Copyright (C) VHS <vhsdev@tutanota.com> | @license Zlib
  // Updated by Alexandru Faina and Sebastian Ene
  // Original Code at https://codeberg.org/vhs/fetch-inject/src/branch/trunk/fetch-inject.js

  static async fetchInject(inputs, promise, renderInline = true, attributes = {}) {

    const filterSourceMaps = (string) => {
      string = string.replace(/\/\/[#@]\s(source(?:Mapping)?URL)=\s*(\S+)/, '');
      return string
    }

    const getResourceType = (src) => {
      if (src.endsWith('.css')) return "style"
      if (src.includes("fonts.googleapis")) return "style"
      return "script"
    }

    const injector = (resource, rootEl, resourceType, resolve) => {
      let src = resource.input.src || resource.input;
      if (rootEl.querySelector(`${resourceType}[injected-src="${src}"]`)) {
        resolve(resource);
        return;
      }
      resourceType = resourceType == "style" && !renderInline ? "link" : resourceType;
      const resourceEl = document.createElement(resourceType);
      const firstEncounter = document.getElementsByTagName(resourceType)[0];
      if (resourceType == "link") {
        resourceEl.setAttribute("rel", "stylesheet");
        resourceEl.setAttribute("type", "text/css");
        resourceEl.setAttribute("href", src);
      } else {
        resourceEl.setAttribute('injected-src', src);
        if (renderInline) {
          resourceEl.appendChild(document.createTextNode(filterSourceMaps(resource.text)));
        } else {
          resourceEl.setAttribute('src', src);
        }
        for (const key in attributes) {
          resourceEl.setAttribute(key, attributes[key]);
        }
        if (resource.input.src) {
          for (const resourceKey in resource.input) {
            resourceEl.setAttribute(resourceKey, resource.input[resourceKey]);
          }
        }
      }
      resourceEl.onload = resolve(resource);
      firstEncounter ? firstEncounter.parentNode.insertBefore(resourceEl, firstEncounter) : rootEl.head.appendChild(resourceEl);
    }

    const resources = [];
    const deferreds = promise ? [].concat(promise) : [];
    const thenables = [];

    inputs.forEach((input) => {
      if (input.src || !renderInline) {
        resources.push({
          text: "",
          blob: "",
          input
        })
      } else {
        deferreds.push(
          fetch(input.src || input)
            .then((res) => [res.clone().text(), res.blob()])
            .then(async (promises) => {
              const [text, blob] = await Promise.all(promises);
              resources.push({ text, blob, input });
            })
        )
      }
    });
    await Promise.all(deferreds);
    resources.forEach((resource) =>
      thenables.push({
        then: (resolve) => {
          let type = getResourceType(resource.input.src || resource.input);
          injector(resource, document, type, resolve);
        }
      })
    );

    return await Promise.all(thenables);
  }

  static loadResources(key, sources, callback, renderInline = true) {
    if (window.app_storage?.loadedResources?.has(key)) {
      callback?.();
    } else {
      this.fetchInject(sources, null, renderInline).then(() => {
        window.app_storage?.loadedResources?.set(key, sources);
        callback?.();
      });
    }
  }

  static resourcesLoaded(key, sources, renderInline = true, validate = null) {
    return new Promise(async (resolve, reject) => {
      if (typeof validate === 'function' && validate()) {
        resolve()
        return
      }
      if (!window.app_storage?.loadedResources?.has(key)) {
        window.app_storage?.loadedResources?.set(key, sources);
        await this.fetchInject(sources, null, renderInline);
        if (typeof validate === 'function' && validate()) {
          resolve()
          return
        }
      }
      if (typeof validate === 'function') {
        let interval = setInterval(() => {
          if (validate()) {
            clearInterval(interval);
            resolve();
          }
        }, 200)
      }
    })
  }

  static loadModules(key, sources, callback) {
    if (window.app_storage?.loadedResources?.has(key)) {
      callback?.();
    } else {
      let inputs = [];
      sources.forEach(source => {
        if (typeof source === 'object') {
          inputs.push({
            src: source.nomodule,
            nomodule: ''
          });
          inputs.push({
            src: source.module,
            type: 'module'
          });
        } else {
          inputs.push(source);
        }
      })

      this.fetchInject(inputs, null, false).then(() => {
        window.app_storage?.loadedResources?.set(key, sources);
        callback?.();
      });
    }
  }


  static getMemberNumber() {
    if (!window.get_cookie || !window.atob) return 0
    const memeberCookieKey = {
      dev: "customer_dev",
      stage: "customer_stage",
      prod: "customer"
    }
    const box = window.app_env.box_env || "prod";
    const customerCookieEncoded = window.get_cookie(memeberCookieKey[box]);
    if (!customerCookieEncoded || customerCookieEncoded == "null") return 0

    try {
      const customerData = window.atob(customerCookieEncoded);
      if (!customerData) return 0
      const memberNumberMatch = customerData.match(/memnum=([0-9]+)&/);
      if (!memberNumberMatch) return 0
      return memberNumberMatch.length > 1 ? memberNumberMatch[1] : 0;
    } catch (e) {
      return 0;
    }
  }

  static fragmentFromString(strHTML) {
    const el = document.createElement('template');
    el.innerHTML = strHTML;
    return el.content;
  }

  static stringFromFragment(fragment) {
    const el = document.createElement('div');
    el.appendChild(fragment);
    return el.innerHTML;
  }

  static sortArrayByKey(array, key, reverse = false) {
    return array.sort((a, b) => {
      const yes = reverse ? -1 : 1;
      const no = reverse ? 1 : -1;
      const x = typeof a[key] === 'string' || a[key] instanceof String ? a[key].toLowerCase() : a[key];
      const y = typeof b[key] === 'string' || b[key] instanceof String ? b[key].toLowerCase() : b[key];
      return ((x < y) ? no : ((x > y) ? yes : 0));
    });
  }

  static isLeapYear(year) {
    return ((year % 4 == 0) && (year % 100 != 0)) || (year % 400 == 0);
  }

  static objectToFormData(object) {
    let formData = new FormData();
    for (let key in object) {
      formData.append(key, object[key])
    }
    return formData
  }
  static formDataToObject(formData) {
    if (!formData) return
    return Object.fromEntries(formData.entries());
  }

}

export default Utility;