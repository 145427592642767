import Utility from "../../../base/Utility";
import BaseField from "./BaseField";

class TextareaField extends BaseField {
  constructor(el, form) {
    super(el, form);
    this.type = "textarea";
    this.editor = null;
    this.announcer = null;
    this.maxCharacterCount = this.el.getAttribute('maxlength') ? Number(this.el.getAttribute('maxlength')) : null;
    this.initTextArea();
  }

  initTextArea() {
    if (this instanceof TextareaField && this.maxCharacterCount) {
      this.el.addEventListener('input', this.characterInputHandler.bind(this), {signal: this.abortController.signal})
    }
  }

  getCharacterCount() {
    if (this.editor) {
      return this.editor?.plugins?.wordcount?.body?.getCharacterCount() || 0;
    }
    return this.el.value.length;
  }

  characterInputHandler(e) {
    let chars = this.getCharacterCount();
    this.afterSubmitValidation();
    let remaining = Number(this.maxCharacterCount) - Number(chars);
    let identifier = 0 == remaining ? "zero" : remaining > 0 ? "remain" : "excess";
    remaining = Math.abs(remaining);
    let quantifier = remaining == 1 ? '' : 's';
    let messages = {
      remain: `${remaining} character${quantifier} remaining`,
      zero: "No remaining characters",
      excess: `Excess of ${remaining} character${quantifier}`
    }
    clearTimeout(this.announcer);
    this.announcer = setTimeout(() => {
      window.app_accessibility.announce({
        message: messages[identifier],
        mode: "polite"
      })
    }, 2000);

    Utility.updateContent({
      "character-counter": {
        inner: messages[identifier],
        class: identifier == "excess" ? '--error-text' : ''
      }
    })
  }
}

export default TextareaField;
