import Client from "../../../base/Client";
import Utility from "../../../base/Utility";
import Preview from "./Preview";

class InteractivePreview extends Preview {
  constructor(el, product, pers) {
    super(el, product, pers);
    this.hasVideo = true;
    this.type = 'interactive';
    this.renderTemplate = this.el.querySelector(".product-preview__template") || null;
    this.productHasReplay = false;
    this.productIsBuilding = false;
    this.productReplayHandler = () => {};
  }

  afterInit() {
    this.isReady = true;
    this.watchLoader();
  }

  watchLoader() {
    this.el.classList.remove('--ready', '--after-ready');
    this.el.classList.add('--waits-visible');
    Utility.elementRendered('#agi-expression-container', this.container).then((el) => {
      Utility.elementRendered('img, canvas', this.container).then((el) => {
        if (el.tagName == 'CANVAS' && Client.isHiddenRect(el)) {
          Utility.elementRendered('img', this.container).then((el) => {
            Utility.waitVisibleLoad(this.container, this.ready.bind(this));
          })
        } else {
          this.ready();
        }
      })
    })
  }

  afterReady () {
    this.productIsBuilding = false;
    if (window.app?.replayHandler) {
      this.productHasReplay = true;
      this.productReplayHandler = () => {
        if (IntroState.name == app.currentState.constructor.name) return
        window.app?.replayHandler()
      };
    }
  }

  buildProductPreview() {
    this.loadProduct();
    this.watchLoader();
  }

  searchPauseMedia() {
    if (!this.dialog) return
    if (this.dialog.isActive) return
    let medias = document.querySelectorAll('video, audio');
    medias.forEach((media) => {
      media.pause();
      media.volume = 0;
      // media.remove();
    })
  }

  pauseMedia() {
    if (!this.productBuilded) return
    if (this.productHasReplay) return
    this.searchPauseMedia();
    if (window.createjs) {
      createjs.Sound?.stop()
    }
    if (window.Howler) {
      Howler.unload()
      Howler.mute()
    }
  }

  playMedia() {
    if (!this.productBuilded && !this.productIsBuilding) {
      this.buildProductPreview();
      this.productIsBuilding = true;
    }
  }

  resetMedia() {
    if (!this.productBuilded) return
    if (this.productHasReplay) {
      this.productReplayHandler();
    } else {
      this.el.classList.remove('--ready', '--after-ready', '--waits-visible');
      this.placer.innerHTML = "";
      this.searchPauseMedia();
      setTimeout(() => {
        this.searchPauseMedia();
      }, 200)
      this.productBuilded = false;
    }
  }

}

export default InteractivePreview;