class FacebookProvider {
  constructor(config, manager) {
    this.manager = manager;
    this.config = config;
    this.slug = "facebook";
    this.label = "Facebook";
    this.enabled = false;
    this.provider = null;
    this.init();
  }

  init() {
    if (this.enabled) return
    let check = document.querySelector(`.sso-auth__trigger[value="${this.slug}"]`)
    if (!check || !window.FB || !this.config) return
    this.provider = FB.init({
        appId: this.config.app_id,
        cookie: true,
        xfbml: true,
        version: this.config.api_version
    });
    this.enabled = true;
  }

  handleAuth(response) {
    let token = response?.authResponse?.accessToken;
    let user_id = response?.authResponse?.userID;
    if (!token || !user_id) {
      this.manager.handleAuthFail();
    } else {
      this.manager.handleAuthSuccess(this.label, token, user_id);
    }
  }

  start(e) {
    !this.enabled && this.init();
    if (!this.enabled) return console.log("not initialized");
    window.FB?.login(this.handleAuth.bind(this), {scope: 'public_profile,email'});
  }

  end() {}

}

export default FacebookProvider;