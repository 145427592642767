"use strict";
class RenewBanner {
  constructor() {
    this.init();
  }

  init() {
    const els = document.querySelectorAll(".renewal-promo");
    if (!els || els.length == 0) return
    els.forEach(this.initBanner.bind(this))
  }

  addAccessibilityButton(card, index){
    const btn = document.createElement("button");
    const cardlink = card.querySelector('a');
    const text = cardlink.getAttribute('aria-label');
    const btnindex = index + 1;
    if(!cardlink || !text) return

    cardlink.setAttribute('tabindex', '-1');
    btn.innerHTML = cardlink.outerHTML;
    btn.classList.add('accessibility-btn');
    btn.setAttribute('type', 'button');
    btn.setAttribute('aria-label', text + ' '+ btnindex  + ' of 4 products');
    btn.setAttribute('aria-roledescription', 'press to showcase');
    cardlink.outerHTML = btn.outerHTML;
  }

  removeAccessibilityButton(card){
    let btn = card.querySelector('button');
    let link = card.querySelector('a');
    if(!btn || !link) return

    link.removeAttribute('tabindex');
    btn.remove();
    card.prepend(link);
  }

  accessibilityUpdates(card, mainlink){
    const cardarialbl = card.querySelector('a')?.getAttribute('aria-label');
    const mainproduct = card.querySelector('.product');
    if(!mainproduct|| !cardarialbl) return

    card.querySelector('a')?.setAttribute('aria-label', cardarialbl + ' Showcased product');
    mainlink.setAttribute('aria-label', cardarialbl + " Send Now");
    mainproduct.id = `${mainproduct.id}-showcased`;
  }

  initBanner(el){
    const maincard = el.querySelector('.renewal-promo__main');
    const mainlink = el.querySelector('.renewal-promo__button a');
    const cards = el.querySelectorAll('.renewal-promo__more .product');
    if (!maincard || !mainlink || !cards || cards.length == 0) return

    this.accessibilityUpdates(maincard, mainlink);
    // TODO: add cloned from backend
    cards.forEach((card, index) => {
      this.addAccessibilityButton(card, index);
      card.querySelector('button')?.addEventListener('click', (e) => {
        e.preventDefault();
        const cardclone = card.cloneNode(true);
        this.removeAccessibilityButton(cardclone);
        maincard.innerHTML = cardclone.outerHTML;
        mainlink.href = e.target.href;
        this.accessibilityUpdates(maincard, mainlink);
        maincard.querySelector('a')?.focus();
      })
    })
  }
}
export default RenewBanner;