"use strict";

import Utility from "../base/Utility";

class Switch {

    constructor() {
        this.init();
    }

    init() {
        const els = document.querySelectorAll('.switch-btn');
        const autoSwitches = document.querySelectorAll('.switch-auto');
        if (!els || els.length == 0) return;
        els.forEach(this.initSwitch);
        if(!autoSwitches || autoSwitches.length == 0) return;
        autoSwitches.forEach(this.initAutoSwitch);
    }

    initSwitch(el) {
        const parent = document.querySelector(`[data-switch-parent="${el.getAttribute('data-switch')}"]`);
        const pair = document.querySelector(`[data-switch="${el.getAttribute('data-switch-pair')}"]`);
        if (!parent || !pair) return;
        el.addEventListener('click',() => {
            parent.classList.add('hidden');
            pair.classList.remove('hidden');
            if(parent == document.querySelector('#pof-update')) {
                const form = window.app_forms.collection.get('pof-update');
                Utility.scrollTo(pair, 400);
                form.clearErrors();
            }
        })
    }

    initAutoSwitch(el) {
        const delay = el.getAttribute('data-switch-delay') || 2000;
        const pair = el.getAttribute('data-switch-pair') || false;
        if(!pair) {
            setTimeout( () => {
                el.classList.add('hidden');
            }, delay)
        }
    }
}

export default Switch;