import Filter from "./Filter";

class BrowseFilter extends Filter {
  constructor(el) {
    super(el);
  }

  updateUrl(full = true, initialization = false, removal = false) {
    const { localPackage, url, seo_url } = this.composeQuery(full);
    window.app_storage?.set("state", localPackage);
    if (initialization) {
      window.history.replaceState(localPackage, 'unused', removal ? seo_url : window.location.href);
    } else {
      window.history.pushState(localPackage, 'unused', seo_url);
    }
  }

  filter() {
    if (this.form) {
      const { localPackage, url, seo_url } = this.composeQuery(true, false);
      let getUrl = new URL(seo_url);
      getUrl.pathname = "/fetch-request" + getUrl.pathname
      this.queryUrl = new URL(seo_url);
      this.form.submitHandler(null, getUrl.href, { method: 'GET' }, true);
      this.loading();
      this.form.onResponse = this.handleResponse.bind(this);
      this.form.submitComplete = (form) => this.updateStatus;
    }
  }

}

export default BrowseFilter;