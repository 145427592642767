import Utility from "../../base/Utility";

class ConditionalFieldsets {
  constructor(el, name, form) {
    this.el = el;
    this.name = name;
    this.fieldsets = new Map();
    this.conditions = new Map();
    this.activeFieldset = null;
    this.dataSourceConditional = el.getAttribute('data-conditional-field');
    this.form = form;
    this.noTransition = () => (this.form.noTransition);
    this.init();
  }

  changeHandler(fieldset) {
    this.el.dispatchEvent(new CustomEvent("fieldset-change", {
      detail: fieldset,
      cancelable: false,
      bubbles: true,
    }))
  }

  init() {
    if (!this.el.hasAttribute('data-name')) {
      this.el.setAttribute('data-name', this.name);
    }
    const conditionalFields = this.el.querySelectorAll('.conditional-field');
    if (conditionalFields && conditionalFields.length != 0) {
      conditionalFields.forEach(el => {
        this.fieldsets.set(el.dataset.name, new ConditionalFieldset(el, this, this.form))
      });
      conditionalFields.forEach(el => {
        if (el.classList.contains('--active')) {
          const target = el.getAttribute('data-name');
          this.fieldsets.get(target)?.activate()
        }
      })
    }

    let field = this.form.fields.get(this.dataSourceConditional);
    if (field && field.type == "select") {
      field.el.addEventListener("select", () => {
        if (this.conditions.has(field.getValue())) {
          const target = this.conditions.get(field.getValue());
          if (this.fieldsets.has(target)) {
            this.fieldsets.get(target).activate();
            this.changeHandler(this.fieldsets.get(target));
          }
        } else {
          this.activeFieldset?.deactivate();
        }
      })
      return
    }

    const booleanFields = this.form.el.querySelectorAll(`[name="${this.dataSourceConditional}"]`);
    if (booleanFields && booleanFields.length) {
      booleanFields.forEach(el => {
        if (el.type == 'radio') {
          el.addEventListener('change', () => {
            if (el.checked) {
              if (this.conditions.has(el.value)) {
                const target = this.conditions.get(el.value)
                if (this.fieldsets.has(target)) {
                  this.fieldsets.get(target).activate();
                  this.changeHandler(this.fieldsets.get(target));
                }
              } else {
                this.activeFieldset?.deactivate();
              }
            }
          })
        } else if (el.type == 'checkbox') {
          el.addEventListener('change', (e) => {
            let value = '';
            if (e.target.value == '1') {
              value = e.target.checked ? "checked" : "unchecked"
            } else if (e.target.checked) {
              value = e.target.value;
            }
            if (value == '') return
            const target = this.conditions.get(value);
            if (this.fieldsets.has(target)) {
              this.fieldsets.get(target).activate();
              this.changeHandler(this.fieldsets.get(target));
            } else {
              this.fieldsets.get(target)?.deactivate();
            }
          })
        }
      })
    }
  }
}

class ConditionalFieldset {
  constructor(el, fieldsets, form) {
    this.el = el;
    this.form = form;
    this.fieldset = fieldsets;
    this.sourceValue = el.getAttribute('data-conditional-value');
    this.innerFields = new Map();
    this.active = false;
    this.init();
  }

  init() {
    if (this.sourceValue) {
      if (this.sourceValue.includes(',')) {
        this.sourceValue.split(',').map(el => this.fieldset.conditions.set(el.trim(), this.el.dataset.name))
      } else {
        this.fieldset.conditions.set(this.sourceValue, this.el.dataset.name);
      }
    }
    this.getInnerFields();
  }

  getInnerFields() {
    this.innerFields = new Map();
    this.form.fields.forEach((field, key) => {
      if (this.el.contains(field.el)) {
        field.disable();
        this.innerFields.set(key, field);
      }
    })
    return this.innerFields;
  }

  onActivate() {
    this.el.classList.remove('--activating')
    this.fieldset.el.classList.remove('--no-transition');
  }

  activate() {
    if (this.fieldset.activeFieldset !== this) {
      this.fieldset.activeFieldset?.deactivate();
      this.fieldset.activeFieldset = this;
      this.el.classList.add('--activating');
    }
    if (this.fieldset.noTransition()) {
      this.fieldset.el.classList.add('--no-transition');
    }
    this.handleDescriptions(true);
    this.el.classList.add('--active');
    this.active = true;
    this.getInnerFields().forEach(el => { el.enable() });
    setTimeout(this.onActivate.bind(this), this.fieldset.noTransition() ? 10 : 10);
  }

  onDeactivate() {
    this.el.classList.remove('--deactivating');
    if (!this.active) {
      this.el.classList.remove('--active');
      this.fieldset.el.classList.remove('--no-transition');
    } else {
      this.fieldset.el.classList.remove('--no-transition');
    }
  }

  deactivate() {
    if (this.fieldset.noTransition()) {
      this.fieldset.el.classList.add('--no-transition');
    }
    this.handleDescriptions(false)
    this.el.classList.add('--deactivating');
    this.active = false;
    this.getInnerFields().forEach(el => { el.disable() });
    setTimeout(this.onDeactivate.bind(this), this.fieldset.noTransition() ? 10 : 10);
  }

  handleDescriptions(add) {
    this.el.querySelectorAll("[data-will-describe]").forEach(descriptor => {
      if (!descriptor.id) {
        descriptor.id = `${this.form.id}-descriptor-1`;
        Utility.fixDuplicates(descriptor);
      }
      let key = descriptor.getAttribute("data-will-describe");
      if (add) {
        this.form.fields.get(key)?.addDescription(descriptor.id);
      } else {
        this.form.fields.get(key)?.removeDescription(descriptor.id);
      }
    })
  }
}

export default ConditionalFieldsets;
