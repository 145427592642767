import BaseField from "./BaseField";

class RangeField extends BaseField {
  constructor(el, form) {
    super(el, form);
    this.initRangeField();
    this.fileList = this.el.files || null;
  }

  initRangeField() {
    this.field.querySelectorAll('[data-input-trigger]').forEach(trigger => {
      let value = Number(trigger.getAttribute('data-increment'));
      trigger.addEventListener('click', () => {
        this.el.value = Number(this.el.value) + value;
        this.el.dispatchEvent(new Event('input'));
      }, {signal: this.abortController.signal}
    )});
  }

  handleInput(e) {
    this.value = e.target.value;
    this.handleProxyInput(e);
    this.afterSubmitValidation();
    this.onInput(this.value);
  }
}

export default RangeField