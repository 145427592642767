class MobileDrawer {
  constructor(el, headerMobile) {
    this.el = el;
    this.headerMobile = headerMobile;
    this.isActive = false;
    this.trigger = headerMobile.el.querySelector(`.drawer-trigger[aria-controls="${el.id}"]`) || null;
    this.back = el.querySelector('.drawer__back') || null
    this.backdrop = el.querySelector('.drawer__backdrop') || null
    this.el.classList.add('--hidden');
    this.init();
  }

  activate(e = null) {
    if (this.isActive) return
    this.isActive = true;
    this.headerMobile.activeDrawer?.deactivate();
    this.headerMobile.activeDrawer = this;
    this.trigger.ariaExpanded = true;
    this.el.ariaHidden = false;
    this.headerMobile.el.querySelector('.drawer:not(.--hidden)')?.classList.add('--hidden');
    this.el.classList.remove('--hidden');
    setTimeout(() => {
      this.el.classList.add('--active');
      this.headerMobile.el.classList.add('--drawer-is-active');
      if (window.app_page?.wasKeyboardEvent) this.back.focus();
    }, 10)
  }

  deactivate(e = null) {
    if (!this.isActive) return
    this.isActive = false;
    this.headerMobile.activeDrawer = null;
    this.trigger.ariaExpanded = false;
    this.el.ariaHidden = true;
    this.el.classList.remove('--active');
    this.headerMobile.el.classList.remove('--drawer-is-active');
    if (window.app_page?.wasKeyboardEvent) this.trigger.focus();
  }

  toggle(e = null) {
    if (this.isActive) {
      this.deactivate(e)
    } else {
      this.activate(e);
    }
  }

  init() {
    this.trigger?.addEventListener('click', this.toggle.bind(this));
    this.back?.addEventListener('click', this.deactivate.bind(this));
    this.backdrop?.addEventListener('click', this.deactivate.bind(this));
  }
}

export default MobileDrawer;