import Client from "../../base/Client";
import Render from "../../base/Render";
import Utility from "../../base/Utility";
import Heartbeat  from "../../../../../../common/modules/utilities/Heartbeat";

class UnifiedBuilder {
  constructor(el, order) {
    this.el = el;
    this.order = order || window.app_env?.ecard_order;
    this.UBLoader = null;
    this.product = window.app_env?.product;
    this.form = null;
    this.builder = null;
    this.config = window.app_env?.product_config;
    this.assets = {};
    this.selfCalledResize = false;
    this.loaderStatus = {};
    this.hasQRGift = false;
    this.deviceHasScrollbar = false;
    this.processingInterval = null;
    this.jifitiConfig = null;
    this.ubModalOpen = false;
    this.placer = document.querySelector(".agub__insert-before");
    this.lobster_id = null;
    this.init();
  }

  init() {
    if (!this.placer) return
    this.form = window.app_forms?.collection.get("personalize-cac");
    if (this.config.flags.includes("video")) {
      Utility.loadResources("ub-dependencies", [
        "https://cdn.jsdelivr.net/npm/@mux/upchunk@2"
      ], this.initUB.bind(this), true)
    } else {
      this.initUB();
    }
  }

  async initUB() {
    this.supportsNativeShare = Boolean(window.navigator.share) && Client.isHandheldDevice();
    this.UBLoader = new UBLoader(this.product.product_number, this.placer, { "supportsNativeShare": this.supportsNativeShare, ...window.app_env?.product_config,});
    this.builder = await this.UBLoader.init();
    if (!this.builder) return console.log("UB not initialized");
    this.builder.addEventListener('ub-initialized', this.initHandler.bind(this));
    this.builder.addEventListener('cardReady', this.cardReadyHandler.bind(this));
    this.builder.addEventListener("PRINT", this.printHandler.bind(this));
    this.builder.addEventListener("SHARE", this.shareHandler.bind(this));
    this.builder.addEventListener("BEGIN_GIFTCARD_PURCHASE", this.jifitiStartHandler.bind(this));
    this.builder.addEventListener("GIFTCARD_PURCHASE_FINISHED", this.jifitiCompleteHandler.bind(this));
    this.initGiftDialog();
  }

  jifitiStartHandler() {
    this.hasQRGift = false;
    let dialog = window.app_dialogs?.collection.get("ub-select-gift");
    if (!dialog) return console.log("no giftcard dialog found");
    if (this.deviceHasScrollbar) {
      dialog.hasScrollbar = true;
    }
    window.app_dialogs?.open("ub-select-gift");
  }

  jifitiCompleteHandler() {
    this.jifitiConfig = null;
    this.hasQRGift = true;
  }

  adjustResize() {
    if (this.selfCalledResize) {
      this.selfCalledResize = false;
      return
    }
    this.selfCalledResize = true;
    setTimeout(() => {
      window.dispatchEvent(new Event("resize"))
    }, 200)
  }

  setEditMode(isEditMode = true) {
    const mediaQuery = window.matchMedia("(orientation: portrait)");
    let orientationController = null;
    const handleMediaChange = (m) => {
      if (m.matches) {
        this.adjustResize();
      }
    }
    if (isEditMode) {
      orientationController?.abort();
      orientationController = new AbortController();
      window.app_listeners?.add("resize", "cac-resize-adjustment", this.adjustResize.bind(this));
      document.body.classList.add("--ub-edit-clear");
      mediaQuery.addEventListener("change", handleMediaChange.bind(this), { signal: orientationController.signal });

    } else {
      orientationController?.abort();
      window.app_listeners?.remove("resize", "cac-resize-adjustment");
      document.body.classList.remove("--ub-edit-clear");
    }
  }

  setError(message = null) {
    this.setLoading(false, { status: "error" })
    this.form.addError("server", message || "This service is currently unavailable. Please try again later.");
    this.form.renderErrors();
  }

  updateFlowThumb(imageUrl) {
    Utility.updateContent({
      base_thumb: {
        addClass: "hidden"
      },
      personalized_thumb: {
        removeClass: "hidden"
      },
      personalized_thumb_img: {
        "data-src": imageUrl
      },
      confirm_image: {
        "data-src": imageUrl
      }
    })
  }

  async shareHandler(shareEvent) {
    this.assets = {};
    let time = 0;
    this.setLoading(true, {
      title: shareEvent.pers.video.video ? "Processing video..." : "Processing...",
      status: "loading",
      progress: 0,
      time: 0,
    });
    this.processingInterval && clearInterval(this.processingInterval);
    this.processingInterval = setInterval(() => {
      if (time >= 70) {
        clearInterval(this.processingInterval);
        return
      };
      time += Utility.getRandomInt(5, 15);
      this.setLoading(true, { time });
    }, Utility.getRandomInt(100, 1000));
    try {
      if (shareEvent.pers.video.video) {
        let playbackUrl = await this.handleVideoPersonalization(shareEvent);
        if (!playbackUrl) throw "No playback url"
        shareEvent.pers.video.playbackUrl = playbackUrl;
      }

      await this.saveToLobster(shareEvent);
    } catch (err) {
      this.setError("Unable to save personalizations, please try again later.");
      return
    }
    this.form.extraRequestParams = this.getUsageData(shareEvent.usage.simple);
    this.processingInterval && clearInterval(this.processingInterval);
    this.setLoading(true, {
      title: "Loading...",
      progress: 100,
      time: 90,
    });
    this.updateFlowThumb(this.form.extraRequestParams.cac_pers?.agi_personalized_thumb);
    this.submitForm(shareEvent.usage.deliveryMethod);
  }

  async printHandler(printEvent) {
    this.assets = {};
    let time = 0;
    const is_succesful_print = printEvent.usage.errors.length == 0;
    if (!is_succesful_print) return;
    this.setLoading(true, {
      title: "Processing print...",
      status: "loading",
      progress: 0,
      time: 0,
    });
    this.processingInterval && clearInterval(this.processingInterval);
    this.processingInterval = setInterval(() => {
      if (time >= 70) {
        clearInterval(this.processingInterval);
        return
      };
      time += Utility.getRandomInt(5, 15);
      this.setLoading(true, { time });
    }, Utility.getRandomInt(100, 1000));
    try {
      await this.saveToLobster(printEvent);
    } catch (err) {
      this.setError("Unable to save personalizations, please try again later.");
      return
    }
    this.form.extraRequestParams = this.getUsageData(printEvent.usage.simple);
    if (this.hasQRGift) {
      this.form.extraRequestParams["giftcard"] = "1";
      this.hasQRGift = false;
    }
    this.processingInterval && clearInterval(this.processingInterval);
    this.setLoading(true, {
      title: "Loading...",
      progress: 100,
      time: 90,
    });
    this.updateFlowThumb(this.form.extraRequestParams.cac_pers?.agi_personalized_thumb);
    let path = `${printEvent.itemNumber ? printEvent.itemNumber : 0}${this.order.sender_id_fragment}`
    this.form.customEndpoint = `${app_env.shost}/ub-print/${path}`
    this.submitForm("print");
  }

  async saveToLobster(eventDetail) {
    // copy and update the copy
    eventDetail = await this.storeGeneratedAssets(eventDetail);
    if (!eventDetail) {
      throw new Error("Error storing assets!")
    }

    //send pers image and pers envelope to the back-end
    this.assets["agi_personalized_thumb"] = eventDetail.pers.customizedImage;
    if (eventDetail.pers?.envelope?.front && this.config.flags.includes("envelope")) {
      this.assets["envelope_thumb"] = eventDetail.pers.envelope.front;
    }
    // Save to lobster
    this.lobster_id = await window.app_services?.lobster.save(
      this.product.product_number,
      eventDetail.pers
    )
    if (!this.lobster_id) {
      throw new Error("Error storing in lobster!")
    }
  }

  async parseImgString(dataUrl) {
    // read more about data Urls on MDN. They have a specific format.
    try {
      const imageRes = await fetch(dataUrl)
      const imageBlob = await imageRes.blob()
      return imageBlob
    } catch (err) {
      // A request should be made to create a traceback for this
      console.log(err);
      this.setError();
    }
  }

  async storeGeneratedAssets(shareEventDetail) {
    // replaces the dataUrls with the storage url where they can be found

    // if hand-picking the generated files becomes weird we can always
    // write the recursive function that walks down the tree of this object
    // looking for "data:" in each string. Current is faster and less complex
    const persToUpload = {}
    persToUpload["customizedImage"] = await this.parseImgString(shareEventDetail.pers.customizedImage);
    persToUpload["image0"] = await this.parseImgString(shareEventDetail.pers.images[0]);
    persToUpload["image1"] = await this.parseImgString(shareEventDetail.pers.images[1]);
    if (shareEventDetail.pers.envelope && Object.keys(shareEventDetail.pers.envelope).length) {
      persToUpload["backLiner"] = await this.parseImgString(shareEventDetail.pers.envelope.backLiner);
      persToUpload["flapLiner"] = await this.parseImgString(shareEventDetail.pers.envelope.flapLiner);
      persToUpload["front"] = await this.parseImgString(shareEventDetail.pers.envelope.front);
    }

    const urlSchema = {};
    for (const key of Object.keys(persToUpload)) {
      urlSchema[key] = persToUpload[key].type
    }
    let updatedUrlSchema;
    try {
      const resp = await fetch(`${app_env.shost}/ub-image`, {
        method: "POST",
        body: JSON.stringify(urlSchema),
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": Client.getCSRFToken(),
        }
      })
      updatedUrlSchema = await resp.json();
    } catch (err) {
      // A request should be made to create a traceback for this
      console.log(err);
      this.setError();
      return;
    }

    try {
      const promiseArray = [];
      for (const key of Object.keys(persToUpload)) {
        promiseArray[promiseArray.length] = fetch(
          updatedUrlSchema[key].presigned_url, {
          method: "PUT",
          body: persToUpload[key]
        })
      }
      await Promise.all(promiseArray);
    } catch (err) {
      // A request should be made to create a traceback for this
      this.setError();
      return;
    }

    shareEventDetail.pers.customizedImage = updatedUrlSchema.customizedImage.storage_url;
    shareEventDetail.pers.images[0] = updatedUrlSchema.image0.storage_url;
    shareEventDetail.pers.images[1] = updatedUrlSchema.image1.storage_url;
    if (shareEventDetail.pers.envelope && Object.keys(shareEventDetail.pers.envelope).length) {
      shareEventDetail.pers.envelope.backLiner = updatedUrlSchema.backLiner.storage_url;
      shareEventDetail.pers.envelope.flapLiner = updatedUrlSchema.flapLiner.storage_url;
      shareEventDetail.pers.envelope.front = updatedUrlSchema.front.storage_url;
    }
    return shareEventDetail;
  }

  setLoading(isLoading = true, update = null) {
    let dialog = window.app_dialogs?.collection.get("ub-spinner");
    if (!dialog) return console.log("no spinner found");
    if (update) {
      this.loaderStatus = { ...this.loaderStatus, ...update };
      let state = update.state || this.loaderStatus.status || "loading";
      let time = update.time || this.loaderStatus.time || 0;
      let progress = update.progress || this.loaderStatus.progress || 0;
      let value = (time + progress) / 2;
      Utility.updateContent({
        "agub-spinner": {
          removeClass: ["--loading", "--completed", "--error"].removeItem(`--${state}`),
          addClass: `--${state}`,
          style: `--loader-progress:${value}%;`
        },
        "agub-spinner__title": update.title || this.loaderStatus.title || "Processing...",
        "agub-spinner__progress-label": `${Math.round(value)}%`
      });
    }
    if (isLoading) {
      if (dialog.isActive) return
      this.form.removeErrors();
      Client.scrollTo();
      if (this.deviceHasScrollbar) {
        dialog.hasScrollbar = true;
      }
      window.app_dialogs?.open("ub-spinner")
    } else {
      setTimeout(() => {
        window.app_dialogs?.close("ub-spinner", null, true);
      }, 200);
      this.processingInterval && clearInterval(this.processingInterval);
    }
  }

  submitForm(deliveryMethod) {
    const methods = {
      "share-email": "email",
      "share-facebook": "facebook",
      "share-continue": "copy",
      "print": "print",
    };
    this.form.extraRequestParams["delivery_method"] = methods[deliveryMethod]
    this.form.el.submit();
    this.form.onComplete = () => {
      if (this.form.status == "success") {
        this.setEditMode(false);
        this.setLoading(false, {
          title: "Completed!",
          status: "completed",
          progress: 100,
          time: 100,
        });
      } else {
        this.setLoading(false, {
          status: "error",
        });
      }
      setTimeout(() => {
        this.setLoading(false, {
          title: "Processing...",
          status: "loading",
          progress: 0,
          time: 0,
        });
      }, 600)
    }
  }

  getUsageData(usage) {
    let features_used = [];
    let generate_message = false;

    // Features Usage
    usage.photo && features_used.push("used_photo");
    usage.signature && features_used.push("used_signature");
    usage.text && features_used.push("used_ub_text");
    usage.video && features_used.push("used_video");
    if (usage.music?.productNumber && usage.music?.userSelected) {
      features_used.push("used_music_choice");
    }

    // Generated Message Usage
    if (usage.aiMessage && this.config.flags.includes("ai") && !this.config.is_ai_locked) {
      let state = usage.aiMessage.messageState;
      const fields = usage.aiMessage.fields;
      state = state && (usage.text || state != "Own") ? state : "Own";
      features_used.push(Utility.getFeatureCode(state, "message_state"));
      generate_message = JSON.stringify({
        age: fields?.age ? String(fields.age) : "",
        favorite_activity: fields?.favourite_activity || fields?.favorite_activity || "",
        occasion: fields?.occasion || "",
        tone: fields?.tone || "General"
      })
    }

    // Creatacard Personalizations
    const cac_pers = {
      "LobsterId": this.lobster_id,
      "page2PhotoUsage": String(usage.page2PhotoUsage),
      "liner_filename": usage.liner?.filename || "",
      "user_selected_liner": usage.liner?.userSelected || "",
      "stamp_filename": usage.stamp?.filename || "",
      "user_selected_stamp": usage.stamp?.userSelected,
      "agi_personalized_thumb": this.assets.agi_personalized_thumb,
      "envelope_thumb": this.assets.envelope_thumb || "",
      "video_upload_id": this.assets.video_upload_id || "",
      "video_file_size_mb": this.assets.video_file_size_mb || "",
      "user_selected_music": usage.music?.userSelected || "",
      "music_track_id": usage.music?.productNumber || "0",
    }

    if (usage.confetti) {
      cac_pers["confetti"] = usage.confetti.enabled || false;
    }

    features_used = JSON.stringify(features_used);

    return { cac_pers, features_used, generate_message };
  }

  uploadVideo(shareEventDetail) {
    return new Promise(async (resolve, reject) => {
      try {
        const videoServiceResponse = await fetch(`${app_env.shost}/ub-video`);
        const videoServiceData = await videoServiceResponse.json();
        if (!videoServiceData?.upload_id) reject();
        this.assets["video_upload_id"] = videoServiceData.upload_id;

        const upload = UpChunk.createUpload({
          endpoint: videoServiceData["upload_url"],
          file: shareEventDetail.pers.video.video, // this will come from dpd side
          chunkSize: 5120
        })
        upload.on("progress", data => {
          this.setLoading(true, {
            progress: data.detail
          });
        })
        upload.on("success", () => {
          delete shareEventDetail.pers.video.video;
          resolve(videoServiceData["upload_id"]);
        })
        upload.on('error', err => {
          reject()
        });
      } catch (e) {
        reject()
      }
    })
  }

  async fetchPlaybackUrl(uploadId) {
    const playbackUrlReq = await fetch(`${this.config.video_service_url}/video/uploads/${uploadId}/playback-url`);
    const playbackUrlRes = await playbackUrlReq.json();
    if (playbackUrlReq.status != 200) {
      throw new Error(`Couldn't fetch playback URL ${playbackUrlRes}`)
    }
    return playbackUrlRes
  }

  async handleVideoPersonalization(shareEventDetail) {
    const videoSizeMB = (shareEventDetail.pers.video.video.size / (1024 * 1024)).toFixed(3); // bytes -> MB
    this.assets["video_file_size_mb"] = videoSizeMB;

    // We need to make sure that we try to get the upload url twice, in case of rate limiting
    const uploadId = await this.uploadVideo(shareEventDetail);
    if (!uploadId) return null;
    let playbackUrlRes;
    try {
      playbackUrlRes = await this.fetchPlaybackUrl(uploadId);
    } catch {
      try {
        // rate limiting is 1 req/sec (supposedly). retry will happen a bit later than a second,
        // randomly, in case more of these happen at the same time
        await new Promise(resolve => setTimeout(resolve, Utility.getRandomInt(1000, 1800)));
        playbackUrlRes = await this.fetchPlaybackUrl(uploadId);
      } catch (err) {
        //TODO: add error handling on the page and create a route to generate tracebacks for this
        console.log(err);
        return null;
      }
    }
    return playbackUrlRes?.item?.url;
  }

  initHandler(e) {
    document.querySelector("#ub-close-tab")?.addEventListener("click", (e) => {
      if (this.product?.url) {
        window.location.href = this.product.url;
      }
    });
  }

  cardReadyHandler(e) {
    if (this.supportsNativeShare)
      this.el.classList.add("--mobile-share");
    this.el.classList.add("--loaded");
    this.deviceHasScrollbar = Boolean(Client.getScrollbarWidth());
    let observer = new MutationObserver(this.modalObserver.bind(this));
    observer.observe(document.body, { attributes: true });
    this.setEditMode();
    let panel = this.el.closest(".wizard-panel");
    if (panel) {
      panel.addEventListener("panel-activated", () => {
        this.setEditMode();
      });
      panel.addEventListener("panel-before-deactivation", () => {
        this.setEditMode(false);
      });
    }
  }

  modalObserver(mutationList) {
    for (const mutation of mutationList) {
      if (mutation.attributeName === "class") {
        if (mutation.target.classList.contains("modal-open")) {
          this.ubModalOpen = true;
          window.app_page.freezeScroll(false);
        } else {
          if (!this.ubModalOpen) return
          this.ubModalOpen = false;
          if (window.app_dialogs.activeDialogs.size != 0) return
          window.app_page.unfreezeScroll();
        }
      }
    }
  }

  initGiftDialog() {
    let form = window.app_forms.collection.get("ub-select-gift-form");
    let dialog = window.app_dialogs.collection.get("ub-select-gift");
    let iframeHolder = dialog?.el.querySelector("#ub-jifiti-merchant");
    if (!dialog || !form || !iframeHolder) return
    let jifitiListener = null;
    let heartbeat = null;
    let iframeString = iframeHolder.innerHTML || "";

    const failureOnHeartbeat = () => {
      form.addError("server", "Unable to connect to merchant, please try again later.");
      form.renderErrors();
      dialog.isBlocking = false;
    }

    // Render iframe before panel activation
    dialog.el.addEventListener("dialog-opened", async () => {
      document.querySelector("#ub-giftcard-container")?.setAttribute("aria-hidden", true);
      jifitiListener = new AbortController();

      if (!this.jifitiConfig) {
        iframeHolder.classList.remove("--loaded");
        iframeHolder.innerHTML = "";
        iframeHolder.appendChild(Render.fragmentFromString(iframeString));
        await this.getJifitiConfig();
        if (!this.jifitiConfig?.iframe_url) {
          iframeHolder.innerHTML = "";
          form.addError("server", "Unable to connect to merchant, please try again later.");
          form.renderErrors();
          return
        };
        let iframe = iframeHolder.querySelector("iframe");
        let loader = iframeHolder.querySelector(".lazy-load-iframe__loader");
        if (!iframe) return
        iframe.setAttribute("src", this.jifitiConfig.iframe_url);
        iframe.addEventListener("load", () => {
          setTimeout(() => {
            iframeHolder.classList.add("--loaded");
            setTimeout(() => {
              loader.remove();
            }, 300)
          }, 500);
        });
      }

      // Listen for message event
      window.addEventListener('message', e => {
        if (!e.origin.includes("jifiti.com") || e.data?.event != "success") return
        dialog.isBlocking = true;
        heartbeat?.kill();
        heartbeat = new Heartbeat(30, 15);
        heartbeat.onSuccess = async (response) => {
          let respBody;
          try {
            respBody = await response.json();
          }
          catch {
            return failureOnHeartbeat();
          }

          this.builder.dispatchEvent({
            type: "GIFTCARD_PURCHASE_FINISHED",
            qrcode: respBody.gift.qr_url,
            itemNumber: this.jifitiConfig.print_item_number
          });
          window.app_services?.track("send-gift-attached", {
            ...this.order,
            ...{ gift: respBody.gift }
          });
          dialog.isBlocking = false;
          window.app_dialogs.deactivate(dialog.el.id);
        }
        heartbeat.onFail = (reason) => {
          failureOnHeartbeat();
        }
        heartbeat.start(`${app_env.shost}/gifts/transaction-started/${this.jifitiConfig.print_item_number}${this.order.sender_id_fragment}`, {
          method: "POST",
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'X-Csrftoken': Client.getCSRFToken(),
          }
        });
      }, { signal: jifitiListener.signal });
    });

    // Clear iframe and errors after deactivation
    dialog.el.addEventListener("dialog-closed", () => {
      jifitiListener?.abort();
      form.clearErrors(["server"]);
      heartbeat?.kill();
      document.querySelector("#ub-giftcard-container")?.setAttribute("aria-hidden", false);
    });
  }


  async getJifitiConfig() {
    let createUsageReq = null;
    let createUsageResp = null;

    try {
      createUsageReq = await fetch(
        `${app_env.shost}/ub-jifiti/${this.order.sender_id_fragment}`, {
        method: "POST",
        body: Utility.objectToFormData({
          product_number: this.order.product.product_number,
          csrfmiddlewaretoken: Client.getCSRFToken(),
        })
      }
      );
      if (createUsageReq.status != 200) throw new Error("fetch-failed");
      createUsageResp = await createUsageReq.json();
    } catch (e) {
      console.log(e);
    } finally {
      this.jifitiConfig = createUsageResp;
      return this.jifitiConfig
    }
  }
}

class UBLoader {
  constructor(product_number, placer, config = null) {
    this.product_number = product_number;
    this.placer = placer;
    this.config = config || window.app_env?.product_config;
    this.options = null;
    this.builder = null;
  }

  getOptions() {
    if (!this.config) return {}

    this.options = {
      aiMessage: {
        customerNumber: app_env.customer.number,
        customerStatus: app_env.customer.status,
        enabled: this.config.flags.includes("ai"),
        joinLink: "/join",
        locked: this.config.is_ai_locked
      },
      apiUrl: app_env.apihost,
      assetPath: `${app_env.imghost}/product/unifiedbuilder/${this.product_number}/`,
      css_urls: [`${app_env.imghost}${this.config.ub_font_css}`],
      clientID: app_env.client_id,
      digital_sends: true,
      envelope: {
        enabled: this.config.flags.includes("envelope")
      },
      feedback: "https://www.surveymonkey.com/r/unified-builder",
      help: "/faq/creatacard-faqs",
      host: this.config.ub_options_host,
      shareViaFacebook: false,
      shareButtonCopy: this.config.supportsNativeShare ? "Continue to Share" : "Copy Link",
      multi_tab: false,
      printseturl: "/faq/creatacard-faqs",
      privacy_policy: `${app_env.shost}/privacy-policy`,
      skip_bootstrap: true,
      qrcode: {
        default_image: "https://ak.imgag.com/product/siteassets/general/3544156/image.png",
        enabled: this.config.flags.includes("qr")
      },
      terms_of_service: `${app_env.shost}/terms-of-service`,
      user_agent: {
        is_mobile: false,
        is_tablet: false,
        is_handheld: false,
      },
      video: {
        enabled: this.config.flags.includes("video"),
        defaultThumb: `https://ak.imgag.com/product/siteassets/general/3547553/image.png`
      },
      music: {
        enabled: this.config.flags.includes("music"),
      },
      print: {
        enabled: this.config.flags.includes("print"),
      }
    };

    return this.options;
  }

  async loadManifest() {
    const assetsPath = `${app_env.imghost}/product/unifiedbuilder/${this.product_number}/`;
    const loader = new agi.AGProductLoader(assetsPath);
    const ubTransformedManifest = await loader.load(`${assetsPath}manifest.json`);
    return ubTransformedManifest;
  }

  async init() {
    const manifest = await this.loadManifest();

    if (!window.agi?.UnifiedBuilder || !manifest) return console.log("no manifest or class found");
    const Builder = this.adaptUnifiedBuilder(agi.UnifiedBuilder);
    this.builder = new Builder(manifest, this.product_number, this.getOptions(), this.placer);
    return this.builder
  }

  adaptUnifiedBuilder(AGIUnifiedBuilder) {
    class AdaptedUnifiedBuilder extends AGIUnifiedBuilder {
      constructor(a, b, c, d) {
        super(a, b, c, d);
      }
      // This is a temporary adaptation, should raise the issue to product
      // to offer a flag to remove the ub-base from dependency array
      loadCssDependencies(a) {
        let dependenciesArray = this.dependencyManager.getCSSDependencies();
        dependenciesArray = dependenciesArray.removeItem("ub-base.css");
        return this.loadDependencies(a, "css", dependenciesArray);
      }
    }

    return AdaptedUnifiedBuilder;
  }
}

export default UnifiedBuilder;