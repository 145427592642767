class LoadMore {
  constructor() {
    window.app_listeners?.add('click', 'load-more', this.clickHandler.bind(this));
  }

  async clickHandler(e) {
    let target = e.real_target || e.target;
    if (!target.classList.contains('load-more__trigger')) return
    if (target.parentElement.classList.contains('--loading')) return
    let filter = document.querySelector('.filters')?.getInstance("filters");
    if (!filter) return
    if(filter.type == "mailbox") {
      filter.filter("next-page");
      return;
    }
    this.filterHandler(e, filter);
  }

  async filterHandler(e, filter) {
    let target = e.real_target || e.target;

    if (!filter) return
    let {localPackage, url, seo_url} = filter.composeQuery(true, false);
    url = new URL(filter.type == 'browse' ? seo_url : url);
    const pageInputs = document.querySelectorAll('input[type="hidden"][name="page"]');
    const currentPage = (pageInputs && pageInputs.length != 0) ? Number(pageInputs[0].value) : 1;
    let response = null;
    let result = null;
    url.pathname = "/fetch-request" + url.pathname;
    url.searchParams.set('page', currentPage + 1);
    target.parentElement.classList.add('--loading');
    try {
      response = await fetch(url, { method: 'GET' });
      result = await response?.json();
      if (!result?.hasOwnProperty('status')) return
    } catch (e) {
      console.warn(e);
      return
    }

    // This may be needed in case we decide to track load-more depth
    // window.history.replaceState(null, document.title, url.pathname + url.search)
    target.parentElement.classList.remove('--loading');
    if (pageInputs && pageInputs.length != 0) {
      pageInputs.forEach(input => {
        input.value = url.searchParams.get('page');
      })
    }
    if (result?.data) {
      filter.lastTriggerEvent = e;
      filter.response = result.data;
      filter.hydrateProducts(false);
      filter.hydrateCount();
    }
  }
}

export default LoadMore;