class StorageManager {
  constructor() {
    this.page = new Map();
    this.loadedResources = new Map();
    this.session = window.sessionStorage;
    this.local = window.localStorage;
    this.temp = {};
  }

  get(key, type = "") {
    let entry = "";
    if (["session", "local", "cookie"].includes(type)) {
      if (type == "cookie") {
        entry = this.getCookie(key);
      } else {
        entry = this[type].getItem(key);
      }
      try {
        entry = JSON.parse(entry);
      } catch (e) {
        entry = "";
      }
    } else {
      entry = this.page.get(key) || "";
    }

    return entry;
  }

  set(key, value = "", type = "", regex = null, expiration = 0) {
    if (!value || !key) return false
    if (!type || type == "page") {
      this.page.set(key, value);
      return true;
    }
    if (!["session", "local", "cookie"].includes(type)) return false;

    try {
      value = JSON.stringify(value);
    } catch (e) {
      return false
    }
    if (type == "cookie") {
      this.setCookie(key, value, expiration);
    } else {
      try {
        this[type].setItem(key, value);
      } catch (e) {
        if (e.name === 'QuotaExceededError') {
          regex && this.clearSet(key, value, type, regex);
          return false
        }
      }
    }

    return true
  }

  clearSet(key, stringifiedValue = "", type = "", regex) {
    if (!["session", "local"].includes(type) || !regex || !stringifiedValue) return
    this.temp = {};
    regex = new RegExp(regex);
    let storageKeys = Object.keys(this[type]);
    let cleared = false;
    storageKeys.forEach(storageKey => {
      if (regex.test(storageKey)) {
        cleared = true;
        this.temp[storageKey] = this[type].getItem(storageKey);
        this.delete(storageKey, type);
      }
    })
    if (cleared) {
      try {
        this[type].setItem(key, stringifiedValue);
      } catch (e) {
        if (e.name === 'QuotaExceededError') {
          for (let tempKey in this.temp) {
            this[type].setItem(tempKey, this.temp[tempKey]);
          }
        }
      }
    }
  }

  clearOthers(key, type, regex) {
    if (!["session", "local"].includes(type) || !regex) return
    regex = new RegExp(regex);
    Object.keys(this[type]).forEach(storageKey => {
      if (regex.test(storageKey) && storageKey != key) {
        this.delete(storageKey, type);
      }
    })
  }

  delete(key, type = "") {
    if (!key) return
    if (["session", "local"].includes(type)) {
      this[type].removeItem(key);
    } else if (type == "cookie") {
      this.deleteCookie(key);
    } else {
      this.page.delete(key);
    }
  }

  remove(key, type = "") {
    this.delete(key, type);
  }

  setCookie(key, value, expiration = 0, path = "/") {
    const d = new Date();
    if (expiration) {
      d.setTime(d.getTime() + (expiration * 24 * 60 * 60 * 1000));
      let expires = d.toUTCString();
      document.cookie = `${key}=${value};expires=${expires};path=${path}`;
    } else {
      document.cookie = `${key}=${value};path=${path}`;
    }
  }

  getCookie(key) {
    let name = key + "=";
    let ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  deleteCookie(key, path = "/") {
    document.cookie = `${key}=; expires=Thu, 01-Jan-1970 00:00:01 GMT; path=${path}`;
  }
}

export default StorageManager;