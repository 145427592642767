class Format {

  static getContactName(contact = {}, withEmail = false) {
    let tokens = [];
    if (contact.hasOwnProperty('fname') && contact.fname != ''){
      tokens.push(contact.fname);
    }
    if (contact.hasOwnProperty('lname') && contact.lname != ''){
      tokens.push(contact.lname);
    }
    if (withEmail || tokens.length == 0){
      if (contact.hasOwnProperty('email') && contact.email != ''){
        tokens.push(`${contact.email}`);
      }
    }
    return tokens.join(' ');
  }
  static getGroupName(group = {}, withContacts = false) {
    let tokens = [];
    if (group.hasOwnProperty('name') && group.name != ''){
      tokens.push(group.name);
    }
    if (withContacts || tokens.length == 0){
      if (group.hasOwnProperty('count') && group.count != ''){
        let label = group.count == 1 ? 'contact' : 'contacts';
        tokens.push(tokens.length == 0 ? `Group with ${group.count} ${label}` : `with ${group.count} ${label}`);
      }
    }
    return tokens.join(' ');
  }

  static contactToForm(data) {
    const obj = {};
    Object.entries(data).forEach(([key, value]) => {
      if (key != "events") {
        obj[key] = value;
      } else {
        if (!(value instanceof Array) || value.length == 0) return;
        value.forEach((event) => {
          let label = event.occasion_id == 1 ? "birthday" : "anniversary";
          obj[`${label}_reminder`] = 1;
          Object.entries(event).forEach(([eventKey, eventValue]) => {
            if (eventKey == "event_id") {
              obj[`${label}_id`] = eventValue;
            } else {
              if (value) {
                obj[`${label}_${eventKey}`] = eventValue;
              }
            }
          });
        });
      }
    });
    return obj;
  }

  static formToContact(data) {
    const obj = {};
    ["email", "lname", "fname", "id", "email_id"].forEach((label) => {
      if (data.hasOwnProperty(label)) {
        obj[label] = data[label];
      }
    });

    const events = [];
    ["birthday", "anniversary"].forEach((label) => {
      if (
        !data.hasOwnProperty(`${label}_reminder`) &&
        !(data.hasOwnProperty(`${label}_id`) && data[`${label}_id`] != "")
      )
        return;
      let event_obj = {
        occasion_id: label == "birthday" ? 1 : 2,
      };

      if (data.hasOwnProperty(`${label}_id`) && data[`${label}_id`] != "") {
        event_obj["event_id"] = data[`${label}_id`];
      }

      ["year", "month", "day", "reminders"].forEach((eventLabel) => {
        let key = `${label}_${eventLabel}`;
        if (data.hasOwnProperty(key)) {
          event_obj[eventLabel] = data[key];
        }
      });
      events.push(event_obj);
    });

    if (events.length != 0) {
      obj["events"] = events;
    }
    return obj;
  }
}

export default Format;