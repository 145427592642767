import Global from "../../base/Global";

class SubmenuNavigator {
  constructor(container, trigger, button, looping = false) {
    this.container = container;
    this.trigger = trigger;
    this.button = button;
    this.looping = looping;
    this.focusOptions = {preventScroll: true};
    this.init();
  }

  init() {
    this.container.querySelectorAll('.submenu__column').forEach((column, columnCounter) => {
      const focusables = column.querySelectorAll(Global.focusableQueryString);
      if (!focusables || focusables.length == 0) return
      focusables.forEach((focusable, counter) => {
        focusable.setAttribute('data-item-index', counter);
        if (focusable.parentElement == focusable.parentElement.parentElement.lastElementChild) {
          focusable.setAttribute('data-item-last', true);
        }
        if (focusable.parentElement == focusable.parentElement.parentElement.firstElementChild) {
          focusable.setAttribute('data-item-first', true);
        }
        focusable.setAttribute('data-column-index', columnCounter);
        if (column == column.parentElement.firstElementChild) {
          focusable.setAttribute('data-column-first', true);
        }
        if (column == column.parentElement.lastElementChild) {
          focusable.setAttribute('data-column-last', true);
        }
      })
    });
  }

  nextItem(current) {
    const column = parseInt(current.dataset.columnIndex);
    const item = parseInt(current.dataset.itemIndex);
    if (current == this.trigger || current == this.container || current == this.button) {
      this.container.querySelector('[data-column-first][data-item-first]')?.focus(this.focusOptions);
    } else if (current.dataset.itemLast) {
      this.nextColumn(current);
    } else {
      this.container.querySelector(`[data-column-index="${column}"][data-item-index="${item + 1}"]`)?.focus(this.focusOptions);
    }
  }

  prevItem(current) {
    const column = parseInt(current.dataset.columnIndex);
    const item = parseInt(current.dataset.itemIndex);
    if (current == this.trigger || current == this.button || current == this.container) {
      this.container.querySelector('[data-column-last][data-item-last]')?.focus(this.focusOptions);
    } else if (current.dataset.columnFirst && current.dataset.itemFirst) {
      if (this.looping){
        this.container.querySelector(`[data-column-last][data-item-last]`)?.focus(this.focusOptions);
      }
    } else if (current.dataset.itemFirst) {
      this.container.querySelector(`[data-column-index="${column - 1}"][data-item-last]`)?.focus(this.focusOptions);
    } else {
      this.container.querySelector(`[data-column-index="${column}"][data-item-index="${item - 1}"]`)?.focus(this.focusOptions);
    }
  }

  nextColumn(current) {
    const column = parseInt(current.dataset.columnIndex);
    if (current == this.trigger || current == this.button || current == this.container) {
      this.container.querySelector('[data-column-first][data-item-first]')?.focus(this.focusOptions);
    } else if (current.dataset.columnLast) {
      if (this.looping){
        this.container.querySelector(`[data-column-first]`)?.focus(this.focusOptions);
      }
    } else {
      this.container.querySelector(`[data-column-index="${column + 1}"]`)?.focus(this.focusOptions);
    }
  }

  prevColumn(current) {
    const column = parseInt(current.dataset.columnIndex);
    if (current == this.trigger || current == this.button || current == this.container) {
      this.container.querySelector('[data-column-last][data-item-first]')?.focus(this.focusOptions);
    } else if (current.dataset.columnFirst) {
      if (this.looping){
        this.container.querySelector(`[data-column-last]`)?.focus(this.focusOptions);
      }
    } else {
      this.container.querySelector(`[data-column-index="${column - 1}"]`)?.focus(this.focusOptions);
    }
  }

}
export default SubmenuNavigator;