"use strict";

import Utility from "../../base/Utility";
import Form from "./Form";

class Forms {

  constructor() {
    this.collection = new Map();
    this.init();
  }

  init() {
    this.showHideRecaptcha();
    const els = document.querySelectorAll('.form');
    if (!els || els.length == 0) return
    els.forEach(this.initForm.bind(this));
    window.app_listeners.add('pageshow', 'remove-loadings-upon-bfcache', this.pageshowHandler.bind(this));
    this.initExternalControls();
  }

  pageshowHandler(e) {
    // This is implemeted to remove loadings upon hitting the page with bfcache
    if (e.persisted) {
      this.collection.forEach(form => {
        form.el.classList.remove("--loading");
        form.submitBtns.forEach(btn => btn.classList.remove('--loading'));
        form.submitConfig?.submitter?.classList.remove('--loading');
        form.loadingEl?.setAttribute("aria-hidden", true);
      })
    }
  }

  initExternalControls() {
    window.app_listeners?.add('click', 'forms-interact-click', this.clickHandler.bind(this))
  }

  showHideRecaptcha() {
    if (!window.app_env?.recaptcha) return
    window.app_listeners?.add('focus', 'forms-focus-recaptcha', (e) => {
      if (!e.target || !(e.target instanceof HTMLElement)) return
      let show = e.target.closest('.form[data-recaptcha-protect]') && !e.target.closest('[aria-hidden="true"]');
      if (e.target.classList.contains("sso-auth__trigger") || e.target.classList.contains("btn-action")) {
        show = false;
      }
      show ? window.app_page.showRecaptcha() : window.app_page.hideRecaptcha()
    })
  }

  clickHandler(e) {
    let target = e.real_target || e.target;

    if (target.classList.contains('form-interact')) {
      let trigger = target;
      let formKey = trigger.getAttribute('data-form');
      let dispatches = trigger.hasAttribute('data-dispatches');
      let fieldKey = trigger.getAttribute('data-field');
      let value = trigger.hasAttribute('data-value') ? trigger.getAttribute('data-value') : null;
      if (!formKey) {
        formKey = target.closest('form')?.id;
      }
      let form = this.collection.get(formKey);
      if (form) {
        if (fieldKey == 'submit') {
          if (form.el.tagName == 'FORM') {
            form.el.submit()
          } else {
            form.el.dispatchEvent(new Event('submit', {cancelable: true}));
          }
        } else {
          let field = form.fields.get(fieldKey);
          if (field) {
            if (value) {
              field.setValue(value, dispatches);
            } else {
              field.setFocus();
            }
          } else {
            form.el.querySelectorAll(`input[type="hidden"][name="${fieldKey}"], input[type="hidden"][data-key="${fieldKey}"]`).forEach(input => {
              input.value = value || "";
            })
          }
        }
      }
    }
  }

  initForm(el) {
    if (el.classList.contains("--skip")) return
    if (el.id == '') {
      el.id = `form-instance`;
    }
    Utility.fixDuplicates(el);
    const instance = new Form(el, this);
    this.collection.set(el.id, instance);
    return instance
  }

}

export default Forms;