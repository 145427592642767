import Utility from "../../../base/Utility";
import PasswordField from "./PasswordField";

class CreatePasswordField extends PasswordField {
  constructor(el, form) {
    super(el, form);
    this.type = 'create-password';
    this.meterEl = this.field.querySelector('.password-meter');
    this.meterBar = this.field.querySelector('.password-meter__bar');
    this.hintScore = 0;
    this.hintPercent = 0;
    this.hints = [
      {
        key: 'length',
        hint: "at least 8 characters",
        weight: 5,
        score: 1,
        pass: v => v.length > 7,
      },
      {
        key: 'number',
        hint: "a number",
        weight: 3,
        score: 1,
        pass: v => v.match(/([0-9])/),
      },
      {
        key: 'letters',
        hint: "uppercase and lowercase letters",
        weight: 2,
        score: 1,
        pass: v => v.match(/([a-z].*[A-Z])|([A-Z].*[a-z])/),
      },
      // {
      //   key: 'special',
      //   weight: 1,
      //   score: 0,
      //   pass: v => v.match(/([!,%,&,@,#,$,^,*,?,_,~,+,=,(,),-])/),
      // },
    ];
    this.strengths = {
      0: {
        message: "Password is Very Weak",
        label: 'weak'
      },
      1: {
        message: "Password is Weak",
        label: 'weak'
      },
      2: {
        message: "Password is Average",
        label: 'weak'
      },
      3: {
        message: "Password is Acceptable",
        label: 'acceptable'
      },
      4: {
        message: "Password is Strong",
        label: 'strong'
      }
    }
    this.initCreatePassword();
  }

  initCreatePassword() {
    this.unsetReady();
    Utility.loadResources('zxcvbn',
      ['https://cdnjs.cloudflare.com/ajax/libs/zxcvbn/4.4.2/zxcvbn.js'],
      this.initPasswordMeter.bind(this));

    this.rules.set('pattern', {
      error: this.el.getAttribute('data-pattern-error') || `Password unacceptable`,
      formError: this.el.getAttribute('data-pattern-form-error') || `Password is not acceptable`,
      test: v => (this.testStrength(v)),
    });
  }

  initPasswordMeter() {
    this.setReady();
    this.el.value = this.filterRegEx(this.el.value);
    this.value = this.el.value;
    this.testStrength(this.value);
    if (this.meterEl) {
      this.meterBar.id = this.id + '-meter';
    }
    this.addDescription(this.meterBar.id);
  }

  handleInput(e) {
    e.target.value = this.filterRegEx(e.target.value);
    this.value = e.target.value;
    this.testStrength(this.value)
    this.afterSubmitValidation();
    this.onInput(this.value);
  }

  validateHints(value) {
    value = value || this.value;
    this.hintScore = 0;
    this.hintPercent = 0;
    let recommendations = [];

    this.hints.forEach(hint => {
      const hintEl = this.meterEl.querySelector(`.password-hint[data-key="${hint.key}"]`);
      if (hint.pass(value)) {
        if (hint.key == 'number') {
          this.hintScore += value.length > 2 ? hint.score : 0;
        } else {
          this.hintScore += hint.score;
        }
        this.hintPercent += hint.weight * 10;
        hintEl?.classList.add('--passed');
        hintEl.querySelector(".password-hint__icon").innerHTML = "<span class='sr-only'>included </span>";
      } else {
        recommendations.push(hint.hint);
        hintEl?.classList.remove('--passed');
        hintEl.querySelector(".password-hint__icon").innerHTML = "<span class='sr-only'>not included </span>";
      }
    })

    return recommendations
  }

  updateMeterInfo(message, label = '', percent = 0) {
    this.meterEl?.classList.remove("--weak", "--acceptable", "--strong");
    this.meterBar.setAttribute("aria-valuenow", Math.round(percent));
    this.meterBar.setAttribute("aria-valuetext", message);
    if (label != '') {
      this.meterEl?.classList.add(`--${label}`);
    }

    this.meterEl?.style.setProperty('--pm-strength', percent);
  }

  composeMessage(strength, recommendations) {
    let parts = [];
    strength && parts.push(strength);
    if (recommendations && recommendations.length) {
      parts.push(`must contain ${Utility.enumerate(recommendations)}`);
    }
    return parts.join(", ")
  }

  testStrength(value) {
    value = value || this.value;
    if (!this.ready) return false
    if (!this.enabled) return true
    let recommendations = this.validateHints(value);
    let pass = false;
    const evaluation = zxcvbn(this.value);
    let score = this.hintScore == 3 ? Math.max(3, evaluation.score) : this.hintScore;
    let percent = (Math.min(evaluation.guesses_log10 * 8, 100) + score * 50) / 3;
    if (this.value == '') {
      this.updateMeterInfo(this.composeMessage(null, recommendations))
    } else {
      this.updateMeterInfo(
        this.composeMessage(this.strengths[score].message, recommendations),
        this.strengths[score].label,
        percent
      );
    }

    if (score > 2) {
      pass = true;
    }
    return pass;
  }
}

export default CreatePasswordField;