import Utility from "../base/Utility";
import Wizard from "./Wizard";

class Wizards {
  constructor() {
    this.collection = new Map();
    this.init();
  }

  init() {
    const els = document.querySelectorAll('.wizard:not(.--ignore)');
    if (!els || els.length == 0) return
    els.forEach(this.initWizard.bind(this));
  }

  initWizard(el) {
    if (el.id == '') {
      el.id = `wizard-instance`;
    }
    Utility.fixDuplicates(el);
    this.collection.set(el.id, new Wizard(el));
  }

  get(key) {
    let result = this.collection.entries().next().value;
    if (key) {
      result = this.collection.get(key);
    }
    return result;
  }

}

export default Wizards;