import Client from "../../base/Client";
import Utility from "../../base/Utility";
import SubmenuNavigator from "./SubmenuNavigator";

class Submenu {

  constructor(el, header) {
    this.el = el;
    this.header = header;
    this.inner = el.querySelector('.menu-item__inner');
    this.container = el.querySelector('.menu-item__submenu');
    this.button = el.querySelector('.menu-item__trigger');
    this.link = el.querySelector('.menu-item__overlay');
    this.menu = el.closest('.header__main');
    this.navigator = null;
    this.submenu = el.querySelector('.submenu');
    this.delay = 300;
    this.isActive = false;
    this.init();
  }

  init() {
    this.el.addEventListener("mouseenter", this.activateWithDelay.bind(this));
    this.el.addEventListener("mouseleave", this.deactivateWithDelay.bind(this));
    this.button.addEventListener("click", this.toggle.bind(this));
    this.inner.addEventListener("click", (e) => {
      if (e.target == this.inner) {
        this.toggle(e);
      }
    });
    this.initGroups();
  }

  activateWithDelay(e) {
    if (e.type == 'mouseenter' && Client.isTouch()) return
    if (this.el.classList.contains('--deactivate-intent')) {
      this.el.classList.remove('--deactivate-intent');
      return
    }

    if (this.menu.classList.contains('--menu-entered')) {
      if (this.isActive) return;
      this.el.classList.add('--activate-intent');
      this.el.classList.remove('--deactivate-intent');
      this.activate();
      setTimeout(() => {
        this.el.classList.remove('--activate-intent');
      }, 0);
      return
    }

    if (this.isActive) return;
    this.el.classList.add('--activate-intent');
    setTimeout(() => {
      if (this.el.classList.contains('--activate-intent')) {
        this.el.classList.remove('--activate-intent');
        this.activate();
      }
    }, this.delay);
  }

  deactivateWithDelay(e) {
    if (e.type == 'mouseleave' && Client.isTouch()) return
    if (this.el.classList.contains('--activate-intent')) {
      this.el.classList.remove('--activate-intent');
      return
    }
    if (!this.isActive) return;
    this.el.classList.add('--deactivate-intent');
    setTimeout(() => {
      if (this.el.classList.contains('--deactivate-intent')) {
        this.el.classList.remove('--deactivate-intent');
        this.deactivate();
      }
    }, this.delay);
  }

  initGroups() {
    const groups = this.container.querySelectorAll('[role="group"]');
    if (!groups || groups.length == 0) return
    groups.forEach((el, counter) => {
      const label = el.getAttribute('aria-label') || '';
      el.setAttribute('aria-label', `${label}, ${counter + 1} of ${groups.length} columns`);
    })
  }

  activate(byFocus = false) {
    if (this.navigator == null) {
      this.navigator = new SubmenuNavigator(this.container, this.link, this.button);
    }
    if (this.isActive) return
    this.header.activeSubmenu?.deactivate();
    this.isActive = true;
    this.el.classList.add("--active");
    this.container.setAttribute("aria-hidden", false);
    this.button.setAttribute("aria-expanded", true);
    if (byFocus) {
      setTimeout(() => {
        this.container.querySelector('a, button')?.focus({ preventScroll: true });
      }, 100)
    }
    this.header.activeSubmenu = this;
    this.menu.classList.add("--menu-entered");
    this.menu.style.setProperty("--submenu-height", this.container.getBoundingClientRect().height + 'px');
    Utility.loadImages(this.container);
    window.app_listeners?.add('keydown', 'submenu-focus-handler', this.keydownHandler.bind(this));
  }

  keydownHandler(e) {
    if (!this.isActive) return
    if (e.key == 'Escape') {
      this.button.focus({ preventScroll: true });
      this.deactivate();
      e.preventDefault();
    } else if (['ArrowDown', 'ArrowUp', 'ArrowRight', 'ArrowLeft'].includes(e.key)) {
      this.container.setAttribute("aria-hidden", false);
      this.arrowNavigator(e.key);
      e.preventDefault();
    } else if (e.code == 'Space') {
      if (document.activeElement && document.activeElement.tagName == 'A') {
        document.activeElement.click();
        e.preventDefault();
      }
    }
  }

  arrowNavigator(key) {
    if (!this.navigator) return;
    switch (key) {
      case 'ArrowDown': this.navigator.nextItem(document.activeElement); break;
      case 'ArrowUp': this.navigator.prevItem(document.activeElement); break;
      case 'ArrowRight': this.navigator.nextColumn(document.activeElement); break;
      case 'ArrowLeft': this.navigator.prevColumn(document.activeElement); break;
    }
  }

  deactivate() {
    if (!this.isActive) return
    this.isActive = false;
    this.el.classList.remove("--active");
    this.container.setAttribute("aria-hidden", true);
    this.button.setAttribute("aria-expanded", false);
    this.header.activeSubmenu = null;
    const active_menus = document.querySelectorAll(".menu-item.--active");
    if (!active_menus || active_menus.length == 0) {
      this.menu.style.setProperty("--submenu-height", '');
      this.menu.classList.remove("--menu-entered");
    }
    window.app_listeners?.remove('keydown', 'submenu-focus-handler');
  }

  toggle(e = null) {
    if (this.isActive) {
      if (!this.el.classList.contains('--activate-intent')) {
        this.deactivate();
      }
    } else {
      this.activate(e && e.pointerType == "");
    }
    e?.preventDefault();
  }

}

export default Submenu;