import Client from "./Client";
import Global from "./Global";

class Listeners {

  constructor() {
    this.resizeObserver;
    this.changeObserver;
    this.abortController = new AbortController();
    this.events = {
      scroll: new Map(),
      keydown: new Map(),
      keyup: new Map(),
      focus: new Map(),
      blur: new Map(),
      click: new Map(),
      afterClick: new Map(),
      resize: new Map(),
      change: new Map(),
      popstate: new Map(),
      pageshow: new Map(),
      isMobile: new Map(),
      isDesktop: new Map(),
      breakpoint: new Map()
    }
    this.currentlyIsMobile = false;
    this.init();
  }

  add(eventType, key, callback, prop = null) {
    if (!this.events[eventType]) return
    if (prop) {
      this.events[eventType].set(key, { callback, prop });
    } else {
      this.events[eventType].set(key, { callback });
    }
  }

  remove(eventType, key) {
    if (!this.events[eventType] || !this.events[eventType].has(key)) return
    this.events[eventType].delete(key);
  }

  clear(key = null){
    for (const eventName in this.events){
      if (!key){ 
        this.events[eventName] = new Map()
      } else if (this.events[eventName].has(key)){
        this.events[eventName].delete(key)
      }
    }
  }

  scrollHandler(e) {
    this.events.scroll.forEach((obj => {
      if (obj.hasOwnProperty('prop')) {
        obj.callback(e, obj.prop);
      } else {
        obj.callback(e);
      }
    }))
  }

  keydownHandler(e) {
    this.events.keydown.forEach((obj, name) => {
      if (obj.hasOwnProperty('prop')) {
        obj.callback(e, obj.prop);
      } else {
        obj.callback(e);
      }
    })
  }

  blurHandler(e) {
    this.events.blur.forEach((obj, name) => {
      if (obj.hasOwnProperty('prop')) {
        obj.callback(e, obj.prop);
      } else {
        obj.callback(e);
      }
    })
  }

  keyupHandler(e) {
    this.events.keyup.forEach((obj, name) => {
      if (obj.hasOwnProperty('prop')) {
        obj.callback(e, obj.prop);
      } else {
        obj.callback(e);
      }
    })
  }

  focusHandler(e) {
    this.events.focus.forEach((obj => {
      if (obj.hasOwnProperty('prop')) {
        obj.callback(e, obj.prop);
      } else {
        obj.callback(e);
      }
    }))
  }

  resizeHandler(entries) {
    let theEntry = null;
    let width = null;
    let height = null;
    if (entries){
      for (const entry of entries) {
        theEntry = entry;
      }
    }
    if (theEntry.borderBoxSize) {
      width = theEntry.borderBoxSize[0].inlineSize;
      height = theEntry.borderBoxSize[0].blockSize;
    } else {
      let box = theEntry.target.getBoundingClientRect();
      width = box.width;
      height = box.height;
    }

    Client.height = height;
    Client.width = width;
    this.events.resize.forEach((obj => {
      if (obj.hasOwnProperty('prop')) {
        obj.callback({width, height}, obj.prop);
      } else {
        obj.callback({width, height});
      }
    }))
  }

  clickHandler(e) {
    if (e.target.closest("button")) {
      e.real_target = e.target.closest("button")
    }
    this.events.click.forEach((obj => {
      if (obj.hasOwnProperty('prop')) {
        obj.callback(e, obj.prop);
      } else {
        obj.callback(e);
      }
    }))
  }

  afterClickHandler(e) {
    if (e.target.closest("button")) {
      e.real_target = e.target.closest("button")
    }
    this.events.afterClick.forEach((obj => {
      if (obj.hasOwnProperty('prop')) {
        obj.callback(e, obj.prop);
      } else {
        obj.callback(e);
      }
    }))
  }

  popstateHandler(e) {
    this.events.popstate.forEach((obj => {
      if (obj.hasOwnProperty('prop')) {
        obj.callback(e, obj.prop);
      } else {
        obj.callback(e);
      }
    }))
  }

  pageshowHandler(e) {
    this.events.pageshow.forEach((obj => {
      if (obj.hasOwnProperty('prop')) {
        obj.callback(e, obj.prop);
      } else {
        obj.callback(e);
      }
    }))
  }

  changeHandler() {
    this.events.change.forEach((obj => {
      if (obj.hasOwnProperty('prop')) {
        obj.callback(obj.prop);
      } else {
        obj.callback();
      }
    }))
  }

  isMobileHandler(e) {
    this.events.isMobile.forEach((obj => {
      if (obj.hasOwnProperty('prop')) {
        obj.callback(e, obj.prop);
      } else {
        obj.callback(e);
      }
    }))
  }

  isDesktopHandler(e) {
    this.events.isDesktop.forEach((obj => {
      if (obj.hasOwnProperty('prop')) {
        obj.callback(e, obj.prop);
      } else {
        obj.callback(e);
      }
    }))
  }

  breakpointHandler(e) {
    this.events.breakpoint.forEach((obj => {
      if (obj.hasOwnProperty('prop')) {
        obj.callback(e, obj.prop);
      } else {
        obj.callback(e);
      }
    }))
  }

  init() {
    this.abortController = new AbortController();
    window.addEventListener('scroll', this.scrollHandler.bind(this), {signal: this.abortController.signal});
    window.addEventListener('popstate', this.popstateHandler.bind(this), {signal: this.abortController.signal});
    window.addEventListener('pageshow', this.pageshowHandler.bind(this), {signal: this.abortController.signal});
    window.addEventListener('focus', this.focusHandler.bind(this), {capture: true, signal: this.abortController.signal});
    window.addEventListener('blur', this.blurHandler.bind(this), {capture: true, signal: this.abortController.signal});
    document.addEventListener('click', this.clickHandler.bind(this), {capture: true, signal: this.abortController.signal});
    document.addEventListener('click', this.afterClickHandler.bind(this), {signal: this.abortController.signal});
    document.addEventListener('keydown', this.keydownHandler.bind(this), {signal: this.abortController.signal});
    document.addEventListener('keyup', this.keyupHandler.bind(this), {signal: this.abortController.signal});
    window.addEventListener('isMobile', this.isMobileHandler.bind(this), {signal: this.abortController.signal});
    window.addEventListener('isDesktop', this.isDesktopHandler.bind(this), {signal: this.abortController.signal});
    document.addEventListener('breakpoint', this.breakpointHandler.bind(this), {signal: this.abortController.signal});
    this.changeObserver = new MutationObserver(this.changeHandler.bind(this));
    this.changeObserver.observe(document.documentElement, { childList: true, attributes: true, subtree: true });
    this.initResizeListener();
  }

  handleMobileDesktopSwitch(event) {
    window.dispatchEvent(new Event(event.matches ? 'isMobile' : "isDesktop"));
  }

  initResizeListener() {
    this.resizeObserver = new ResizeObserver(this.resizeHandler.bind(this));
    let proxy = document.querySelector('.resize-proxy');
    if (!proxy) {
      const proxyEl = document.createElement('div');
      proxyEl.classList.add('resize-proxy');
      document.body.insertBefore(proxyEl, document.body.querySelector('div'));
      proxy = document.querySelector('.resize-proxy');
    }
    const mediaWatcher = window.matchMedia(`(max-width: ${Global.mobileBreakpoint}px)`);
    setTimeout(() => {
      this.handleMobileDesktopSwitch(mediaWatcher)
    }, 100);
    mediaWatcher.onchange = this.handleMobileDesktopSwitch.bind(this);

    if (proxy) {
      this.resizeObserver.observe(proxy);
    } else {
      this.resizeObserver.observe(document.body);
    }
  }

  disconnect() {
    this.abortController.abort();
    this.resizeObserver.disconnect();
    this.changeObserver.disconnect();
  }

}

export default Listeners